header {
    transition: all 0.2s ease-in;
}

body.offcanvas-active {
    @media screen and (max-width: 991px) {
        overflow: hidden;
    }
}

.offcanvas-header {
    display: none;
}

.hide-notify.notify-sec {
    display: none;
    @media screen and (max-width: 991px) {
        display: flex;
    }
}

.notify-remove main {
    margin-top: 0;
}

.skip-main {
    left: -62.4375rem;
    position: absolute;
    top: auto;
    width: 0.063rem;
    height: 0.063rem;
    overflow: hidden;
    z-index: -999;
    font-weight: $regularFont;
    color: $darkBlue;
    &:focus {
        background-color: $white;
        left: 0.125rem;
        width: 20%;
        height: auto;
        overflow: auto;
        text-align: center;
        font-size: 0.9rem;
        z-index: 9999;
        text-decoration: underline;
        outline: 2px solid $white;
    }
    &:hover {
        color: $darkBlue;
    }
}
a.text-xs.primary-theme-clr.medium-font {
    font-size: 0.83rem;
    line-height: 1.5;
}
.notify-sec {
    height: 3.75rem;
    background-color: $darkBlue;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
        height: 4.8rem;
    }
    .notify-txt {
        max-width: 80%;
        margin: 0 auto;
    }
    p {
        color: $primaryYellow;
        text-align: center;
        font-size: 1rem;
        line-height: 1.5;
        margin: 0;
        a {
            color: $white;
            margin: 0 0.3125rem;
            &:focus {
                outline: 0.125rem solid $white;
            }
        }
        @media only screen and (max-width: 991px) {
            font-size: 0.75rem;
        }
    }
    // Close button
    .close-icn {
        position: absolute;
        right: 0.625rem;
        top: 50%;
        transform: translate(0, -50%);
        opacity: 1;
        cursor: pointer;
        background: none;
        color: $white;
        border: none;
        font-size: 2.0625rem;
        line-height: 1.625rem;
        display: inline-block;
        &:focus {
            outline: 2px solid $white;
        }
        @media only screen and (max-width: 991px) {
            right: 4%;
            top: 50%;
        }
    }
}

// Notification Hide
.notify-hide {
    .notify-sec {
        display: none;
    }
}

.neighborly-sec {
    height: 3.75rem;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    font-family: 'Inter Regular';
    position: relative;
    p,
    a {
        margin: 0;
    }
    .nei-mobile-logo {
        display: none;
        img {
            vertical-align: top;
            margin-top: 3px;
        }
        @media screen and (max-width: 991px) {
            width: 1rem;
            display: block;
            margin-bottom: 0;
        }
        @media screen and (max-width: 375px) {
            width: 1.1rem;
        }
    }
    .nei-logo-wrap {
        @media screen and (max-width: 767px) {
            padding-right: 0;
        }
    }
    ul {
        list-style-type: none;
        padding: 0.3125rem 0 0;
        margin: 0;
        text-align: right;
        @media screen and (max-width: 767px) {
            padding: 0;
        }
        li {
            display: inline-block;
            vertical-align: top;
            position: relative;
            &:last-child {
                padding: 0;
            }
            a {
                text-decoration: none;
                &:focus,
                &:hover {
                    text-decoration: underline;
                    color: $white;
                }
            }
        }
        .nei-guarntee {
            padding-left: 2.6875rem;
            margin-right: 0.9375rem;
            &::before {
                position: absolute;
                content: '';
                background: url('../images/guarantee-icon-FPO.png') no-repeat top left;
                width: 2.125rem;
                height: 2.3rem;
                top: -0.4375rem;
                left: 0;
                background-size: cover;
            }
        }
        .sign-in {
            &::before {
                position: absolute;
                content: '';
                background: url('../images/login.svg') no-repeat top left;
                width: 1.25rem;
                height: 1.25rem;
                top: 0;
                left: 0;
                background-size: contain;
                @media screen and (max-width: 991px) {
                    width: 0.75rem;
                    height: 0.9375rem;
                    top: 4px;
                }
            }
            a {
                position: relative;
                padding-right: 0;
                padding-left: 1.875rem;
                &::after {
                    position: absolute;
                    content: '';
                    right: 0.4375rem;
                    top: 0.125rem;
                    // one time use
                    background: #AEDAF3;
                    width: 10.0625rem;
                    height: 0.9375rem;
                }
                &:last-child {
                    //padding: 0;
                    &::after {
                        display: none;
                    }
                }
                img {
                    height: 1.125rem;
                    width: auto;
                    padding-right: 0.25rem;
                }
                @media screen and (max-width:991px) {
                    padding-left: 1.125rem;
                }
            }
        }
        .join-col {
            &::before {
                padding: 0 0.3125rem;
                color: $white;
                content: " | ";
                line-height: 0.75rem;
                @media screen and (max-width: 991px) {
                    font-size: 0.6875rem;
                    padding: 0;
                }
            }
        }
    }
    @media screen and (max-width:991px) {
        .neighbourly-Box {
            display: flex;
            p {
                padding-left: 0.625rem;
                line-height: 1.25rem;
            }
        }
        .neighborly-logo {
            display: none;
        }
        .nei-guarntee {
            display: none;
        }
    }
    .container {
        @media screen and (max-width: 1319px) {
            padding: 0 0.9375rem;
        }
    }
    .col-sec {
        padding: 0 0.9375rem;
        @media screen and (max-width: 450px) {
            max-width: 60%;
        }
    }
}

.request-mobile-sec {
    display: none;
    @media screen and (max-width: 991px) {
        display: block;
        background-color: $white;
        padding: 0.9375rem;
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
            li {
                display: flex;
                flex-direction: column;
                .call-icon {
                    font-size: 0.875rem;
                    color: $black;
                    font-weight: $boldFont;
                    font-family: 'Inter Bold';
                    &::before {
                        display: none;
                    }
                    img {
                        height: 1rem;
                        vertical-align: text-top;
                    }
                }
                span {
                    font-size: 0.625rem;
                    color: $darkGrey;
                    display: inline-block;
                    margin-top: 0.1875rem;
                }
            }
        }
    }
    .primary-btn {
        @media screen and (max-width: 767px) {
            min-width: 10rem;
            min-width: 10rem;
            line-height: 2.125rem;
            min-height: 2.125rem;
            font-size: 0.8125rem;
        }
        @media screen and (max-width: 360px) {
            padding: 0;
            min-width: 9rem;
            font-size: 0.75rem;
        }
    }
    @media screen and (max-width:360px) {
        font-size: 0.75rem;
    }
}

.mobile-search {
    display: none;
    @media screen and (max-width: 991px) {
        display: block;
        ul {
            list-style: none;
            display: flex;
            li {
                padding-right: 0.9375rem;
                @media screen and (max-width: 380px) {
                    padding-right: 0.5rem;
                }
                a {
                    font-size: 0.875rem;
                    color: $black;
                    letter-spacing: 0;
                    display: inline-block;
                }
            }
        }
    }
}


/*-- ================================
           Top Header Section End
===================================== */

// Top Menu Section Start
.top-menu {
    position: relative;
    display: flex;
    @media screen and (min-width: 992px) {
        min-height: 4.125rem;
    }
    // justify-content: center;
    @media screen and (max-width: 991px) {
        position: static;
        background: none;
        padding: 0;
        height: auto;
        display: block;
        width: 100%;
        opacity: 1;
    }
    .locate-form-wrapper {
        width: 100%;
        position: absolute;
        left: 5rem;
        @media screen and (max-width: 1200px) {
            left: 6.5rem;
        }
        @media screen and (min-width: 1440px) {
            left: 4rem;
        }
        @media screen and (min-width: 1820px) {
            left: 0.3rem;
        }
        @media screen and (max-width:991px) {
            display: none;
        }
        .locate-form {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            justify-content: center;
            margin-right: 0;
            margin-bottom: 0;
            .col-form-label {
                padding-top: calc(0.375rem + 1px);
                padding-bottom: calc(0.375rem + 1px);
                margin-bottom: 0;
                line-height: 1.5;
                letter-spacing: 0;
                text-align: right;
            }
            .form-control {
                background: $white;
                border: 1px solid $blue;
                height: 3rem;
                border-radius: 0;
                max-width: 10.5rem;
                margin: 0 1.5625rem;
                &::placeholder {
                    color: $primaryGrey;
                    font-size: 0.875rem;
                }
            }
            .inputPostal {
                width: 17.625rem;
                padding: 0 1.25rem;
                position: relative;
                @media screen and (max-width: 1365px) {
                    width: 15rem;
                }
                @media screen and (max-width:1199px) {
                    width: 12rem;
                }
            }
            .locate-btn {
                background: none;
                padding: 0;
                font-weight: 500;
                border: none;
                font-family: 'Inter Medium';
                color: $black;
                &:focus,
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.location-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 6.2rem;
    @media screen and (max-width: 1200px) {
        left: 7.2rem;
    }
    @media screen and (min-width: 1440px) {
        left: 5.2rem;
    }
    @media screen and (min-width: 1820px) {
        left: 2rem;
    }
    strong {
        font-size: 1.125rem;
        letter-spacing: 0;
        text-align: center;
        font-weight: $boldFont;
        @media screen and (max-width: 1100px) {
            font-size: 1rem;
        }
    }
    a {
        font-size: 0.83rem;
        color: $secondary;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        img {
            padding-right: 0.3125rem;
            height: 1.1875rem;
        }
        &:hover,
        &:focus {
            color: $secondary;
        }
    }
    small {
        font-size: 0.75rem;
        color: $darkGrey;
        letter-spacing: 0;
        text-align: center;
        font-weight: 500;
    }
    @media screen and (max-width:991px) {
        display: none;
    }
}

// Top Menu Section End
.header-classic {
    color: $black;
    background-color: $white;
    min-height: 9.75rem;
    @media screen and (max-width: 991px) {
        box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.10);
        min-height: 100%;
    }
    .navbar {
        padding: 0;
    }
    .logo-sec {
        h1 {
            margin-bottom: 0;
            .navbar-brand {
                margin: 0;
                padding: 0;
                @media screen and (min-width: 992px) {
                    margin-left: 0.9375rem;
                }
                @media screen and (max-width: 991px) {
                    padding: 0.3125rem 0;
                    margin: 0 1.875rem;
                    &:focus{
                        outline-offset: -2px;
                    }
                }
                img {
                    max-width: 14.9375rem;
                    height: auto;
                    @media screen and (max-width: 1199px) {
                        max-width: 10.9375rem;
                    }
                    @media screen and (max-width: 991px) {
                        width: 100%;
                        margin-left: 0;
                        max-width: 9.0625rem;
                    }
                }
            }
        }
    }
    .find-row {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        .mobile-icon {
            display: none;
        }
        @media screen and (max-width: 991px) {
            display: none;
        }
        ul {
            margin-bottom: 0;
            width: 100%;
        }
        a {
            font-weight: 500;
            letter-spacing: 0;
            text-align: center;
            display: inline-block;
            border: 1px solid transparent;
            color: $black;
            @media screen and (min-width: 991px) {
                margin-left: 1rem;
                &:first-child {
                    margin-left: 0;
                }
            }
            @media screen and (max-width: 1010px) {
                white-space: nowrap;
            }
            @media screen and (max-width: 1230px) {
                margin-left: 0.4rem;
                z-index: 9;
            }
            &:hover,
            &:focus {
                color: $black;
            }
        }
        li {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            .call-icon {
                font-family: 'Inter Bold';
                white-space: nowrap;
                font-size: 1.75rem;
                &::before {
                    display: none;
                }
                img {
                    width: auto;
                    height: 2.125rem !important;
                    padding-right: 0.625rem;
                    vertical-align: text-top;
                    @media screen and (max-width: 991px) {
                        padding-right: 0;
                    }
                }
                &:hover,
                &:focus {
                    color: $black;
                }
            }
            span {
                display: block;
                letter-spacing: 0;
                font-weight: $regularFont;
                padding-top: 0.125rem;
                text-align: right;
            }
            span:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
        li:nth-of-type(2) {
            flex-direction: column;
        }
        .input-group {
            border: 0.0625rem solid #979797;
            margin-bottom: 0;
            height: 100%;
            input[type="search"] {
                border: none;
                padding: 0 0 0 0.75rem;
                height: 1.8125rem;
                font-size: 0.6875rem;
                color: $primaryGrey;
                line-height: 1.5rem;
                border: 2px solid transparent;
                &::placeholder {
                    font-size: 0.875rem;
                    color: $primaryGrey;
                    line-height: 1.5rem;
                }
                &:focus {
                    box-shadow: none;
                    border: 2px solid $darkBlue;
                    outline: none;
                    outline-offset: 0;
                }
            }
            input[type="search"]::-webkit-search-decoration,
            input[type="search"]::-webkit-search-cancel-button,
            input[type="search"]::-webkit-search-results-button,
            input[type="search"]::-webkit-search-results-decoration {
                -webkit-appearance: none;
            }
            input[type="submit"] {
                height: 1.8125rem;
                border: none;
                border-left: 0;
                background: no-repeat center/0.75rem url(../images/searchIcon.svg);
                background-color: $white;
                padding: 0 0.625rem 0 1.5625rem;
                &:focus {
                    box-shadow: none;
                    border: 1px solid $darkBlue;
                    outline: none;
                    border-radius: 0;
                    outline-offset: 0;
                }
            }
        }
        .search-dropdown {
            margin-left: 1rem;
            vertical-align: top;
            @media screen and (max-width: 1230px) {
                margin-left: 0.4rem;
            }
            .dropdown-menu {
                padding: 0.625rem;
                box-shadow: 0 1px 21px 2px rgba(0, 0, 0, 0.20);
                &.show {
                    right: 0;
                    left: auto;
                    width: 100%;
                    min-width: 14.0625rem;
                }
                .form-control {
                    &:focus {
                        outline: 1px solid $darkBlue;
                    }
                }
            }
            .dropdown-toggle {
                border: none;
                background-color: transparent;
                color: $black;
                &:focus,
                &:hover {
                    text-decoration: underline;
                    color: $black;
                }
                &::after {
                    display: none;
                }
                img {
                    vertical-align: top;
                    padding-right: 0.375rem;
                }
            }
        }
    }
    .main-nav {
        display: flex;
        flex: 0 1 82%;
        flex-direction: column;
        padding-left: 0;
        @media screen and (max-width: 991px) {
            flex: 0;
            height: 5rem;
            padding-top: 0;
            justify-content: center;
        }
        a {
            border: 1px solid transparent;
        }
    }
    .navbar-nav {
        width: 100%;
        justify-content: center;
        @media screen and (max-width: 991px) {
            padding: 0;
            li:last-child {
                border-bottom: none;
            }
        }
        @media screen and (max-width: 1300px) {
            justify-content: space-around;
        }
        .dropdown-menu {
            background: rgba(255, 255, 255, 0.98);
            border-top: 1px solid #979797;
            border-right: none;
            border-radius: 0;
            margin: 0;
            @media screen and (max-width: 991px) {
                left: 100%;
                position: fixed;
                width: 100%;
                display: block;
                top: 0;
                height: 100%;
                opacity: 0;
                border: none;
                visibility: hidden;
            }
            &.show {
                opacity: 1;
                visibility: visible;
                padding: 2.4rem 0 3.65rem;
                margin-top: 0;
                @media screen and (max-width: 991px) {
                    border: none;
                    left: 0;
                    z-index: 99;
                }
            }
        }
        .dropmenu {
            max-width: 950px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin: auto;
            flex-wrap: wrap;
            @media screen and (max-width: 991px) {
                flex-direction: column;
                padding: 0;
                max-width: 100%;
            }
            .mega-menu-links {
                width: 100%;
                .inner-menu-row {
                    width: 100%;
                    display: flex;
                    @media screen and (max-width: 991px) {
                        flex-direction: column;
                        background-color: $white;
                    }
                }
            }
        }
        .menu-col {
            width: 20rem;
            padding-right: 2rem;
            flex: 1 0 0;
            &:last-child {
                padding-right: 0;
            }
            @media screen and (max-width: 991px) {
                width: 100%;
                padding-right: 0;
                background-color: $white;
            }
            .back-arrow-link {
                display: none;
                @media screen and (max-width: 991px) {
                    display: block;
                    width: 100%;
                    text-align: left;
                    padding: 0.625rem 0 0.625rem 1.2rem;
                    border: none;
                    background: transparent;
                    font-size: 0.875rem;
                    font-family: 'Inter Medium';
                    &::before {
                        transform: none;
                    }
                    &:focus,
                    &:hover,
                    &:active {
                        outline-offset: -2px;
                        color: $secondary;
                    }
                }
            }
            .inner-menu-list {
                list-style: none;
                @media screen and (max-width: 991px) {
                    padding: 0;
                    line-height: 1.5;
                    width: 100%;
                    padding-right: 1rem;
                    padding-left: 1rem;
                }
                .inner-list-item {
                    @media screen and (max-width: 991px) {
                        border-bottom: 1px solid #e3e3e3;
                    }
                }
                .dropdown-item {
                    padding-left: 0;
                    padding-bottom: 0;
                    font-size: 1rem;
                    color: $grey;
                    background-color: transparent;
                    display: block;
                    @media screen and (max-width: 991px) {
                        font-size: 0.875rem;
                        padding: 0.625rem 0;
                        margin-left: 0;
                    }
                    &:focus,
                    &:hover,
                    &:active {
                        color: $secondary;
                        opacity: 1;
                        text-decoration: underline;
                        background: none;
                    }
                }
            }
        }
        .col-section {
            @media screen and (max-width: 991px) {
                margin: 0 0.9375rem;
                .dropdown-menu-inner {
                    opacity: 0;
                    visibility: hidden;
                    left: 100%;
                    position: fixed;
                    width: 100%;
                    top: 0;
                    min-height: 100%;
                    // transition: all .5s ease-in-out;
                    z-index: 9;
                }
                &.active {
                    // transition: all .5s ease-in-out;
                    .dropdown-menu-inner {
                        display: block;
                        left: 0;
                        background-color: $white;
                        opacity: 1;
                        visibility: visible;
                        ul {
                            padding: 0 1rem;
                        }
                        p {
                            padding: 0.75rem 0;
                            margin: 0;
                        }
                    }
                }
            }
            &.col-info {
                @media screen and (max-width: 991px) {
                    margin-top: 1.25rem;
                }
                h2 {
                    @media screen and (max-width: 991px) {
                        font-weight: $boldFont;
                        padding: 0;
                    }
                }
                h2,
                p {
                    @media screen and (max-width: 991px) {
                        border-bottom: none;
                        margin-bottom: 0;
                    }
                    font-size: 0.75rem;
                }
            }
            h2,
            .dropdown-link,
            .mob-link {
                display: inline-block;
                margin-bottom: 0.5rem;
                //text-transform: uppercase;
                letter-spacing: 0.106875rem;
                text-align: left;
                line-height: 1rem;
                font-weight: $boldFont;
                color: $black;
                font-size: 1rem;
                @media screen and (max-width: 991px) {
                    display: block;
                    margin-bottom: 0;
                    line-height: 2.2rem;
                    padding-left: 1.4375rem;
                    border-bottom: 1px solid #e3e3e3;
                    padding: 0.375rem 0;
                    text-transform: capitalize;
                    letter-spacing: 0;
                    font-weight: $regularFont;
                    font-size: 0.875rem;
                }
                a {
                    color: $black;
                    display: inline-block;
                }
                p {
                    padding-top: 0.25rem;
                }
                h2 {
                    line-height: 1.2rem;
                }
            }
            .mob-link {
                display: none;
                @media screen and (max-width: 991px) {
                    display: block;
                    border: none;
                    border-bottom: 1px solid #e3e3e3;
                    width: 100%;
                    background: transparent;
                    color: $black;
                    font-size: 0.875rem;
                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }
            .desktop-link {
                display: block;
                color: $black;
                font-weight: 500;
                font-family: 'Inter Bold';
                font-size: 1.125rem;
                margin-bottom: 0.5rem;
                &:hover,
                &:focus {
                    color: $secondary;
                }
                @media screen and (max-width: 991px) {
                    display: none;
                }
            }
            h2.desktop-link, span.desktop-link {
                color: $grey;
                font-size: 1rem;
                margin-bottom: 0.8rem;
                font-family: 'Inter Medium';
                font-weight: 500;
                &:hover,
                &:focus {
                    color: $grey;
                }
            }
            .dropdown-link {
                color: $black;
                @media screen and (min-width: 991px) {
                    font-family: 'Inter Bold';
                    font-size: 1.125rem;
                    letter-spacing: 0;
                    margin-bottom: 1.65rem;
                }
                &:hover,
                &:focus {
                    font-weight: $boldFont;
                    @media screen and (max-width: 991px) {
                        font-weight: $regularFont;
                        color: $black;
                    }
                }
                @media screen and (max-width: 991px) {
                    display: block;
                }
            }
            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 1rem;
                @media screen and (max-width: 991px) {
                    padding-left: 0;
                    padding-bottom: 0.3125rem;
                    margin: 0;
                }
                li {
                    @media screen and (max-width: 991px) {
                        line-height: 1.25rem;
                        border-bottom: 1px solid #e3e3e3;
                    }
                }
            }
            .dropdown-item {
                display: inline-block;
                width: auto;
                padding: 0;
                font-weight: $regularFont;
                background-color: transparent;
                line-height: 1.5625rem;
                font-size: 1rem;
                color: $black;
                margin-bottom: 0.1875rem;
                white-space: normal;
                &:hover {
                    .dropdown-toggle::after {
                        color: $secondary;
                    }
                }
                &:hover,
                &:focus {
                    color: $secondary;
                    opacity: 1;
                    text-decoration: underline;
                }
                @media screen and (max-width: 991px) {
                    //color: $black;
                    letter-spacing: 0;
                    line-height: 1.5rem;
                    //font-weight: $regularFont;
                    padding: 0.625rem 0;
                    display: block;
                    margin-bottom: 0;
                    font-size: 0.875rem;
                }
            }
            .mobile-link {
                display: none;
                @media (max-width: 991px) {
                    display: block;
                }
            }
        }
        .btn-row {
            margin-top: 0.7rem;
            margin-bottom: 1.5rem;
            @media screen and (max-width: 991px) {
                padding-left: 0;
            }
        }
        h2.overview-link, span.overview-link,
        .overview-link {
            display: none;
            @media screen and (max-width: 991px) {
                display: block;
                background: $lightGreySecondary;
                font-size: 0.875rem;
                color: $grey;
                letter-spacing: 1.5px;
                font-weight: 500;
                padding: 0.625rem 1rem;
                line-height: 1.5rem;
                text-align: left;
                text-transform: uppercase;
                width: 100%;
            }
        }
        a.overview-link:focus {
            outline-offset: -2px;
        }
    }
    .nav-link {
        letter-spacing: 0;
        text-align: center;
        line-height: 1.5rem;
        padding: 0;
        display: flex;
        flex-direction: column;
        color: $black;
        font-size: 1rem;
        font-family: 'Inter Medium';
        font-weight: 500;
        border: 1px solid transparent;
        @media screen and (max-width: 1300px) {
            font-size: 0.875rem;
        }
        @media screen and (max-width: 991px) {
            font-weight: $regularFont;
            font-family: 'Inter Regular';
            display: block;
            text-align: left;
            position: relative;
            margin-left: 0;
            padding: 0.625rem 0;
        }
        &:hover,
        &:focus {
            text-decoration: underline;
            color: $secondary;
            @media screen and (min-width: 991px) {
                font-weight: $boldFont;
                font-family: 'Inter Bold';
            }
            // @media screen and (max-width: 991px) {
            //     font-weight: $regularFont;
            // }
        }
    }
    .nav-link::after {
        content: attr(data-title);
        content: attr(data-title) / "";
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: $boldFont;
        border: none;
        margin: 0;
        font-family: 'Inter Bold';
        @media speech {
            display: none;
        }
    }
    .mobile-icon {
        display: none;
    }
    @media screen and (max-width: 991px) {
        .close-icon {
            width: 4.1667rem;
        }
        .mobile-icon {
            display: flex;
            width: auto;
            justify-content: space-between;
            align-items: center;
            a {
                display: flex;
                padding-right: 0.85rem;
                img {
                    margin: 0 auto;
                }
                &:hover,
                &:focus {
                    font-weight: $regularFont;
                }
            }
            .search-info {
                height: 1.25rem;
                width: 1.25rem;
            }
        }
        .btn-close {
            margin-left: 0.4375rem;
            img {
                width: auto;
                height: auto;
            }
        }
        .navbar-toggler {
            padding: 0;
            .navbar-toggler-icon {
                display: inline-flex;
                width: 1.5rem;
                height: 1.5rem;
                vertical-align: top;
            }
        }
        .navbar-collapse {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 100%;
            padding-right: 1rem;
            padding-left: 1rem;
            overflow: auto;
            visibility: hidden;
            background-color: $white;
            z-index: 9999;
            height: 100vh;
            padding-top: 0.3125rem;
        }
        .navbar-collapse.show {
            visibility: visible;
            transform: translateX(-100%);
            z-index: 99;
            right: 0;
            height: 100vh;
            overflow-x: hidden;
            .dropdown {
                .dropdown-menu {
                    background: $white;
                    box-shadow: none;
                    margin: 0;
                    padding: 0;
                    //height: calc(100vh + 150px);
                }
            }
        }
        .navbar-collapse.show::-webkit-scrollbar {
            width: 0 !important;
        }
    }
    .arrow-right-link::before {
        display: inline-block;
        @media screen and (min-width: 992px) {
            display: none;
        }
    }
}

.menu-large {
    position: static !important;
    @media screen and (max-width: 991px) {
        line-height: 2.8125rem;
        border-bottom: 1px solid #e3e3e3;
    }
    .dropdown-toggle::after {
        display: none;
    }
}

.megamenu {
    padding: 1.8rem 0;
    width: 100%;
}

.megamenu>div>li>ul {
    padding: 0;
    margin: 0;
}

@media (max-width: 768px) {
    .megamenu {
        margin-left: 0;
        margin-right: 0;
    }
    .megamenu>li {
        margin-bottom: 1.875rem;
    }
    .megamenu>li:last-child {
        margin-bottom: 0;
    }
    .megamenu.dropdown-header {
        padding: 0.1875rem 0.9375rem;
    }
    .navbar-nav .open .dropdown-menu .dropdown-header {
        color: $white;
    }
}

//CSS changes for showing menu on hover in desktop version
@media (min-width: 992px) {
    .header-classic .main-nav .navbar-nav .dropdown>.dropdown-toggle:active {
        /*Without this, clicking will make it sticky*/
        pointer-events: none;
    }
    .header-classic .main-nav .navbar-nav .dropdown:hover>.dropdown-menu {
        display: block;
    }
    .header-classic .main-nav .navbar-nav .dropdown:focus-within>.dropdown-menu {
        display: block;
    }
    .header-classic .main-nav .navbar-nav .dropdown:hover>a {
        font-weight: 500;
        text-decoration: underline;
        color: $secondary;
        font-family: 'Inter Bold';
    }
    .menu-large {
        display: inline-flex;
        align-items: center;
        // height: 4.5625rem;
    }
}

.mobile-icon {
    display: flex;
    .btn-phone {
        min-width: 2.1875rem;
        text-align: center;
        vertical-align: middle;
        line-height: 2.0625rem;
        min-height: 2.1875rem;
    }
    .search-button {
        position: relative;
        border: none;
        background-color: transparent;
    }
}

.back-arrow-link {
    font-size: 0.875rem;
    color: $darkBlue;
    letter-spacing: 0;
    line-height: 1.5rem;
    font-weight: $regularFont;
    color: $black;
    &::before {
        content: "";
        display: inline-block;
        background: url(../images/left-chevron-black.svg) top no-repeat;
        background-size: inherit;
        width: 0.5625rem;
        height: 0.5625rem;
        padding: 0.3125rem;
        margin-right: 0.4375rem;
        margin-left: -0.25rem;
    }
    &:hover,
    &:focus {
        outline-offset: -2px;
        &::before {
            background: url(../images/left-chevron-red.svg) top no-repeat;
        }
    }
}

.arrow-right-link {
    position: relative;
    &::before {
        content: '';
        background: no-repeat center url(../images/right-chevron-black.svg);
        height: 0.625rem;
        width: 0.5rem;
        position: absolute;
        display: inline-block;
        margin-right: 0.625rem;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
    }
    &:hover,
    &:focus {
        text-decoration: underline;
        &::before {
            background: no-repeat center url(../images/right-chevron-red.svg);
        }
    }
}

.header-classic .mob-header-search {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
    width: 0%;
    visibility: hidden;
    .input-group {
        border: none;
        margin: 0.75rem 0.75rem 0;
    }
    &.open-search {
        visibility: visible;
        opacity: 1;
        display: flex;
        z-index: 9;
        width: 100%;
        min-width: 2.5rem;
        top: 0;
        right: 0;
        height: 4.875rem;
        background: $white;
        input[type="search"] {
            height: 100%;
            padding-left: 2.8125rem;
            &:focus {
                outline: 2px solid $darkBlue;
                border: none;
                outline-offset: -3px;
            }
        }
        .search-icon {
            z-index: 9;
            &::before {
                position: absolute;
                width: 1.25rem;
                height: 1.25rem;
                background: no-repeat center/1rem url(../images/searchIcon.svg);
                content: '';
                top: 50%;
                left: 0.9375rem;
                -webkit-transform: translateY(-48%);
                transform: translateY(-48%);
                pointer-events: none;
            }
        }
        input[type="search"] {
            font-size: 1rem;
            &::placeholder {
                font-size: 1rem;
            }
        }
        .close-header-search {
            border: none;
            background: $white;
            width: 2rem;
            font-size: 2rem;
            margin-left: 1px;
            &:focus {
                outline: 2px solid $secondary;
                border: none;
                outline-offset: -3px;
            }
        }
    }
}

// Mobile Menu Request Section
.mobile-logo-sec {
    display: none;
    @media screen and (max-width: 991px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.625rem 0 1.875rem;
    }
    .sm-logo {
        width: 8.125rem;
        img {
            width: 100%;
        }
    }
    .close-btn {
        border: none;
        background-color: transparent;
    }
}

// Find Section On Mobile Section
.find-sec {
    display: none;
    @media only screen and (max-width: 991px) {
        display: block;
        margin-top: 0.6rem;
        margin-bottom: 2rem;
    }
    .location-section {
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        margin-top: 0.65rem;
        @media only screen and (max-width: 767px) {
            padding: 0 0.9375rem;
        }
    }
    label {
        font-size: 1rem;
        color: $secondary;
        letter-spacing: 0.96px;
        line-height: 1.5rem;
        width: 100%;
        margin: 1.25rem 0;
        font-weight: $boldFont;
        font-family: 'Inter Bold';
    }
    input[type="text"] {
        background: $white;
        border: 1px solid $blue;
        height: 3rem;
        border-radius: 0;
        color: $primaryGrey;
        font-size: 0.875rem;
        width: 100%;
        margin-bottom: 1.25rem;
        padding-left: 0.625rem;
        max-width: 18.75rem;
        display: block;
    }
}

.offcanvas-header .mobile-icon {
    z-index: 999;
    .mob-header-search {
        margin-top: 1.25rem;
        height: 3.125rem;
    }
}

.nav-links {
    @media (max-width: 991px) {
        position: relative;
    }
    .hamburger-menu {
        width: 2.5rem;
        text-align: center;
        font-size: 0;
        cursor: pointer;
        display: none;
        position: absolute;
        right: 0.9375rem;
        top: -0.625rem;
        @media (max-width: 991px) {
            display: block;
        }
        span {
            width: 1.375rem;
            height: 0.125rem;
            background: #DA291C;
            display: inline-block;
            margin: 0.125rem 0;
        }
    }
    .nav-section {
        @media (max-width: 1670px) {
            width: 100%;
        }
        @media (min-width: 992px) {
            margin: 0 auto;
        }
        @media (min-width: 992px) {
            .nav-link {
                padding-right: 1px;
                padding-left: 1px;
                // line-height: 3.125rem;
                line-height: 4.5625rem;
                &:focus, &:hover, &:active{
                    outline-offset: -4px;
                }
            }
        }
        // css changes for emergency service link
        @media (min-width: 1150px) {
            .nav-link {
                padding-right: 0.4rem;
                padding-left: 0.4rem;
            }
        }
        @media (min-width: 1650px) {
            .nav-link {
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }
    }
}

.nav-container {
    width: 100%;
    max-width: 100%;
    @media (min-width: 1441px) {
        padding-left: 4.375rem;
        padding-right: 4.375rem;
    }
}

.col-sec-left {
    flex: 0 0 22%;
    max-width: 22%;
    padding: 0;
    align-items: center;
    height: 100%;
    @media screen and (max-width: 1199px) {
        flex: 0 0 18%;
        max-width: 18%;
    }
    @media screen and (max-width: 991px) {
        flex: 0 0 80%;
        max-width: 80%;
    }
}

.col-sec-middle {
    padding: 0;
    flex: 0 0 55%;
    max-width: 55%;
    @media screen and (min-width: 1440px) {
        flex: 0 0 60%;
        max-width: 60%;
    }
    @media screen and (min-width: 1820px) {
        flex: 0 0 70%;
        max-width: 70%;
    }
}

.col-sec-right {
    padding: 0;
    flex: 0 0 45%;
    max-width: 45%;
    @media screen and (min-width: 1440px) {
        flex: 0 0 40%;
        max-width: 40%;
    }
    @media screen and (min-width: 1820px) {
        flex: 0 0 30%;
        max-width: 30%;
    }
}

.col-btn {
    padding-left: 0;
    flex: 0 0 25%;
    max-width: 25%;
    @media screen and (max-width: 1199px) {
        flex: 0 0 23%;
        max-width: 23%;
    }
    @media screen and (max-width: 991px) {
        display: none;
    }
}

.col-main-nav {
    padding: 0;
    flex: 0 0 75%;
    max-width: 75%;
    @media screen and (max-width: 1199px) {
        flex: 0 0 77%;
        max-width: 77%;
    }
    @media screen and (max-width: 991px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.header-left-sec {
    padding-right: 0;
    flex: 0 0 78%;
    max-width: 78%;
    @media screen and (min-width: 991px) {
        padding: 0.5rem 0 0 0;
    }
    .row {
        @media screen and (min-width: 991px) {
            margin-top: 0.125rem;
        }
    }
    @media screen and (max-width: 1199px) {
        flex: 0 0 82%;
        max-width: 82%;
    }
    @media screen and (max-width: 991px) {
        flex: 0 0 20%;
        max-width: 20%;
    }
    .desktop-btn {
        display: none;
        @media (min-width: 992px) {
            display: flex;
            justify-content: flex-end;
        }
        .primary-btn {
            @media (max-width: 1300px) {
                padding: 0 0.625rem;
                font-size: 1rem;
                min-width: auto;
            }
            @media (max-width: 1150px) {
                font-size: 0.875rem;
            }
        }
    }
}

.mobile-link {
    display: none;
    @media (max-width: 991px) {
        display: block;
    }
}

.mega-menu-front {
    list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
    .list-item {
        display: flex;
        margin-bottom: 1.8rem;
        flex: 0 0 25%;
        max-width: 25%;
        padding-right: 1.5rem;
        font-family: 'Inter Medium';
        font-size: 0.875rem;
        color: $grey;
        font-weight: 500;
        @media screen and (max-width: 991px) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-right: 0;
            margin-bottom: 0;
            display: flex;
            width: 100%;
            background-color: $white;
        }
    }
    button {
        background-color: transparent;
        display: flex;
        align-items: center;
        text-align: left;
        min-height: 50px;
        border: none;
        width: 100%;
        @media screen and (max-width: 991px) {
            margin: 0 1rem;
            padding-left: 1.4375rem;
            border-bottom: 1px solid #e3e3e3;
            padding: 0;
            text-transform: capitalize;
            letter-spacing: 0;
            font-weight: 400;
            min-height: 46px;
            padding: 0.625rem 0;
            line-height: 1.5rem;
            font-family: 'Inter Regular';
            font-size: 0.875rem;
            img {
                display: none;
            }
        }
        span {
            padding-left: 1.25rem;
            display: inline-block;
            max-width: 126px;
            line-height: 1.0625rem;
            @media screen and (max-width: 991px) {
                padding-left: 0;
                max-width: 100%;
            }
        }
        img {
            &.img-hover {
                display: block;
                @media screen and (max-width: 991px) {
                display: none;
                }
            }
        }
        &.back-arrow-link {
            display: none;
            @media screen and (max-width: 991px) {
                display: block;
                border: none;
                font-family: 'Inter Medium';
                padding: 0.625rem 0 0.625rem 1.2rem;
                margin: 0;
                min-height: auto;
            }
        }
        &:focus,
        &:hover,
        &:active {
            color: $secondary;
            text-decoration: underline;
            z-index: 99;
            img {
                display: none;
                &.img-hover {
                    display: block;
                    @media screen and (max-width: 991px) {
                        display: none;
                    }
                }
            }
        }
    }
}

// This is used for only header menu
.desktop-item {
    display: none;
    @media screen and (min-width: 992px) {
        display: flex;
        height: 1.625rem;
        align-items: center;
        margin-bottom: 1.5625rem;
        list-style: none;
        li {
            display: inline-block;
            a {
                font-size: 1rem;
                color: $grey;
                display: inline-block;
                &.nav-title {
                    font-family: 'Inter Bold';
                    color: $secondary;
                    font-weight: 700;
                    text-decoration: none;
                }
            }
        }
        .active {
            &::before {
                padding: 0 5px 0 9px;
                color: $grey;
                content: " | ";
            }
        }
    }
}
//Mobile sticky header US 118250 Start here
// $color_1: #fff;
// $font-family_1: 'Inter Medium';

.header-classic.mobile-header {
	display: none;
}
.scheduler-modal {
	.result-Stack-logo {
		margin-top: 0.6875rem;
	}
}
@media screen and (max-width: 991px) {
	.header-classic.mobile-header {
        display: block !important;
		padding-bottom: 0;
		transition: all 0.5s ease-in-out;
		.navbar-nav {
			.col-section.active {
				.dropdown-menu-inner {
					position: absolute;
					padding: 0 1rem;
				}
			}
			.menu-col {
				.col-section {
					margin: 0;
				}
				.inner-menu-list {
					display: block;
					padding: 0px;
					padding: 0;
				}
				.back-arrow-link {
					padding: 0.625rem 0 0.625rem 0.325rem;
					line-height: 1.72;
				}
			}
			h2.overview-link, span.overview-link {
				padding: 0.625rem;
			}
			.overview-link {
				padding: 0.625rem;
			}
			.col-section {
				ul {
					display: block;
					padding: 0px;
				}
			}
			.dropdown-menu {
				position: absolute;
			}
			.dropmenu {
				.mega-menu-links {
					.inner-menu-row {
						.menu-col {
							padding: 0;
						}
					}
				}
			}
		}
		.navbar-collapse.show {
			.dropdown {
				.dropdown-menu {
					padding: 0 1rem;
				}
			}
			position: absolute;
			width: 100%;
			left: auto;
			right: 0;
			top: 0;
			z-index: 9;
			transform: none;
			opacity: 1;
			padding-top: 5px;
			border-top: 1px solid #cccccca1;
            height: 100vh;
		}
		.logo-sec {
			.navbar-brand {
				margin: auto;
				padding: 0;
				img {
					max-width: 11.9375rem;
				}
			}
		}
		.nav-container {
			.row {
				margin: 0;
			}
		}
		.main-nav {
			height: auto;
		}
		.navbar-collapse {
			.mobile-search {
				margin-bottom: 10px;
			}
		}
	}
	body {
		.scheduler-modal {
			.result-Stack-logo {
				width: 100%;
				display: block;
				min-height: 3.6875rem;
				img {
					width: 10.25rem;
				}
			}
			.modal-dialog {
				.close {
					top: -1rem;
				}
			}
			.head-title {
				margin: 0.8125rem auto;
			}
		}
	}
	.scheduler-modal {
		.neighborly-stamp-wrap {
			margin-top: 10px;
		}
	}
	.header-classic.desktop-header {
		display: none;
	}
	.mobile-header {
		.request-mobile-sec {
			padding: 0;
			ul.nav {
				li {
					width: 100%;
				}
			}
		}
		.col-sec-left {
			flex: 0 0 100%;
			max-width: 100%;
			padding-bottom: 10px;
			text-align: center;
		}
		.main-nav {
			.mega-menu-front {
				button.back-arrow-link {
					padding: 0.625rem 0 0.625rem 0.325rem;
					line-height: 1.72;
				}
				button {
					margin: 0;
				}
			}
		}
		.mobile-logo-sec {
			padding-bottom: 0.875rem;
			align-items: start;
		}
		.logo-sec.hide {
			display: block;
		}
		.request-mobile-sec.sticky-header.sticky {
			.logo-sec {
				display: none;
			}
		}
	}
	.offcanvas-active {
		.mobile-header {
			.header-left-sec {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
	.request-mobile-sec.sticky-header {
		.find-sec {
			.primary-btn {
				min-width: 15rem;
				min-height: 2.75rem;
			}
		}
		ul {
			li {
				.call-icon {
					color: #fff;
					justify-content: start;
					font-family: 'Inter Medium';
					font-weight: normal;
					font-size: 1.1875rem;
					font-weight: 500;
					line-height: 2.5;
					min-width: 100%;
					min-height: 2.8125rem;
					color: #fff;
					justify-content: start;
					font-family: 'Inter Medium';
					font-weight: normal;
					min-width: 8rem;
					min-height: 2.125rem;
					font-size: 1.1875rem;
					padding-left: 0.8125rem;
					font-size: 0.8125rem;
					img {
						width: 14px;
						height: 22px;
						margin-right: 5px;
						margin-top: -2px;
						width: 14px;
						height: 18px;
						margin-right: 5px;
						margin-top: -2px;
					}
				}
				span {
					font-size: 0.875rem;
				}
			}
		}
		>ul {
			align-items: center;
			padding: 1rem;
		}
		.primary-btn {
			min-width: 100%;
			min-height: 2.125rem;
			font-size: 0.8125rem;
		}
	}
	.sticky-header {
		.nav-links {
			.hamburger-menu {
				text-align: center;
				margin: auto;
				display: block;
				background: #da291c;
				width: 2.8rem;
				padding: 7px 10px;
				right: 0;
				top: 0;
				position: relative;
				span {
					background: #fff;
				}
				&:hover {
					background: #b00006;
				}
			}
			.hamburger-menu.activate {
				span {
					&:nth-child(2) {
						opacity: 0;
					}
					&:nth-child(1) {
						transform: rotate(45deg);
						position: relative;
						top: 6px;
					}
					&:nth-child(3) {
						transform: rotate(-45deg);
						top: -6px;
						position: relative;
					}
				}
			}
		}
	}
	.request-mobile-sec.sticky-header.sticky {
		position: fixed;
		width: 100%;
		z-index: 99;
		top: 0;
		padding-bottom: 0;
	}
	body.offcanvas-active {
		.mobile-header {
			.logo-sec {
				display: none;
			}
		}
	}
}
@media (max-width: 767px) {
	.request-mobile-sec.sticky-header {
		ul {
			li {
				.call-icon {
					font-size: 0.8125rem;
				}
			}
		}
	}
}