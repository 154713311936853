.nav-arrow {
  font-weight: 700;
  font-family: 'Inter Bold';
}

@media screen and (max-width: 567px) {
  .nav-arrow {
    text-indent: -99999px;
    float: left;
  }
}

.pagination-section {
  padding: 3.1875rem 0;
}

.pagination-section .pagination {
  max-width: 616px;
  margin: 0 auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 767px) {
  .pagination-section .pagination {
    max-width: 520px;
  }
}

@media screen and (max-width: 567px) {
  .pagination-section .pagination {
    max-width: 315px;
  }
}

@media screen and (max-width: 350px) {
  .pagination-section .pagination {
    max-width: 290px;
  }
}

.pagination-section .pagination a[aria-current="page"] {
  font-weight: 700;
  font-family: 'Inter Bold';
}

.pagination-section .pagination .pagination-item {
  color: #DA291C;
  position: relative;
  font-size: 1.125rem;
}

.pagination-section .pagination .disabled, .pagination-section .pagination :disabled {
  color: #898989;
  pointer-events: none;
}

.pagination-section .pagination .nav-arrow.first {
  padding-left: 1.85rem;
}

.pagination-section .pagination .nav-arrow.first::before {
  background-image: url(/us/en-us/mr-handyman/_assets/images/right-dbl-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 1.25rem;
  height: 0.938rem;
  position: absolute;
  content: "";
  top: 60%;
  left: 0;
  -webkit-transform: translate(0, -60%) rotate(180deg);
          transform: translate(0, -60%) rotate(180deg);
}

.pagination-section .pagination .nav-arrow.first:disabled::before, .pagination-section .pagination .nav-arrow.first.disabled::before {
  background-image: url(/us/en-us/mr-handyman/_assets/images/right-dbl-gray-arrow.svg);
}

.pagination-section .pagination .nav-arrow.prev {
  padding-left: 1.2rem;
}

.pagination-section .pagination .nav-arrow.prev::before {
  background-image: url(/us/en-us/mr-handyman/_assets/images/right-chevron-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 0.563rem;
  height: 0.938rem;
  position: absolute;
  content: "";
  -webkit-transform: translate(0, -60%);
          transform: translate(0, -60%);
  top: 60%;
  left: 0;
}

.pagination-section .pagination .nav-arrow.prev:disabled::before, .pagination-section .pagination .nav-arrow.prev.disabled::before {
  background-image: url(/us/en-us/mr-handyman/_assets/images/left-chevron-arrow.svg);
}

.pagination-section .pagination .nav-arrow.next {
  padding-right: 1.2rem;
}

.pagination-section .pagination .nav-arrow.next::after {
  background-image: url(/us/en-us/mr-handyman/_assets/images/right-chevron-arrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 0.563rem;
  height: 0.938rem;
  position: absolute;
  content: "";
  -webkit-transform: translate(0, -65%) rotate(180deg);
          transform: translate(0, -65%) rotate(180deg);
  top: 65%;
  right: 0;
}

.pagination-section .pagination .nav-arrow.next:disabled::after, .pagination-section .pagination .nav-arrow.next.disabled::after {
  background-image: url(/us/en-us/mr-handyman/_assets/images/left-chevron-arrow.svg);
}

.pagination-section .pagination .nav-arrow.last {
  padding-right: 1.85rem;
}

.pagination-section .pagination .nav-arrow.last::after {
  background-image: url(/us/en-us/mr-handyman/_assets/images/right-dbl-arrow.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 1.25rem;
  height: 0.938rem;
  position: absolute;
  content: "";
  -webkit-transform: translate(0, -65%);
          transform: translate(0, -65%);
  top: 65%;
  right: 0;
}

.pagination-section .pagination .nav-arrow.last:disabled::after, .pagination-section .pagination .nav-arrow.last.disabled::after {
  background-image: url(/us/en-us/mr-handyman/_assets/images/right-dbl-gray-arrow.svg);
}

.page-numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
}

.page-numbers li:not(:last-child) {
  margin-right: 1.125rem;
}

.pagination-search .search-form {
  margin: 0 auto;
  max-width: 560px;
  padding: 1.8rem 0 1.25rem;
}

.pagination-search .search-form label {
  display: block;
}

.pagination-search .search-form .form-control {
  display: inline-block;
  width: 100%;
  height: 2.8125rem;
  padding: 0.9375rem;
  line-height: 1.125rem;
  max-width: 240px;
  border: 1px solid #002f65;
  margin-right: 2.125rem;
}

.pagination-search .search-form .form-control::-webkit-input-placeholder {
  font-style: italic;
}

.pagination-search .search-form .form-control:-ms-input-placeholder {
  font-style: italic;
}

.pagination-search .search-form .form-control::-ms-input-placeholder {
  font-style: italic;
}

.pagination-search .search-form .form-control::placeholder {
  font-style: italic;
}

.pagination-search .search-form input[type="submit"] {
  min-width: auto;
}

@media screen and (max-width: 390px) {
  .pagination-search .search-form input[type="submit"] {
    margin-top: 0.625rem;
  }
}

.social-media-pagination {
  padding: 3.938rem 0;
}

@media screen and (max-width: 767px) {
  .social-media-pagination {
    padding: 1.375rem 0 1.688rem;
  }
}

.social-media-pagination .category-row strong,
.social-media-pagination .tags-row strong {
  padding-right: 0.625rem;
}

.social-media-pagination .category-row ul,
.social-media-pagination .tags-row ul {
  display: inline-block;
}

.social-media-pagination .category-row ul .list-inline-item:not(:last-child),
.social-media-pagination .tags-row ul .list-inline-item:not(:last-child) {
  margin-right: 0.15rem;
}

.social-media-pagination .link-item {
  color: #0D0D0D;
  font-size: 1.125rem;
  text-decoration: underline;
}

.social-media-pagination .social-icons-list {
  padding-left: 0.75rem;
  display: inline-block;
}

.social-media-pagination .social-icons-list a {
  display: inline-block;
  border: 1px solid transparent;
}

.social-media-pagination .social-icons-list .list-inline-item:not(:last-child) {
  margin-right: 0.875rem;
}

@media screen and (max-width: 500px) {
  .social-media-pagination .social-icons-list {
    display: block;
    padding-left: 0;
    padding-top: 1rem;
  }
}

.social-media-pagination .bottom-nav {
  padding-top: 2.1875rem;
}

@media screen and (max-width: 767px) {
  .social-media-pagination .bottom-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}

.social-media-pagination .bottom-nav::before {
  content: '';
  height: 2px;
  width: 98%;
  margin: 0 auto;
  background: #B2B2B2;
}

@media screen and (max-width: 767px) {
  .social-media-pagination .bottom-nav::before {
    display: none;
  }
}

.social-media-pagination .bottom-nav .next-article-link {
  padding-left: 55px;
}

@media screen and (max-width: 767px) {
  .social-media-pagination .bottom-nav .next-article-link {
    padding-left: 0;
  }
}

.social-media-pagination .link-text {
  position: relative;
  margin-top: 0;
  font-size: 1.125rem;
  line-height: normal;
}

.social-media-pagination .link-text::before {
  position: absolute;
  left: 0;
  top: 0;
  margin-right: 0;
}

.social-media-pagination .link-text.prev-arrow {
  padding-left: 2rem;
}

.social-media-pagination .link-text.prev-arrow::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.social-media-pagination .link-text.next-arrow {
  padding-right: 2rem;
}

.social-media-pagination .link-text.next-arrow::before {
  left: auto;
  right: 0;
}

@media screen and (max-width: 767px) {
  .social-media-pagination .link-text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 575px) {
  .social-media-pagination .link-text {
    font-size: 0.938rem;
    line-height: inherit;
  }
}

.social-media-pagination .back-to-top-btn {
  text-align: right;
  margin-top: 1.75rem;
}

@media screen and (max-width: 767px) {
  .social-media-pagination .back-to-top-btn {
    text-align: center;
    margin-top: 0;
  }
}

.social-media-pagination .back-to-top-btn .link-text {
  padding-left: 2rem;
  background-color: transparent;
}

.social-media-pagination .back-to-top-btn .link-text::before {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.social-media-pagination .back-to-top-btn .link-text:hover, .social-media-pagination .back-to-top-btn .link-text:focus {
  text-decoration: underline;
}

.social-media-pagination .article-pagination {
  margin-top: 1.75rem;
}

@media screen and (max-width: 767px) {
  .social-media-pagination .article-pagination {
    border-top: 2px solid #B2B2B2;
    padding-top: 1.85rem;
  }
  .social-media-pagination .article-pagination .list-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

.section-hr {
  border-top: 10px solid #e3e3e3;
}

.pagination-section {
  position: relative;
  padding-top: 0;
}

.pagination-section:before {
  content: '';
  display: block;
  height: 2px;
  width: 96%;
  margin: 0 auto;
  background-color: #ccc;
  margin-bottom: 3.1875rem;
  max-width: 1200px;
}

@media screen and (max-width: 575px) {
  .pagination-section:before {
    width: 92%;
  }
}