header {
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

@media screen and (max-width: 991px) {
    body.offcanvas-active {
        overflow: hidden;
    }
}

.offcanvas-header {
    display: none;
}

.hide-notify.notify-sec {
    display: none;
}

@media screen and (max-width: 991px) {
    .hide-notify.notify-sec {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.notify-remove main {
    margin-top: 0;
}

.skip-main {
    left: -62.4375rem;
    position: absolute;
    top: auto;
    width: 0.063rem;
    height: 0.063rem;
    overflow: hidden;
    z-index: -999;
    font-weight: 400;
    color: #142651;
}

.skip-main:focus {
    background-color: #ffffff;
    left: 0.125rem;
    width: 20%;
    height: auto;
    overflow: auto;
    text-align: center;
    font-size: 0.9rem;
    z-index: 9999;
    text-decoration: underline;
    outline: 2px solid #ffffff;
}

.skip-main:hover {
    color: #142651;
}

.notify-sec {
    height: 3.75rem;
    background-color: #142651;
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .notify-sec {
        height: 4.8rem;
    }
}

.notify-sec .notify-txt {
    max-width: 80%;
    margin: 0 auto;
}

.notify-sec p {
    color: #FFC733;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0;
}

.notify-sec p a {
    color: #ffffff;
    margin: 0 0.3125rem;
}

.notify-sec p a:focus {
    outline: 0.125rem solid #ffffff;
}

@media only screen and (max-width: 991px) {
    .notify-sec p {
        font-size: 0.75rem;
    }
}

.notify-sec .close-icn {
    position: absolute;
    right: 0.625rem;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    opacity: 1;
    cursor: pointer;
    background: none;
    color: #ffffff;
    border: none;
    font-size: 2.0625rem;
    line-height: 1.625rem;
    display: inline-block;
}

.notify-sec .close-icn:focus {
    outline: 2px solid #ffffff;
}

@media only screen and (max-width: 991px) {
    .notify-sec .close-icn {
        right: 4%;
        top: 50%;
    }
}

.notify-hide .notify-sec {
    display: none;
}

.neighborly-sec {
    height: 3.75rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Inter Regular';
    position: relative;
}

.neighborly-sec p,
.neighborly-sec a {
    margin: 0;
}

.neighborly-sec .nei-mobile-logo {
    display: none;
}

.neighborly-sec .nei-mobile-logo img {
    vertical-align: top;
    margin-top: 3px;
}

@media screen and (max-width: 991px) {
    .neighborly-sec .nei-mobile-logo {
        width: 1rem;
        display: block;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 375px) {
    .neighborly-sec .nei-mobile-logo {
        width: 1.1rem;
    }
}

@media screen and (max-width: 767px) {
    .neighborly-sec .nei-logo-wrap {
        padding-right: 0;
    }
}

.neighborly-sec ul {
    list-style-type: none;
    padding: 0.3125rem 0 0;
    margin: 0;
    text-align: right;
}

@media screen and (max-width: 767px) {
    .neighborly-sec ul {
        padding: 0;
    }
}

.neighborly-sec ul li {
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.neighborly-sec ul li:last-child {
    padding: 0;
}

.neighborly-sec ul li a {
    text-decoration: none;
}

.neighborly-sec ul li a:focus,
.neighborly-sec ul li a:hover {
    text-decoration: underline;
    color: #ffffff;
}

.neighborly-sec ul .nei-guarntee {
    padding-left: 2.6875rem;
    margin-right: 0.9375rem;
}

.neighborly-sec ul .nei-guarntee::before {
    position: absolute;
    content: '';
    background: url("/us/en-us/mr-handyman/_assets/images/guarantee-icon-FPO.png") no-repeat top left;
    width: 2.125rem;
    height: 2.3rem;
    top: -0.4375rem;
    left: 0;
    background-size: cover;
}

.neighborly-sec ul .sign-in::before {
    position: absolute;
    content: '';
    background: url("/us/en-us/mr-handyman/_assets/images/login.svg") no-repeat top left;
    width: 1.25rem;
    height: 1.25rem;
    top: 0;
    left: 0;
    background-size: contain;
}

@media screen and (max-width: 991px) {
    .neighborly-sec ul .sign-in::before {
        width: 0.75rem;
        height: 0.9375rem;
        top: 4px;
    }
}

.neighborly-sec ul .sign-in a {
    position: relative;
    padding-right: 0;
    padding-left: 1.875rem;
}

.neighborly-sec ul .sign-in a::after {
    position: absolute;
    content: '';
    right: 0.4375rem;
    top: 0.125rem;
    background: #AEDAF3;
    width: 10.0625rem;
    height: 0.9375rem;
}

.neighborly-sec ul .sign-in a:last-child::after {
    display: none;
}

.neighborly-sec ul .sign-in a img {
    height: 1.125rem;
    width: auto;
    padding-right: 0.25rem;
}

@media screen and (max-width: 991px) {
    .neighborly-sec ul .sign-in a {
        padding-left: 1.125rem;
    }
}

.neighborly-sec ul .join-col::before {
    padding: 0 0.3125rem;
    color: #ffffff;
    content: " | ";
    line-height: 0.75rem;
}

@media screen and (max-width: 991px) {
    .neighborly-sec ul .join-col::before {
        font-size: 0.6875rem;
        padding: 0;
    }
}

@media screen and (max-width: 991px) {
    .neighborly-sec .neighbourly-Box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .neighborly-sec .neighbourly-Box p {
        padding-left: 0.625rem;
        line-height: 1.25rem;
    }

    .neighborly-sec .neighborly-logo {
        display: none;
    }

    .neighborly-sec .nei-guarntee {
        display: none;
    }
}

@media screen and (max-width: 1319px) {
    .neighborly-sec .container {
        padding: 0 0.9375rem;
    }
}

.neighborly-sec .col-sec {
    padding: 0 0.9375rem;
}

@media screen and (max-width: 450px) {
    .neighborly-sec .col-sec {
        max-width: 60%;
    }
}

.request-mobile-sec {
    display: none;
}

@media screen and (max-width: 991px) {
    .request-mobile-sec {
        display: block;
        background-color: #ffffff;
        padding: 0.9375rem;
    }

    .request-mobile-sec ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
    }

    .request-mobile-sec ul li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .request-mobile-sec ul li .call-icon {
        font-size: 0.875rem;
        color: #000000;
        font-weight: 500;
        font-family: 'Inter Bold';
    }

    .request-mobile-sec ul li .call-icon::before {
        display: none;
    }

    .request-mobile-sec ul li .call-icon img {
        height: 1rem;
        vertical-align: text-top;
    }

    .request-mobile-sec ul li span {
        font-size: 0.625rem;
        color: #4e4e4e;
        display: inline-block;
        margin-top: 0.1875rem;
    }
}

@media screen and (max-width: 767px) {
    .request-mobile-sec .primary-btn {
        min-width: 10rem;
        min-width: 10rem;
        line-height: 2.125rem;
        min-height: 2.125rem;
        font-size: 0.8125rem;
    }
}

@media screen and (max-width: 360px) {
    .request-mobile-sec .primary-btn {
        padding: 0;
        min-width: 9rem;
        font-size: 0.75rem;
    }
}

@media screen and (max-width: 360px) {
    .request-mobile-sec {
        font-size: 0.75rem;
    }
}

.mobile-search {
    display: none;
}

@media screen and (max-width: 991px) {
    .mobile-search {
        display: block;
    }

    .mobile-search ul {
        list-style: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .mobile-search ul li {
        padding-right: 0.9375rem;
    }
}

@media screen and (max-width: 991px) and (max-width: 380px) {
    .mobile-search ul li {
        padding-right: 0.5rem;
    }
}

@media screen and (max-width: 991px) {
    .mobile-search ul li a {
        font-size: 0.875rem;
        color: #000000;
        letter-spacing: 0;
    }
}

/*-- ================================
           Top Header Section End
===================================== */
.top-menu {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media screen and (min-width: 992px) {
    .top-menu {
        min-height: 4.125rem;
    }
}

@media screen and (max-width: 991px) {
    .top-menu {
        position: static;
        background: none;
        padding: 0;
        height: auto;
        display: block;
        width: 100%;
        opacity: 1;
    }
}

.top-menu .locate-form-wrapper {
    width: 100%;
    position: absolute;
    left: 5rem;
}

@media screen and (max-width: 1200px) {
    .top-menu .locate-form-wrapper {
        left: 6.5rem;
    }
}

@media screen and (min-width: 1440px) {
    .top-menu .locate-form-wrapper {
        left: 4rem;
    }
}

@media screen and (min-width: 1820px) {
    .top-menu .locate-form-wrapper {
        left: 0.3rem;
    }
}

@media screen and (max-width: 991px) {
    .top-menu .locate-form-wrapper {
        display: none;
    }
}

.top-menu .locate-form-wrapper .locate-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 0;
}

.top-menu .locate-form-wrapper .locate-form .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    line-height: 1.5;
    letter-spacing: 0;
    text-align: right;
}

.top-menu .locate-form-wrapper .locate-form .form-control {
    background: #ffffff;
    border: 1px solid #002f65;
    height: 3rem;
    border-radius: 0;
    max-width: 10.5rem;
    margin: 0 1.5625rem;
}

.top-menu .locate-form-wrapper .locate-form .form-control::-webkit-input-placeholder {
    color: #666666;
    font-size: 0.875rem;
}

.top-menu .locate-form-wrapper .locate-form .form-control:-ms-input-placeholder {
    color: #666666;
    font-size: 0.875rem;
}

.top-menu .locate-form-wrapper .locate-form .form-control::-ms-input-placeholder {
    color: #666666;
    font-size: 0.875rem;
}

.top-menu .locate-form-wrapper .locate-form .form-control::placeholder {
    color: #666666;
    font-size: 0.875rem;
}

.top-menu .locate-form-wrapper .locate-form .inputPostal {
    width: 17.625rem;
    padding: 0 1.25rem;
    position: relative;
}

@media screen and (max-width: 1365px) {
    .top-menu .locate-form-wrapper .locate-form .inputPostal {
        width: 15rem;
    }
}

@media screen and (max-width: 1199px) {
    .top-menu .locate-form-wrapper .locate-form .inputPostal {
        width: 12rem;
    }
}

.top-menu .locate-form-wrapper .locate-form .locate-btn {
    background: none;
    padding: 0;
    font-weight: 500;
    border: none;
    font-family: 'Inter Medium';
    color: #000000;
}

.top-menu .locate-form-wrapper .locate-form .locate-btn:focus,
.top-menu .locate-form-wrapper .locate-form .locate-btn:hover {
    text-decoration: underline;
}

.location-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 6.2rem;
}

@media screen and (max-width: 1200px) {
    .location-section {
        left: 7.2rem;
    }
}

@media screen and (min-width: 1440px) {
    .location-section {
        left: 5.2rem;
    }
}

@media screen and (min-width: 1820px) {
    .location-section {
        left: 2rem;
    }
}

.location-section strong {
    font-size: 1.125rem;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
}

@media screen and (max-width: 1100px) {
    .location-section strong {
        font-size: 1rem;
    }
}

.location-section a {
    font-size: 0.75rem;
    color: #B00006;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.location-section a img {
    padding-right: 0.3125rem;
    height: 1.1875rem;
}

.location-section a:hover,
.location-section a:focus {
    color: #B00006;
}

.location-section small {
    font-size: 0.75rem;
    color: #4e4e4e;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
}

@media screen and (max-width: 991px) {
    .location-section {
        display: none;
    }
}

.header-classic {
    color: #000000;
    background-color: #ffffff;
    min-height: 9.75rem;
}

@media screen and (max-width: 991px) {
    .header-classic {
        -webkit-box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.1);
        min-height: 100%;
    }
}

.header-classic .navbar {
    padding: 0;
}

.header-classic .logo-sec h1 {
    margin-bottom: 0;
}

.header-classic .logo-sec h1 .navbar-brand {
    margin: 0;
    padding: 0;
}

@media screen and (min-width: 992px) {
    .header-classic .logo-sec h1 .navbar-brand {
        margin-left: 0.9375rem;
    }
}

@media screen and (max-width: 991px) {
    .header-classic .logo-sec h1 .navbar-brand {
        padding: 0.3125rem 0;
        margin: 0 1.875rem;
    }
}

.header-classic .logo-sec h1 .navbar-brand img {
    max-width: 14.9375rem;
    height: auto;
}

@media screen and (max-width: 1199px) {
    .header-classic .logo-sec h1 .navbar-brand img {
        max-width: 10.9375rem;
    }
}

@media screen and (max-width: 991px) {
    .header-classic .logo-sec h1 .navbar-brand img {
        width: 100%;
        margin-left: 0;
        max-width: 9.0625rem;
    }
}

.header-classic .find-row {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.header-classic .find-row .mobile-icon {
    display: none;
}

@media screen and (max-width: 991px) {
    .header-classic .find-row {
        display: none;
    }
}

.header-classic .find-row ul {
    margin-bottom: 0;
    width: 100%;
}

.header-classic .find-row a {
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    display: inline-block;
    border: 1px solid transparent;
    color: #000000;
}

@media screen and (min-width: 991px) {
    .header-classic .find-row a {
        margin-left: 1rem;
    }

    .header-classic .find-row a:first-child {
        margin-left: 0;
    }
}

@media screen and (max-width: 1010px) {
    .header-classic .find-row a {
        white-space: nowrap;
    }
}

@media screen and (max-width: 1230px) {
    .header-classic .find-row a {
        margin-left: 0.4rem;
        z-index: 9;
    }
}

.header-classic .find-row a:hover,
.header-classic .find-row a:focus {
    color: #000000;
}

.header-classic .find-row li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.header-classic .find-row li .call-icon {
    font-family: 'Inter Bold';
    white-space: nowrap;
}

.header-classic .find-row li .call-icon::before {
    display: none;
}

.header-classic .find-row li .call-icon img {
    width: auto;
    height: 1.1875rem;
    padding-right: 0.625rem;
    vertical-align: text-top;
}

@media screen and (max-width: 991px) {
    .header-classic .find-row li .call-icon img {
        padding-right: 0;
    }
}

.header-classic .find-row li .call-icon:hover,
.header-classic .find-row li .call-icon:focus {
    color: #000000;
}

.header-classic .find-row li span {
    display: block;
    letter-spacing: 0;
    font-weight: 400;
    padding-top: 0.125rem;
    text-align: right;
}

.header-classic .find-row li span:not(:last-child) {
    margin-right: 0.5rem;
}

.header-classic .find-row li:nth-of-type(2) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.header-classic .find-row .input-group {
    border: 0.0625rem solid #979797;
    margin-bottom: 0;
    height: 100%;
}

.header-classic .find-row .input-group input[type="search"] {
    border: none;
    padding: 0 0 0 0.75rem;
    height: 1.8125rem;
    font-size: 0.6875rem;
    color: #666666;
    line-height: 1.5rem;
    border: 2px solid transparent;
}

.header-classic .find-row .input-group input[type="search"]::-webkit-input-placeholder {
    font-size: 0.6875rem;
    color: #666666;
    line-height: 1.5rem;
}

.header-classic .find-row .input-group input[type="search"]:-ms-input-placeholder {
    font-size: 0.6875rem;
    color: #666666;
    line-height: 1.5rem;
}

.header-classic .find-row .input-group input[type="search"]::-ms-input-placeholder {
    font-size: 0.6875rem;
    color: #666666;
    line-height: 1.5rem;
}

.header-classic .find-row .input-group input[type="search"]::placeholder {
    font-size: 0.6875rem;
    color: #666666;
    line-height: 1.5rem;
}

.header-classic .find-row .input-group input[type="search"]:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 2px solid #142651;
    outline: none;
    outline-offset: 0;
}

.header-classic .find-row .input-group input[type="search"]::-webkit-search-decoration,
.header-classic .find-row .input-group input[type="search"]::-webkit-search-cancel-button,
.header-classic .find-row .input-group input[type="search"]::-webkit-search-results-button,
.header-classic .find-row .input-group input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.header-classic .find-row .input-group input[type="submit"] {
    height: 1.8125rem;
    border: none;
    border-left: 0;
    background: no-repeat center/0.75rem url(/us/en-us/mr-handyman/_assets/images/searchIcon.svg);
    background-color: #ffffff;
    padding: 0 0.625rem 0 1.5625rem;
}

.header-classic .find-row .input-group input[type="submit"]:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #142651;
    outline: none;
    border-radius: 0;
    outline-offset: 0;
}

.header-classic .find-row .search-dropdown {
    margin-left: 1rem;
    vertical-align: top;
}

@media screen and (max-width: 1230px) {
    .header-classic .find-row .search-dropdown {
        margin-left: 0.4rem;
    }
}

.header-classic .find-row .search-dropdown .dropdown-menu {
    padding: 0.625rem;
    -webkit-box-shadow: 0 1px 21px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 21px 2px rgba(0, 0, 0, 0.2);
}

.header-classic .find-row .search-dropdown .dropdown-menu.show {
    right: 0;
    left: auto;
    width: 100%;
    min-width: 14.0625rem;
}

.header-classic .find-row .search-dropdown .dropdown-menu .form-control:focus {
    outline: 1px solid #142651;
}

.header-classic .find-row .search-dropdown .dropdown-toggle {
    border: none;
    background-color: transparent;
    color: #000000;
}

.header-classic .find-row .search-dropdown .dropdown-toggle:focus,
.header-classic .find-row .search-dropdown .dropdown-toggle:hover {
    text-decoration: underline;
    color: #000000;
}

.header-classic .find-row .search-dropdown .dropdown-toggle::after {
    display: none;
}

.header-classic .find-row .search-dropdown .dropdown-toggle img {
    vertical-align: top;
    padding-right: 0.375rem;
}

.header-classic .main-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 82%;
    flex: 0 1 82%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
}

@media screen and (max-width: 991px) {
    .header-classic .main-nav {
        -webkit-box-flex: 0;
        -ms-flex: 0;
        flex: 0;
        height: 5rem;
        padding-top: 0;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.header-classic .main-nav a {
    border: 1px solid transparent;
}

.header-classic .navbar-nav {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav {
        padding: 0;
    }

    .header-classic .navbar-nav li:last-child {
        border-bottom: none;
    }
}

@media screen and (max-width: 1300px) {
    .header-classic .navbar-nav {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
}

.header-classic .navbar-nav .dropdown-menu {
    background: rgba(255, 255, 255, 0.98);
    border-top: 1px solid #979797;
    border-right: none;
    border-radius: 0;
    margin: 0;
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .dropdown-menu {
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
        left: 100%;
        position: fixed;
        width: 100%;
        display: block;
        top: 0;
        height: 100%;
        opacity: 0;
        border: none;
        visibility: hidden;
    }
}

.header-classic .navbar-nav .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    padding: 2.4rem 0 3.65rem;
    margin-top: 0;
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .dropdown-menu.show {
        visibility: visible;
        border: none;
        left: 0;
        z-index: 99;
        background-color: #ffffff;
        opacity: 1;
    }
}

.header-classic .navbar-nav .dropmenu {
    max-width: 59.375rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .dropmenu {
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 0.25rem 0 0.4375rem;
        max-width: 100%;
    }
}

.header-classic .navbar-nav .menu-col {
    width: 20rem;
    padding-right: 2rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
}

.header-classic .navbar-nav .menu-col:last-child {
    padding-right: 0;
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .menu-col {
        width: 100%;
        padding-right: 0;
    }
}

.header-classic .navbar-nav .menu-col .back-arrow-link {
    display: none;
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .menu-col .back-arrow-link {
        display: block;
        width: 100%;
        text-align: left;
        padding: 0.625rem 0 0.625rem 1.2rem;
        border: none;
        background: transparent;
        font-size: 0.875rem;
        font-family: 'Inter Medium';
    }

    .header-classic .navbar-nav .menu-col .back-arrow-link::before {
        -webkit-transform: none;
        transform: none;
    }

    .header-classic .navbar-nav .menu-col .back-arrow-link:focus {
        outline-offset: -2px;
    }
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .col-section {
        margin: 0 0.9375rem;
    }

    .header-classic .navbar-nav .col-section .dropdown-menu-inner {
        opacity: 0;
        visibility: hidden;
        left: 100%;
        position: fixed;
        width: 100%;
        top: 0;
        min-height: 100%;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
        z-index: 9;
    }

    .header-classic .navbar-nav .col-section.active {
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
    }

    .header-classic .navbar-nav .col-section.active .dropdown-menu-inner {
        display: block;
        left: 0;
        background-color: #ffffff;
        opacity: 1;
        visibility: visible;
    }

    .header-classic .navbar-nav .col-section.active .dropdown-menu-inner ul {
        padding: 0 1rem;
    }

    .header-classic .navbar-nav .col-section.active .dropdown-menu-inner p {
        padding: 0.75rem 0;
        margin: 0;
    }
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .col-section.col-info {
        margin-top: 1.25rem;
    }
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .col-section.col-info h2 {
        font-weight: 500;
        padding: 0;
    }
}

.header-classic .navbar-nav .col-section.col-info h2,
.header-classic .navbar-nav .col-section.col-info p {
    font-size: 0.75rem;
}

@media screen and (max-width: 991px) {

    .header-classic .navbar-nav .col-section.col-info h2, .header-classic .navbar-nav .col-section.col-info span,
    .header-classic .navbar-nav .col-section.col-info p {
        border-bottom: none;
        margin-bottom: 0;
    }
}

.header-classic .navbar-nav .col-section h2, .header-classic .navbar-nav .col-section span,
.header-classic .navbar-nav .col-section .dropdown-link,
.header-classic .navbar-nav .col-section .mob-link {
    display: inline-block;
    margin-bottom: 0.5rem;
    letter-spacing: 0.106875rem;
    text-align: left;
    line-height: 1rem;
    font-weight: 500;
    color: #000000;
    font-size: 1rem;
}

@media screen and (max-width: 991px) {

    .header-classic .navbar-nav .col-section h2, .header-classic .navbar-nav .col-section span,
    .header-classic .navbar-nav .col-section .dropdown-link,
    .header-classic .navbar-nav .col-section .mob-link {
        display: block;
        margin-bottom: 0;
        line-height: 2.2rem;
        padding-left: 1.4375rem;
        border-bottom: 1px solid #e3e3e3;
        padding: 0.375rem 0;
        text-transform: capitalize;
        letter-spacing: 0;
        font-weight: 400;
        font-size: 0.875rem;
    }
}

.header-classic .navbar-nav .col-section h2 a,
.header-classic .navbar-nav .col-section .dropdown-link a,
.header-classic .navbar-nav .col-section .mob-link a {
    color: #000000;
    display: inline-block;
}

.header-classic .navbar-nav .col-section h2 p,
.header-classic .navbar-nav .col-section .dropdown-link p,
.header-classic .navbar-nav .col-section .mob-link p {
    padding-top: 0.25rem;
}

.header-classic .navbar-nav .col-section h2 h2,
.header-classic .navbar-nav .col-section .dropdown-link h2,
.header-classic .navbar-nav .col-section .mob-link h2 {
    line-height: 1.2rem;
}

.header-classic .navbar-nav .col-section .mob-link {
    display: none;
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .col-section .mob-link {
        display: block;
        border: none;
        border-bottom: 1px solid #e3e3e3;
        width: 100%;
        background: transparent;
        color: #000000;
        font-size: 0.875rem;
    }

    .header-classic .navbar-nav .col-section .mob-link:hover,
    .header-classic .navbar-nav .col-section .mob-link:focus {
        text-decoration: underline;
    }
}

.header-classic .navbar-nav .col-section .desktop-link {
    display: block;
    color: #000000;
    font-weight: 500;
    font-family: 'Inter Bold';
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.header-classic .navbar-nav .col-section .desktop-link:hover,
.header-classic .navbar-nav .col-section .desktop-link:focus {
    color: #B00006;
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .col-section .desktop-link {
        display: none;
    }
}

.header-classic .navbar-nav .col-section h2.desktop-link, .header-classic .navbar-nav .col-section span.desktop-link {
    color: #0D0D0D;
    font-size: 1rem;
    margin-bottom: 0.8rem;
    font-family: 'Inter Medium';
    font-weight: 500;
}

.header-classic .navbar-nav .col-section h2.desktop-link, .header-classic .navbar-nav .col-section span.desktop-link:hover,
.header-classic .navbar-nav .col-section h2.desktop-link, .header-classic .navbar-nav .col-section span.desktop-link:focus {
    color: #0D0D0D;
}

.header-classic .navbar-nav .col-section .dropdown-link {
    color: #000000;
}

@media screen and (min-width: 991px) {
    .header-classic .navbar-nav .col-section .dropdown-link {
        font-family: 'Inter Bold';
        font-size: 1.125rem;
        letter-spacing: 0;
        margin-bottom: 1.65rem;
    }
}

.header-classic .navbar-nav .col-section .dropdown-link:hover,
.header-classic .navbar-nav .col-section .dropdown-link:focus {
    font-weight: 500;
}

@media screen and (max-width: 991px) {

    .header-classic .navbar-nav .col-section .dropdown-link:hover,
    .header-classic .navbar-nav .col-section .dropdown-link:focus {
        font-weight: 400;
        color: #000000;
    }
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .col-section .dropdown-link {
        display: block;
    }
}

.header-classic .navbar-nav .col-section ul {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .col-section ul {
        padding-left: 0;
        padding-bottom: 0.3125rem;
        margin: 0;
    }
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .col-section ul li {
        line-height: 1.25rem;
        border-bottom: 1px solid #e3e3e3;
    }
}

.header-classic .navbar-nav .col-section .dropdown-item {
    display: inline-block;
    width: auto;
    padding: 0;
    font-weight: 400;
    background-color: transparent;
    line-height: 1.5625rem;
    font-size: 1rem;
    color: #000000;
    margin-bottom: 0.1875rem;
    white-space: normal;
}

.header-classic .navbar-nav .col-section .dropdown-item:hover .dropdown-toggle::after {
    color: #B00006;
}

.header-classic .navbar-nav .col-section .dropdown-item:hover,
.header-classic .navbar-nav .col-section .dropdown-item:focus {
    color: #B00006;
    opacity: 1;
    text-decoration: underline;
}

@media screen and (min-width: 991px) {

    .header-classic .navbar-nav .col-section .dropdown-item:hover,
    .header-classic .navbar-nav .col-section .dropdown-item:focus {
        font-weight: 500;
    }
}

@media screen and (max-width: 991px) {

    .header-classic .navbar-nav .col-section .dropdown-item:hover,
    .header-classic .navbar-nav .col-section .dropdown-item:focus {
        font-weight: 400;
        color: #000000;
    }
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .col-section .dropdown-item {
        color: #000000;
        letter-spacing: 0;
        line-height: 1.5rem;
        font-weight: 400;
        padding: 0.625rem 0;
        display: block;
        margin-bottom: 0;
        font-size: 0.875rem;
    }
}

.header-classic .navbar-nav .col-section .mobile-link {
    display: none;
}

@media (max-width: 991px) {
    .header-classic .navbar-nav .col-section .mobile-link {
        display: block;
    }
}

.header-classic .navbar-nav .btn-row {
    margin-top: 0.7rem;
    margin-bottom: 1.5rem;
}

@media screen and (max-width: 991px) {
    .header-classic .navbar-nav .btn-row {
        padding-left: 0;
    }
}

.header-classic .navbar-nav h2.overview-link, .header-classic .navbar-nav span.overview-link,
.header-classic .navbar-nav .overview-link {
    display: none;
}

@media screen and (max-width: 991px) {

    .header-classic .navbar-nav h2.overview-link, .header-classic .navbar-nav span.overview-link,
    .header-classic .navbar-nav .overview-link {
        display: block;
        background: #F3F3F3;
        font-size: 0.875rem;
        color: #0D0D0D;
        letter-spacing: 1.5px;
        font-weight: 500;
        padding: 0.625rem 1rem;
        line-height: 1.5rem;
        text-align: left;
        text-transform: uppercase;
    }
}

.header-classic .navbar-nav a.overview-link:focus {
    outline-offset: -2px;
}

.header-classic .nav-link {
    letter-spacing: 0;
    text-align: center;
    line-height: 1.5rem;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #000000;
    font-size: 1rem;
    font-family: 'Inter Medium';
    font-weight: 500;
    border: 1px solid transparent;
}

@media screen and (max-width: 1300px) {
    .header-classic .nav-link {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 1050px) {
    .header-classic .nav-link {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 991px) {
    .header-classic .nav-link {
        font-weight: 400;
        font-family: 'Inter Regular';
        font-size: 0.875rem;
        display: block;
        text-align: left;
        position: relative;
        margin-left: 0;
        padding: 0.625rem 0;
    }
}

.header-classic .nav-link:hover,
.header-classic .nav-link:focus {
    text-decoration: underline;
    color: #B00006;
}

@media screen and (min-width: 991px) {

    .header-classic .nav-link:hover,
    .header-classic .nav-link:focus {
        font-weight: 500;
        font-family: 'Inter Bold';
    }
}

@media screen and (max-width: 991px) {

    .header-classic .nav-link:hover,
    .header-classic .nav-link:focus {
        font-weight: 400;
        color: #000000;
    }
}

.header-classic .nav-link::after {
    content: attr(data-title);
    content: attr(data-title)/"";
    height: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    font-weight: 500;
    border: none;
    margin: 0;
    font-family: 'Inter Bold';
}

@media speech {
    .header-classic .nav-link::after {
        display: none;
    }
}

.header-classic .mobile-icon {
    display: none;
}

@media screen and (max-width: 991px) {
    .header-classic .close-icon {
        width: 4.1667rem;
    }

    .header-classic .mobile-icon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 4.1667rem;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media screen and (max-width: 991px) and (max-width: 360px) {
    .header-classic .mobile-icon {
        width: 4rem;
    }
}

@media screen and (max-width: 991px) {
    .header-classic .mobile-icon a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .header-classic .mobile-icon a img {
        margin: 0 auto;
    }

    .header-classic .mobile-icon a:hover,
    .header-classic .mobile-icon a:focus {
        font-weight: 400;
    }

    .header-classic .mobile-icon .search-info {
        height: 1.25rem;
        width: 1.25rem;
    }

    .header-classic .btn-close {
        margin-left: 0.4375rem;
    }

    .header-classic .btn-close img {
        width: auto;
        height: auto;
    }

    .header-classic .navbar-toggler {
        padding: 0;
    }

    .header-classic .navbar-toggler .navbar-toggler-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: top;
    }

    .header-classic .navbar-collapse {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow: auto;
        visibility: hidden;
        background-color: #ffffff;
        -webkit-transition: visibility 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        transition: visibility 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        transition: visibility 0.5s ease-in-out, transform 0.5s ease-in-out;
        transition: visibility 0.5s ease-in-out, transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        z-index: 9999;
        height: 100vh;
        padding-top: 0.3125rem;
    }

    .header-classic .navbar-collapse.show {
        visibility: visible;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        z-index: 99;
        right: 0;
        height: 100vh;
        overflow-x: hidden;
    }

    .header-classic .navbar-collapse.show .dropdown .dropdown-menu {
        background: #ffffff;
        -webkit-box-shadow: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
    }

    .header-classic .navbar-collapse.show .dropdown.show {
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
    }

    .header-classic .navbar-collapse.show::-webkit-scrollbar {
        width: 0 !important;
    }
}

.header-classic .arrow-right-link::before {
    display: inline-block;
}

@media screen and (min-width: 992px) {
    .header-classic .arrow-right-link::before {
        display: none;
    }
}

.menu-large {
    position: static !important;
}

@media screen and (max-width: 991px) {
    .menu-large {
        line-height: 2.8125rem;
        border-bottom: 1px solid #e3e3e3;
    }
}

.menu-large .dropdown-toggle::after {
    display: none;
}

.megamenu {
    padding: 1.5rem 0;
    width: 100%;
}

.megamenu>div>li>ul {
    padding: 0;
    margin: 0;
}

@media (max-width: 768px) {
    .megamenu {
        margin-left: 0;
        margin-right: 0;
    }

    .megamenu>li {
        margin-bottom: 1.875rem;
    }

    .megamenu>li:last-child {
        margin-bottom: 0;
    }

    .megamenu.dropdown-header {
        padding: 0.1875rem 0.9375rem;
    }

    .navbar-nav .open .dropdown-menu .dropdown-header {
        color: #ffffff;
    }
}

@media (min-width: 992px) {
    .header-classic .main-nav .navbar-nav .dropdown>.dropdown-toggle:active {
        /*Without this, clicking will make it sticky*/
        pointer-events: none;
    }

    .header-classic .main-nav .navbar-nav .dropdown:hover>.dropdown-menu {
        display: block;
    }

    .header-classic .main-nav .navbar-nav .dropdown:focus-within>.dropdown-menu {
        display: block;
    }

    .header-classic .main-nav .navbar-nav .dropdown:hover>a {
        font-weight: 500;
        text-decoration: underline;
        color: #B00006;
        font-family: 'Inter Bold';
    }

    .menu-large {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 4.5625rem;
    }
}

.mobile-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.mobile-icon .btn-phone {
    min-width: 2.1875rem;
    text-align: center;
    vertical-align: middle;
    line-height: 2.0625rem;
    min-height: 2.1875rem;
}

.mobile-icon .search-button {
    position: relative;
    border: none;
    background-color: transparent;
}

.back-arrow-link {
    font-size: 0.875rem;
    color: #142651;
    letter-spacing: 0;
    line-height: 1.5rem;
    font-weight: 400;
    color: #000000;
}

.back-arrow-link::before {
    content: "";
    display: inline-block;
    background: url(/us/en-us/mr-handyman/_assets/images/left-chevron-black.svg) top no-repeat;
    background-size: inherit;
    width: 0.5625rem;
    height: 0.5625rem;
    padding: 0.3125rem;
    margin-right: 0.4375rem;
    margin-left: -0.25rem;
}

.back-arrow-link:hover,
.back-arrow-link:focus {
    color: #000000;
    text-decoration: underline;
}

.arrow-right-link {
    position: relative;
}

.arrow-right-link::before {
    content: '';
    background: no-repeat center url(/us/en-us/mr-handyman/_assets/images/right-chevron-black.svg);
    height: 0.625rem;
    width: 0.5rem;
    position: absolute;
    display: inline-block;
    margin-right: 0.625rem;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.arrow-right-link:hover,
.arrow-right-link:focus {
    text-decoration: underline;
}

.header-classic .mob-header-search {
    position: absolute;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
    transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
    width: 0%;
    visibility: hidden;
}

.header-classic .mob-header-search .input-group {
    border: none;
    margin: 0.75rem 0.75rem 0;
}

.header-classic .mob-header-search.open-search {
    visibility: visible;
    opacity: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 9;
    width: 100%;
    min-width: 2.5rem;
    top: 0;
    right: 0;
    height: 4.875rem;
    background: #ffffff;
}

.header-classic .mob-header-search.open-search input[type="search"] {
    height: 100%;
    padding-left: 2.8125rem;
}

.header-classic .mob-header-search.open-search input[type="search"]:focus {
    outline: 2px solid #142651;
    border: none;
    outline-offset: -3px;
}

.header-classic .mob-header-search.open-search .search-icon {
    z-index: 9;
}

.header-classic .mob-header-search.open-search .search-icon::before {
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    background: no-repeat center/1rem url(/us/en-us/mr-handyman/_assets/images/searchIcon.svg);
    content: '';
    top: 50%;
    left: 0.9375rem;
    -webkit-transform: translateY(-48%);
    transform: translateY(-48%);
    pointer-events: none;
}

.header-classic .mob-header-search.open-search input[type="search"] {
    font-size: 1rem;
}

.header-classic .mob-header-search.open-search input[type="search"]::-webkit-input-placeholder {
    font-size: 1rem;
}

.header-classic .mob-header-search.open-search input[type="search"]:-ms-input-placeholder {
    font-size: 1rem;
}

.header-classic .mob-header-search.open-search input[type="search"]::-ms-input-placeholder {
    font-size: 1rem;
}

.header-classic .mob-header-search.open-search input[type="search"]::placeholder {
    font-size: 1rem;
}

.header-classic .mob-header-search.open-search .close-header-search {
    border: none;
    background: #ffffff;
    width: 2rem;
    font-size: 2rem;
    margin-left: 1px;
}

.header-classic .mob-header-search.open-search .close-header-search:focus {
    outline: 2px solid #B00006;
    border: none;
    outline-offset: -3px;
}

.mobile-logo-sec {
    display: none;
}

@media screen and (max-width: 991px) {
    .mobile-logo-sec {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.625rem 0 1.875rem;
    }
}

.mobile-logo-sec .sm-logo {
    width: 8.125rem;
}

.mobile-logo-sec .sm-logo img {
    width: 100%;
}

.mobile-logo-sec .close-btn {
    border: none;
    background-color: transparent;
}

.find-sec {
    display: none;
}

@media only screen and (max-width: 991px) {
    .find-sec {
        display: block;
        margin-bottom: 2rem;
    }
}

.find-sec .location-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    left: 0;
    margin-top: 0.65rem;
}

.find-sec label {
    font-size: 1rem;
    color: #B00006;
    letter-spacing: 0.96px;
    line-height: 1.5rem;
    width: 100%;
    margin: 1.25rem 0;
    font-weight: 500;
    font-family: 'Inter Bold';
}

.find-sec input[type="text"] {
    background: #ffffff;
    border: 1px solid #002f65;
    height: 3rem;
    border-radius: 0;
    color: #666666;
    font-size: 0.875rem;
    width: 100%;
    margin-bottom: 1.25rem;
    padding-left: 0.625rem;
    max-width: 18.75rem;
    display: block;
}

.offcanvas-header .mobile-icon {
    z-index: 999;
}

.offcanvas-header .mobile-icon .mob-header-search {
    margin-top: 1.25rem;
    height: 3.125rem;
}

@media (max-width: 991px) {
    .nav-links {
        position: relative;
    }
}

.nav-links .hamburger-menu {
    width: 2.5rem;
    text-align: center;
    font-size: 0;
    cursor: pointer;
    display: none;
    position: absolute;
    right: 0.9375rem;
    top: -0.625rem;
}

@media (max-width: 991px) {
    .nav-links .hamburger-menu {
        display: block;
    }
}

.nav-links .hamburger-menu span {
    width: 1.375rem;
    height: 0.125rem;
    background: #DA291C;
    display: inline-block;
    margin: 0.125rem 0;
}

@media (max-width: 1670px) {
    .nav-links .nav-section {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .nav-links .nav-section {
        margin: 0 auto;
    }
}

@media (min-width: 992px) {
    .nav-links .nav-section .nav-link {
        padding-right: 1px;
        padding-left: 1px;
    }
}

@media (min-width: 1150px) {
    .nav-links .nav-section .nav-link {
        padding-right: 0.4rem;
        padding-left: 0.4rem;
    }
}

@media (min-width: 1650px) {
    .nav-links .nav-section .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.nav-container {
    width: 100%;
    max-width: 100%;
}

@media (min-width: 1441px) {
    .nav-container {
        padding-left: 4.375rem;
        padding-right: 4.375rem;
    }
}

.col-sec-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22%;
    flex: 0 0 22%;
    max-width: 22%;
    padding: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

@media screen and (max-width: 1199px) {
    .col-sec-left {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 18%;
        flex: 0 0 18%;
        max-width: 18%;
    }
}

@media screen and (max-width: 991px) {
    .col-sec-left {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 80%;
        flex: 0 0 80%;
        max-width: 80%;
    }
}

.col-sec-middle {
    padding: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
}

@media screen and (min-width: 1440px) {
    .col-sec-middle {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
    }
}

@media screen and (min-width: 1820px) {
    .col-sec-middle {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 70%;
        flex: 0 0 70%;
        max-width: 70%;
    }
}

.col-sec-right {
    padding: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
}

@media screen and (min-width: 1440px) {
    .col-sec-right {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
    }
}

@media screen and (min-width: 1820px) {
    .col-sec-right {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 30%;
        flex: 0 0 30%;
        max-width: 30%;
    }
}

.col-btn {
    padding-left: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

@media screen and (max-width: 1199px) {
    .col-btn {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 23%;
        flex: 0 0 23%;
        max-width: 23%;
    }
}

@media screen and (max-width: 991px) {
    .col-btn {
        display: none;
    }
}

.col-main-nav {
    padding: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

@media screen and (max-width: 1199px) {
    .col-main-nav {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 77%;
        flex: 0 0 77%;
        max-width: 77%;
    }
}

@media screen and (max-width: 991px) {
    .col-main-nav {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.header-left-sec {
    padding-right: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 78%;
    flex: 0 0 78%;
    max-width: 78%;
}

@media screen and (min-width: 991px) {
    .header-left-sec {
        padding: 0.5rem 0 0 0;
    }
}

@media screen and (min-width: 991px) {
    .header-left-sec .row {
        margin-top: 0.125rem;
    }
}

@media screen and (max-width: 1199px) {
    .header-left-sec {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 82%;
        flex: 0 0 82%;
        max-width: 82%;
    }
}

@media screen and (max-width: 991px) {
    .header-left-sec {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.header-left-sec .desktop-btn {
    display: none;
}

@media (min-width: 992px) {
    .header-left-sec .desktop-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
}

@media (max-width: 1300px) {
    .header-left-sec .desktop-btn .primary-btn {
        padding: 0 0.625rem;
        font-size: 1rem;
        min-width: auto;
    }
}

@media (max-width: 1150px) {
    .header-left-sec .desktop-btn .primary-btn {
        font-size: 0.875rem;
    }
}

.mobile-link {
    display: none;
}

@media (max-width: 991px) {
    .mobile-link {
        display: block;
    }
}

@media screen and (min-width: 991px) {
    .mob-link {
        display: none;
    }
}

.footer-btn-yellow {
    color: #142651;
    background: #FFC733;
    border-color: transparent;
    font-size: 1rem;
    text-align: center;
    vertical-align: top;
    font-family: 'Inter Regular';
    font-weight: 700;
    min-width: 8.125rem;
    min-height: 2.6875rem;
    line-height: 2.6875rem;
    display: inline-block;
    border: 0;
    cursor: pointer;
    padding: 0.125rem 1.375rem 0 1.375rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    border-radius: 0.5rem;
}

footer .footer {
    padding: 3.75rem 0 0 0;
}

footer .footer a:focus {
    outline-color: #ffffff;
}

footer .footer .footer-top-bg {
    background: url(/us/en-us/mr-handyman/_assets/images/footer_mark_icon_bg.svg) no-repeat bottom right;
}

@media screen and (max-width: 767px) {
    footer .footer .footer-top-bg {
        background-size: 20%;
    }
}

footer .footer .links-section {
    margin-bottom: 3.125rem;
}

@media screen and (max-width: 767px) {
    footer .footer .links-section {
        margin-bottom: 2rem;
    }
}

footer .footer .footer-logo-wrap {
    text-align: center;
    max-width: 12.9375rem;
}

footer .footer .footer-logo-wrap .address {
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    line-height: 1.5rem;
}

footer .footer .footer-logo-wrap .address span {
    display: block;
}

@media screen and (max-width: 767px) {
    footer .footer .footer-logo-wrap .address {
        line-height: 1.1rem;
    }
}

footer .footer .footer-brand-logo {
    display: inline-block;
    min-height: 3.3125rem;
}

@media screen and (max-width: 767px) {
    footer .footer .footer-brand-logo {
        min-height: 2.1875rem;
    }
}

footer .footer .footer-brand-logo img {
    height: auto;
    max-width: 100%;
    width: 9.6875rem;
}

@media screen and (max-width: 767px) {
    footer .footer .footer-brand-logo img {
        width: 8.75rem;
    }
}

footer .footer .location-list-details {
    margin-bottom: 1.25rem;
    margin-top: 1.375rem;
    text-align: center;
    max-width: 12.9375rem;
}

@media screen and (max-width: 767px) {
    footer .footer .location-list-details {
        margin-top: 1.25rem;
    }
}

footer .footer .location-list-details strong {
    letter-spacing: 0;
    font-weight: 500;
}

footer .footer .location-list-details strong:hover {
    text-decoration: none;
}

@media screen and (max-width: 991px) {
    footer .footer .location-list-details strong {
        font-size: 1rem;
    }
}

@media screen and (max-width: 362px) {
    footer .footer .location-list-details strong {
        font-size: 0.8125rem;
    }
}

footer .footer .location-list-details .call-us-text {
    letter-spacing: 0;
    font-weight: 500;
    margin-top: 0.3125rem;
    display: block;
    margin-bottom: 0;
}

footer .footer .list-unstyled li {
    margin-bottom: 0px;
}

footer .footer .list-unstyled li a {
    letter-spacing: 0;
    line-height: 2rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-weight: 500;
}

footer .footer .list-unstyled li a:hover,
footer .footer .list-unstyled li a:focus {
    text-decoration: underline;
    color: #ffffff;
}

@media screen and (min-width: 767px) {
    footer .footer .footer-search {
        padding-left: 1.875rem;
    }
}

footer .footer .footer-search-box {
    min-height: 3.75rem;
    position: relative;
}

@media screen and (max-width: 767px) {
    footer .footer .footer-search-box {
        margin-top: 1.875rem;
        min-height: 4.0625rem;
    }
}

footer .footer .footer-search-box .input-group {
    border: 1px solid #979797;
    margin-bottom: 0;
    height: 2.8125rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 5px;
    max-width: 31.25rem;
}

@media screen and (max-width: 767px) {
    footer .footer .footer-search-box .input-group {
        max-width: 100%;
    }
}

footer .footer .footer-search-box .input-group .form-control {
    border: none;
    padding: 0 0 0 0.75rem;
    height: 2.8125rem;
    letter-spacing: 0;
    line-height: 2.8125rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 0.875rem;
    color: #000000;
}

footer .footer .footer-search-box .input-group .form-control:focus {
    outline-offset: 2px;
    outline-color: #ffffff;
}

footer .footer .footer-search-box .input-group .input-yext {
    height: 2.8125rem;
    border: none;
    border-left: 0;
    background: no-repeat center/2rem url(/us/en-us/mr-handyman/_assets/images/yext_icon.png);
    background-color: #ffffff;
    padding: 0 0.625rem 0 1.5625rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

footer .footer .footer-search-box .input-group .input-yext:focus {
    outline-offset: 2px;
    outline-color: #ffffff;
}

footer .footer .list-inline-item {
    margin-bottom: 0.9375rem;
    margin-right: 1.5625rem;
}

@media screen and (max-width: 767px) {
    footer .footer .list-inline-item {
        margin-right: 0.625rem;
    }
}

footer .footer .list-inline-item img {
    width: 3.75rem;
    height: auto;
}

footer .footer .footer-social-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

footer .footer .footer-social-icons .social-icons-list {
    position: relative;
    display: inline-block;
}

footer .footer .footer-social-icons .social-icons-list::before {
    position: absolute;
    content: '';
    background: #fff;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -0.625rem;
}

footer .footer .footer-social-icons .social-icons-list li {
    float: left;
}

footer .footer .footer-social-icons .social-icons-list li:nth-child(3n+1) {
    content: '';
    display: table;
    clear: both;
}

footer .footer .footer-social-icons .social-icons-list li:nth-child(3n) {
    margin-right: 0;
}

footer .footer .footer-social-icons .social-icons-list li img {
    color: #cccccc;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    filter: brightness(0) invert(1);
    -ms-filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
    width: 1.688rem;
    height: auto;
}

@media screen and (max-width: 767px) {
    footer .footer .footer-social-icons .social-icons-list li img {
        width: 1.375rem;
        height: auto;
    }
}

footer .footer .copyright-foot-note {
    width: 70%;
    padding-bottom: 1.5625rem;
    letter-spacing: 0;
    line-height: 1rem;
    font-family: 'Inter Regular';
    margin-top: 1.875rem;
    font-size: 0.6875rem;
}

@media screen and (max-width: 767px) {
    footer .footer .copyright-foot-note {
        margin-top: 1rem;
    }
}

footer .footer .copyright-foot-note p {
    color: #cccccc;
    font-size: 0.6875rem;
}

footer .footer .copyright-foot-note a {
    text-decoration: underline;
    color: #cccccc;
    font-size: 0.6875rem;
}

footer .footer-secondary {
    padding: 5rem 0 3.125rem 0;
    font-family: 'Inter Regular';
}

@media screen and (max-width: 767px) {
    footer .footer-secondary {
        padding: 3.125rem 0;
    }
}

footer .footer-secondary .footer-neighbourly-logo img {
    width: 15.6875rem;
    height: auto;
}

@media screen and (max-width: 767px) {
    footer .footer-secondary .footer-neighbourly-logo img {
        width: 9.375rem;
    }
}

footer .footer-secondary .logo-heading {
    display: inline-block;
    padding-left: 3.4375rem;
    letter-spacing: 0;
    line-height: 1.125rem;
    font-family: 'Inter Regular';
    font-weight: 700;
}

@media screen and (max-width: 767px) {
    footer .footer-secondary .logo-heading {
        padding-left: 0;
        line-height: normal;
    }
}

footer .footer-secondary .footer-info-heading {
    letter-spacing: 0;
    line-height: 1.125rem;
    font-family: 'Inter Regular';
    font-weight: 400;
    display: block;
}

footer .footer-secondary .footer-info-heading strong {
    font-weight: 700;
}

@media screen and (max-width: 767px) {
    footer .footer-secondary .footer-info-heading {
        font-size: 1rem;
        line-height: 1.5625rem;
    }
}

footer .footer-secondary .secondary-link-cont {
    margin-top: 2.8125rem;
}

@media screen and (max-width: 767px) {
    footer .footer-secondary .secondary-link-cont {
        margin: 1.5625rem 0;
    }

    footer .footer-secondary .secondary-link-cont .col-md-3 {
        padding-left: 0;
    }
}

@media screen and (max-width: 767px) {
    footer .footer-secondary .secondary-link-cont ul {
        margin-bottom: 0;
    }
}

footer .footer-secondary .secondary-link-cont li {
    margin-bottom: 2.1875rem;
    min-height: 2.25rem;
}

@media screen and (max-width: 767px) {
    footer .footer-secondary .secondary-link-cont li {
        margin-bottom: .2rem;
        min-height: 3rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media screen and (max-width: 450px) {
    footer .footer-secondary .secondary-link-cont li {
        min-height: 4rem;
    }
}

footer .footer-secondary .secondary-link-cont li a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Inter Regular';
    letter-spacing: 0;
    font-weight: 700;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

footer .footer-secondary .secondary-link-cont li a span {
    width: 2.8125rem;
    height: auto;
    margin-right: 1.5625rem;
}

@media screen and (max-width: 767px) {
    footer .footer-secondary .secondary-link-cont li a span {
        margin-right: 0.3125rem;
        min-width: 2.1875;
    }
}

@media screen and (max-width: 450px) {
    footer .footer-secondary .secondary-link-cont li a span {
        margin-right: 0;
    }
}

@media screen and (max-width: 767px) {
    footer .footer-secondary .secondary-link-cont li a img {
        width: 1.75rem;
        height: auto;
    }
}

footer .footer-secondary .secondary-link-cont li a:hover,
footer .footer-secondary .secondary-link-cont li a:focus {
    text-decoration: underline;
    color: #ffffff;
}

@media screen and (max-width: 767px) {
    footer .footer-secondary .secondary-link-cont li a {
        padding-left: 0.3125rem;
        font-size: 0.875rem;
    }
}

footer .footer-secondary .footer-info-text .footer-btn-yellow {
    margin: 1.5625rem 0 2.8125rem 0;
}

footer .footer-secondary .footer-info-text .footer-btn-yellow:focus,
footer .footer-secondary .footer-info-text .footer-btn-yellow:hover {
    background-color: #ffffff;
    text-decoration: none;
}

footer .footer-secondary .footer-info-text .footer-btn-yellow:focus {
    outline-offset: 0.25rem;
}

footer .footer-secondary .footer-info-text p {
    font-family: 'Inter Regular';
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.5rem;
    max-width: 53.125rem;
    width: 100%;
}

footer .footer-secondary .footer-info-text .copy-text {
    margin: 2.3125rem 0 0 0;
}

footer .footer-secondary .app-store-Icons a {
    display: inline-block;
}

footer .footer-secondary .app-store-Icons .iosAppStoreBtn img {
    width: 8.6875rem;
    height: auto;
}

@media screen and (max-width: 365px) {
    footer .footer-secondary .app-store-Icons .iosAppStoreBtn img {
        width: 7rem;
    }
}

footer .footer-secondary .app-store-Icons .googlePlayBtn {
    margin-left: 1.5625rem;
}

footer .footer-secondary .app-store-Icons .googlePlayBtn img {
    width: 10.1875rem;
    height: auto;
}

@media screen and (max-width: 355px) {
    footer .footer-secondary .app-store-Icons .googlePlayBtn img {
        width: 8.4375rem;
    }
}

@media screen and (max-width: 767px) {
    footer .footer-secondary .app-store-Icons .googlePlayBtn {
        margin-left: 0.9375rem;
    }
}

footer .footer-bottom-cont {
    padding: 3.75rem 0;
    text-align: center;
}

footer .footer-bottom-cont .footer-bottom-links {
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 1.25rem;
}

footer .footer-bottom-cont .footer-bottom-links li {
    position: relative;
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
    font-size: 1rem;
}

@media screen and (max-width: 767px) {
    footer .footer-bottom-cont .footer-bottom-links li {
        font-size: 0.875rem;
    }
}

footer .footer-bottom-cont .footer-bottom-links li:last-child {
    margin-right: 0;
}

footer .footer-bottom-cont .footer-bottom-links li:first-child::after {
    height: 0;
}

footer .footer-bottom-cont .footer-bottom-links li::after {
    position: absolute;
    content: '';
    top: 5px;
    left: -0.9375rem;
    width: 1px;
    height: 0.9375rem;
    background: #ccc;
}

@media screen and (max-width: 767px) {
    footer .footer-bottom-cont .footer-bottom-links li::after {
        display: none;
    }
}

footer .footer-bottom-cont .footer-bottom-links li a {
    letter-spacing: 0;
    text-align: center;
    font-family: 'Inter Regular';
    font-weight: 700;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

footer .footer-bottom-cont .footer-bottom-links li a:hover,
footer .footer-bottom-cont .footer-bottom-links li a:focus {
    text-decoration: underline;
    color: #497CC2;
}

@media screen and (max-width: 767px) {
    footer .footer-bottom-cont .footer-bottom-links li a {
        font-size: 0.875rem;
    }
}

footer .footer-bottom-cont .footer-bottom-note {
    max-width: 53.125rem;
    width: 100%;
    margin: 0 auto;
}

footer .footer-bottom-cont .footer-bottom-note p {
    font-family: 'Inter Regular';
    letter-spacing: 0;
    text-align: center;
    line-height: 0.875rem;
    margin-bottom: 1.25rem;
    font-size: 0.6875rem;
}

@media screen and (max-width: 767px) {
    .order-col-4 {
        -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .order-col-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .order-col-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .order-col-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

/*-- ================================
           Primary Hero - Srart
===================================== */
.hero-title {
    font-size: 3rem;
}

@media screen and (max-width: 767px) {
    .hero-title {
        font-size: 2rem;
    }
}

.primary-hero-banner {
    background-color: #574847;
}

.primary-hero-banner .banner-row {
    position: relative;
}

@media screen and (max-width: 767px) {
    .primary-hero-banner .banner-row .hero-main-img {
        height: 550px;
        overflow: hidden;
    }
}

.primary-hero-banner .banner-row .banner-img-container {
    height: 100%;
}

.primary-hero-banner .banner-row img {
    max-width: 100%;
    width: 100%;
}

@media screen and (max-width: 767px) {
    .primary-hero-banner .banner-row img {
        height: 100%;
    }
}

.primary-hero-banner .banner-sec .banner-text-sec {
    position: absolute;
    width: 100%;
    background: rgba(255, 255, 255, 0.85);
    padding: 1.575rem 3.55rem 3.125rem 0;
    max-width: 61.4%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

@media screen and (max-width: 1200px) {
    .primary-hero-banner .banner-sec .banner-text-sec {
        font-size: 2.25rem;
        letter-spacing: 0;
        line-height: 2.875rem;
        padding: 1.2rem 1.5rem 2.2rem;
        max-width: 90%;
    }
}

@media screen and (max-width: 767px) {
    .primary-hero-banner .banner-sec .banner-text-sec {
        padding: 1.2rem .5rem 1.6rem;
    }
}

.primary-hero-banner .banner-sec p {
    line-height: 1.625rem;
    font-weight: 500;
    margin-bottom: 1.55rem;
    margin-left: 0.9375rem;
    letter-spacing: -0.19px;
    max-width: 90%;
    font-family: 'Inter Medium';
}

@media screen and (max-width: 767px) {
    .primary-hero-banner .banner-sec p {
        margin-bottom: 1rem;
        line-height: 1.5rem;
        font-size: 1.125rem;
    }
}

.primary-hero-banner .banner-text {
    font-weight: 500;
    margin-bottom: 0.85rem;
    margin-left: 0.75rem;
    letter-spacing: -0.44px;
    line-height: 3.75rem;
    color: #000000;
    text-transform: capitalize;
}

@media screen and (max-width: 767px) {
    .primary-hero-banner .banner-text {
        font-size: 2.25rem;
        letter-spacing: 0;
        line-height: normal;
        margin-bottom: 0.8rem;
    }
}

.primary-hero-banner .navbar-form {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    vertical-align: top;
    margin-left: 0.9375rem;
}

@media screen and (max-width: 991px) {
    .primary-hero-banner .navbar-form {
        max-width: 95%;
    }
}

@media screen and (max-width: 767px) {
    .primary-hero-banner .navbar-form {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 90%;
    }
}

.primary-hero-banner .navbar-form .form-control {
    display: inline-block;
    width: 100%;
    height: 2.8125rem;
    padding: 0.9375rem;
    line-height: 1.125rem;
    max-width: 240px;
    border: 1px solid #002f65;
    margin-right: 1.5rem;
}

@media screen and (max-width: 767px) {
    .primary-hero-banner .navbar-form .form-control {
        max-width: 100%;
    }
}

.primary-hero-banner .navbar-form input[type="submit"] {
    min-width: 16.3125rem;
    font-size: 1.125rem;
}

@media only screen and (max-width: 767px) {
    .primary-hero-banner .navbar-form input[type="submit"] {
        width: 100%;
        margin: 0.9375rem 0 0.3125rem 0;
        font-size: 0.875rem;
        max-width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .primary-hero-banner .navbar-form input[type="submit"] {
        min-width: 100%;
    }
}

/*-- ================================
           Primary Hero - End
===================================== */
/*-- ================================
           50/50 Video Right - Start
===================================== */
.video-container {
    padding: 5rem 0;
}

.video-container .right-inner-wrapper {
    padding-left: 1rem;
}

@media only screen and (max-width: 767px) {
    .video-container .right-inner-wrapper {
        padding-left: 0;
    }
}

/*-- ================================
           50/50 Video Right - End
===================================== */
/*-- ================================
    Neighborly Blade - Starts
===================================== */
.info-section {
    padding: 3.8rem 0 4.5rem;
}

@media screen and (max-width: 767px) {
    .info-section {
        padding: 3.2rem 0;
    }
}

.info-section .left-wrap {
    width: 19.5%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

@media screen and (max-width: 767px) {
    .info-section .left-wrap {
        width: 100%;
        margin-bottom: 0;
    }
}

.info-section .left-wrap .img-section {
    margin-right: 3.4375rem;
}

@media screen and (max-width: 767px) {
    .info-section .left-wrap .img-section {
        max-width: 500px;
        margin: 0 auto;
    }
}

.info-section .right-wrap {
    width: 80.5%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

@media screen and (max-width: 767px) {
    .info-section .right-wrap {
        width: 100%;
        padding-left: 0;
        margin-top: 2.375rem;
    }
}

.info-section .right-wrap .content-wrapper {
    max-width: 87%;
}

@media screen and (max-width: 767px) {
    .info-section .right-wrap .content-wrapper {
        max-width: 100%;
    }
}

.info-section .right-wrap .primary-title {
    margin-bottom: 1.25rem;
}

@media screen and (max-width: 767px) {
    .info-section .right-wrap .primary-title {
        font-size: 1.5rem;
    }
}

.info-section .right-wrap .secondary-title {
    color: #383838;
    margin-bottom: 0.5rem;
}

@media screen and (max-width: 767px) {
    .info-section .right-wrap .secondary-title {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
}

.info-section .right-wrap .secondary-title strong {
    margin-right: 0.3125rem;
}

.info-section .right-wrap .link-text {
    margin-top: 0.625rem;
    color: #497CC2;
}

@media screen and (max-width: 767px) {
    .info-section .right-wrap .link-text {
        margin-top: 0.1rem;
    }
}

@media screen and (max-width: 1240px) {
    .info-section .row {
        padding: 0 0.9375rem;
    }
}

@media screen and (max-width: 767px) {
    .info-section .row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

/*-- ================================
    Neighborly Blade - Ends
===================================== */
/*-- ================================
    Services We Provide - Starts
===================================== */
.collapsable-list h3 {
    margin-bottom: 0;
}

.collapsable-list h3 button {
    padding-left: 1.9rem;
    position: relative;
    background-color: transparent;
    border: 0;
    text-align: left;
    margin-bottom: 0.9rem;
}

@media only screen and (max-width: 767px) {
    .collapsable-list h3 button {
        font-size: 1.125rem;
        padding-left: 2.75rem;
        margin-bottom: 1.3rem;
    }
}

.collapsable-list h3 button::before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    content: "";
    background: no-repeat center url(/us/en-us/mr-handyman/_assets/images/right-arrow-faq.svg);
    height: 0.75rem;
    width: 0.625rem;
    position: absolute;
    display: inline-block;
    margin-right: 0.625rem;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    left: 0;
    top: 0.3125rem;
}

.collapsable-list h3 button.collapsed::before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.collapsable-list h3 button:hover,
.collapsable-list h3 button:focus {
    text-decoration: underline;
}

.collapsable-list ul {
    list-style: none;
}

.services-list-section {
    padding: 4.6875rem 0;
}

@media screen and (max-width: 767px) {
    .services-list-section {
        padding: 2.8rem 0 1.9rem 0;
    }
}

.services-list-section .primary-title {
    margin-bottom: 2rem;
}

@media screen and (max-width: 767px) {
    .services-list-section .primary-title {
        font-size: 1.5rem;
        max-width: 80%;
        margin: 0 auto 1.375rem;
        line-height: 1.75rem;
    }
}

@media screen and (max-width: 1200px) {
    .services-list-section h2 {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 500px) {
    .services-list-section h2 {
        font-size: 1rem;
    }
}

.services-list-section .services-list {
    list-style: none;
    margin-left: 2rem;
}

@media screen and (max-width: 767px) {
    .services-list-section .services-list {
        margin-left: 2.8rem;
    }
}

.services-list-section .services-list a {
    line-height: 1.375rem;
    margin-bottom: 0.7rem;
    display: inline-block;
    font-weight: boldFont;
}

.services-list-section .services-list a:hover,
.services-list-section .services-list a:focus {
    color: #B00006;
    text-decoration: underline;
    font-weight: 700;
}

/*-- ================================
    Services We Provide - End
===================================== */
/*-- ================================
           hero-intro-blade - Start
===================================== */
.hero-intro-blade {
    padding: 4.5rem 0 5.5rem 0;
}

@media screen and (max-width: 767px) {
    .hero-intro-blade {
        padding: 1.875rem 0 1.25rem 0;
    }
}

.hero-intro-blade .primary-title {
    margin-bottom: 2.3125rem;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .hero-intro-blade .primary-title {
        font-size: 1.5rem;
        max-width: 80%;
        margin: 0 auto 1.375rem;
        line-height: 1.75rem;
    }
}

.hero-intro-blade .col-space {
    padding: 0 0.75rem;
}

.hero-intro-blade .card {
    border-radius: 10px;
    padding: 2.1875rem 1.5625rem 1.5rem 1.5625rem;
    text-align: center;
    height: 100%;
    display: block;
}

@media screen and (max-width: 991px) {
    .hero-intro-blade .card {
        min-height: 20.3125rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media screen and (max-width: 767px) {
    .hero-intro-blade .card {
        padding: 1.5rem;
        min-height: 14.5rem;
    }
}

.hero-intro-blade .card img {
    width: 4.875rem;
    margin: auto;
}

@media screen and (max-width: 767px) {
    .hero-intro-blade .card img {
        width: 3.75rem;
        margin: 0;
    }
}

.hero-intro-blade .card .secondary-title {
    margin: 1.5rem 0;
    word-wrap: break-word;
}

@media screen and (max-width: 767px) {
    .hero-intro-blade .card .secondary-title {
        font-size: 1rem;
        line-height: 1.625rem;
        margin-bottom: 0;
        margin-top: 1.2rem;
    }
}

.hero-intro-blade .card p {
    letter-spacing: -0.0125rem;
}

.hero-intro-blade .card:hover,
.hero-intro-blade .card:focus {
    background-color: #B00006;
    text-decoration: none;
    color: #ffffff;
}

.hero-intro-blade .card:hover .secondary-title,
.hero-intro-blade .card:focus .secondary-title {
    text-decoration: none;
}

.hero-intro-blade .card:hover p,
.hero-intro-blade .card:focus p {
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .hero-intro-blade p {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .hero-intro-blade .col-space {
        margin-bottom: 1.25rem;
        padding-left: 0.625rem;
        padding-right: 0.9375rem;
    }

    .hero-intro-blade .col-space:nth-child(2n) {
        padding-left: 0.9375rem;
        padding-right: 0.625rem;
    }
}

/*-- ================================
           hero-intro-blade - End
===================================== */
/*-- ================================
           Testimonial - Start
===================================== */
.testimonial-section {
    position: relative;
    background-color: #574847;
}

.testimonial-section::before {
    left: 0;
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(69, 69, 69, 0)), to(#3a3a3a));
    background-image: linear-gradient(270deg, rgba(69, 69, 69, 0) 0%, #3a3a3a 100%);
}

.testimonial-section::after {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    background-image: -webkit-gradient(linear, right top, left top, from(rgba(69, 69, 69, 0)), to(#3a3a3a));
    background-image: linear-gradient(270deg, rgba(69, 69, 69, 0) 0%, #3a3a3a 100%);
    right: 0;
}

.testimonial-section::before,
.testimonial-section::after {
    width: 300px;
    height: 100%;
    position: absolute;
    content: '';
    z-index: 9;
    top: 0;
}

.testimonial-section .hero-main-img {
    height: 635px;
    overflow: hidden;
}

@media screen and (max-width: 991px) {
    .testimonial-section .hero-main-img {
        display: none;
    }
}

.testimonial-section .hero-main-img .banner-img-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 100%;
}

.testimonial-section .hero-main-img .banner-img-container picture {
    height: 100%;
}

.testimonial-section .hero-main-img .banner-img-container img {
    max-width: 100%;
    width: 100%;
}

// .testimonial-section .customert-review {
//     width: 100%;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     -webkit-transform: translate(-50%, -50%);
//     transform: translate(-50%, -50%);
//     z-index: 99;
// }

@media screen and (max-width: 991px) {
    .testimonial-section .customert-review {
        position: relative;
        padding: 50px 0;
        background-color: #574847;
        // -webkit-transform: translate(0, 0);
        // transform: translate(0, 0);
        // top: 0;
        // left: 0;
        z-index: 10 !important;
    }
}

.testimonial-section .client-title {
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.02375rem;
    line-height: 2.625rem;
    margin-bottom: 2.9rem;
    text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
    .testimonial-section .client-title {
        line-height: 2.25rem;
        margin-bottom: 0;
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-section .secondary-btn {
        margin-top: 3.3125rem;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-data {
        margin-top: 3rem;
    }
}

.testimonial-data img {
    margin-bottom: 1.25rem;
}

@media only screen and (max-width: 767px) {
    .testimonial-data img {
        margin-bottom: 0.5rem;
    }
}

.testimonial-content-box {
    max-width: 76%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-top: 0.3125rem;
}

@media only screen and (max-width: 1199px) {
    .testimonial-content-box {
        max-width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .testimonial-content-box {
        max-width: 85%;
    }
}

.testimonial-content-box q {
    margin-bottom: 0;
    letter-spacing: 0.0125rem;
    line-height: 1.625rem;
}

@media only screen and (max-width: 767px) {
    .testimonial-content-box q {
        letter-spacing: 0.019rem;
        font-size: 1rem;
        line-height: 1.625rem;
    }
}

.testimonial-content-box span {
    line-height: 1.1875rem;
    margin-bottom: 0;
    font-weight: 400;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .testimonial-content-box span {
        letter-spacing: 0.019rem;
        font-size: 1rem;
        line-height: 1.625rem;
    }
}

.testimonial-content-box strong {
    margin-top: 0.625rem;
    margin-bottom: 0.3125rem;
    line-height: 1.375rem;
    font-weight: 500;
    display: block;
}

@media only screen and (max-width: 767px) {
    .testimonial-content-box strong {
        line-height: 1.625rem;
        margin-top: 0.4375rem;
        font-size: 1.3125rem;
    }
}

/*-- ================================
           Testimonial - End
===================================== */
/*-- ================================
   threetile-blade-section - Start
===================================== */
.threetile-blade-section {
    padding: 5rem 0;
}

@media screen and (max-width: 767px) {
    .threetile-blade-section {
        padding: 3rem 0;
    }
}

.threetile-blade-section .primary-title {
    margin-bottom: 2.375rem;
}

@media screen and (max-width: 767px) {
    .threetile-blade-section .primary-title {
        line-height: 1.625rem;
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 767px) {
    .threetile-blade-section .card {
        height: 100%;
    }
}

@media screen and (max-width: 767px) {
    .threetile-blade-section .card {
        margin-bottom: 1.875rem;
    }
}

.threetile-blade-section .card .card-img-top {
    min-height: 13.5rem;
    background-color: #d8d8d8;
}

.threetile-blade-section .card-block {
    padding: 1.875rem 1.875rem 2.2rem 1.875rem;
}

@media screen and (max-width: 767px) {
    .threetile-blade-section .card-block {
        padding-bottom: 1.9rem;
    }
}

.threetile-blade-section .card-block .card-title {
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 1rem;
    min-height: 3.125rem;
}

@media screen and (max-width: 767px) {
    .threetile-blade-section .card-block .card-title {
        margin-bottom: 0.5rem;
        line-height: 1.375rem;
        min-height: auto;
        font-size: 1rem;
    }
}

.threetile-blade-section .card-block .card-text {
    letter-spacing: 0;
    line-height: 1.625rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

/*-- ================================
        threetile-blade-section - End
===================================== */
/*-- ================================
        fifty-fifty-section - Start
===================================== */
.left-text-wrap .col-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

@media screen and (max-width: 767px) {
    .left-text-wrap.mob-reverse-col .col-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

.left-text-wrap .text-container {
    padding-right: 3.125rem;
}

@media screen and (max-width: 991px) {
    .left-text-wrap .text-container {
        padding-right: 2rem;
    }
}

@media screen and (max-width: 767px) {
    .left-text-wrap .text-container {
        padding-right: 1rem;
    }
}

.right-text-wrap .col-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
    .right-text-wrap.mob-reverse-col .col-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

.right-text-wrap .text-container {
    padding-left: 3.125rem;
}

@media screen and (max-width: 991px) {
    .right-text-wrap .text-container {
        padding-left: 2rem;
    }
}

@media screen and (max-width: 767px) {
    .right-text-wrap .text-container {
        padding-left: 0;
        padding-right: 1rem;
    }
}

.fifty-fifty-section {
    padding: 4.85rem 0;
}

@media screen and (max-width: 767px) {
    .fifty-fifty-section {
        padding: 2.85rem 0 2rem 0;
    }
}

@media screen and (max-width: 767px) {
    .fifty-fifty-section.left-text-wrap .col-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media screen and (max-width: 767px) {
    .fifty-fifty-section .right-wrap {
        margin-top: 2.5rem;
    }
}

.fifty-fifty-section .video-container {
    padding: 0;
    padding-right: 1.85rem;
}

@media screen and (max-width: 767px) {
    .fifty-fifty-section .video-container {
        padding-right: 0;
    }
}

.fifty-fifty-section .video-container img {
    width: 100%;
}

.fifty-fifty-section .primary-title {
    margin-bottom: 2rem;
    margin-left: 0;
}

@media screen and (max-width: 767px) {
    .fifty-fifty-section .primary-title {
        margin-left: 0;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
}

.fifty-fifty-section .link-text {
    margin-left: 0;
}

.fifty-fifty-section ul {
    list-style-type: none;
}

.fifty-fifty-section ul li {
    margin-bottom: 1rem;
}

.fifty-fifty-section ul li .inner-desc {
    margin-left: 1rem;
}

.fifty-fifty-section ul li h3 {
    letter-spacing: 0;
    line-height: 1.375rem;
    font-weight: 500;
}

.fifty-fifty-section ul li p {
    letter-spacing: 0;
    line-height: 1.625rem;
    font-weight: 400;
    margin-bottom: 0;
}

.fifty-fifty-section .media-left {
    margin-right: 1.25rem;
    min-width: 3.125rem;
    text-align: center;
    margin-left: 0;
}

.fifty-fifty-section .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.fifty-fifty-section .media .withborder-img img {
    border-radius: 50%;
    border: 1px #B00006 solid;
    padding: 13%;
    width: 3.125rem;
    height: 3.125rem;
}

.fifty-fifty-section .media .withoutborder-img img {
    width: 1.875rem;
}

/*-- ================================
        fifty-fifty-section - End
===================================== */
/*-- ================================
        local-map-image - Start
===================================== */
.local-map-image {
    padding: 5.5rem 0;
}

@media screen and (max-width: 767px) {
    .local-map-image {
        padding: 3rem 0 1.5rem 0;
    }
}

.local-map-image .primary-title {
    margin-bottom: 1.3125rem;
    max-width: 80%;
}

@media screen and (max-width: 767px) {
    .local-map-image .primary-title {
        margin-bottom: 1rem;
        line-height: 1.625rem;
        font-size: 1.5rem;
    }
}

.local-map-image p strong {
    color: #0D0D0D;
}

@media only screen and (max-width: 767px) {
    .local-map-image .collapsable-list {
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .local-map-image .collapsable-list button {
        font-size: 1.125rem;
        padding-left: 1.9rem;
        margin-bottom: 0.8rem;
    }
}

.local-map-image ul {
    margin-bottom: 1.2rem;
}

@media screen and (max-width: 767px) {
    .local-map-image ul {
        margin-bottom: 0;
    }
}

.local-map-image ul li {
    font-weight: 400;
    margin-left: 1.9rem;
}

@media screen and (max-width: 1023px) {
    .local-map-image ul li {
        font-size: 0.875rem;
        overflow-wrap: break-word;
        margin-left: 0;
    }
}

@media screen and (max-width: 767px) {
    .local-map-image ul li {
        font-size: 1rem;
        margin-bottom: 0.3125rem;
    }
}

@media screen and (max-width: 380px) {
    .local-map-image ul li {
        margin-left: 0;
    }
}

.local-map-image .mt-space {
    margin-top: 3rem;
}

@media screen and (max-width: 767px) {
    .local-map-image .mt-space {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 767px) {
    .local-map-image .right-wrap {
        margin-bottom: 2.1875rem;
    }
}

.local-map-image .accordion-button:hover,
.local-map-image .accordion-button:focus {
    text-decoration: underline;
}

/*-- ================================
        local-map-image - End
===================================== */
/*-- ================================
        services-wrapper  -Start
===================================== */
.services-wrapper {
    padding: 5rem 0;
}

@media screen and (max-width: 767px) {
    .services-wrapper {
        padding: 2rem 0;
    }
}

@media screen and (max-width: 767px) {
    .services-wrapper.left-text-wrap .col-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media screen and (max-width: 767px) {
    .services-wrapper.right-text-wrap .col-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.services-wrapper.right-text-wrap .left-wrap {
    padding-right: 0.9375rem;
    padding-left: 3.5rem;
}

.services-wrapper.right-text-wrap .text-container {
    padding-left: 0;
}

.services-wrapper .left-wrap {
    padding-right: 3.5rem;
}

@media screen and (max-width: 767px) {
    .services-wrapper .left-wrap {
        width: 100%;
        margin-bottom: 2.1875rem;
        padding: 0;
    }
}

.services-wrapper .left-wrap .img-section {
    position: relative;
}

@media screen and (max-width: 767px) {
    .services-wrapper .left-wrap .img-section {
        max-width: 10.6875rem;
        margin: 0 auto;
    }
}

.services-wrapper .left-wrap .img-section .top-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 3.625rem;
    height: 3.625rem;
}

@media screen and (max-width: 991px) {
    .services-wrapper .left-wrap .img-section .top-img {
        width: 2.8125rem;
        height: 2.8125rem;
    }
}

@media screen and (max-width: 767px) {
    .services-wrapper .left-wrap .img-section .top-img {
        width: 3.625rem;
        height: 3.625rem;
    }
}

.services-wrapper .left-wrap .img-section .top-img.border-img {
    border-radius: 50%;
    border: 2px solid #EE3831;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 3.75rem;
    height: 3.75rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0.75rem;
    background: #ffffff;
    top: 0;
    right: 0;
}

@media screen and (max-width: 991px) {
    .services-wrapper .left-wrap .img-section .top-img.border-img {
        width: 2.75rem;
        height: 2.75rem;
        padding: 0.5rem;
    }
}

@media screen and (max-width: 767px) {
    .services-wrapper .left-wrap .img-section .top-img.border-img {
        width: 3.5rem;
        height: 3.5rem;
        padding: 0.5rem;
    }
}

.services-wrapper .right-wrap .text-container {
    max-width: 87%;
}

@media screen and (max-width: 767px) {
    .services-wrapper .right-wrap .text-container {
        max-width: 100%;
        padding: 0;
    }
}

.services-wrapper .right-wrap .primary-title {
    margin-bottom: 1.25rem;
}

@media screen and (max-width: 767px) {
    .services-wrapper .right-wrap .primary-title {
        font-size: 1.5rem;
        line-height: 2.125rem;
        margin-bottom: .6rem;
    }
}

.services-wrapper .right-wrap .secondary-title {
    margin-bottom: 0.5rem;
}

@media screen and (max-width: 767px) {
    .services-wrapper .right-wrap .secondary-title {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
}

.services-wrapper .right-wrap strong {
    margin-right: 0.3125rem;
}

.services-wrapper .right-wrap .link-text {
    margin-top: 0.625rem;
}

@media screen and (max-width: 767px) {
    .services-wrapper .right-wrap .link-text {
        font-size: 1.125rem;
        line-height: 2.25rem;
    }
}

.services-wrapper .list-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 1.2rem;
    margin-top: 1.5rem;
}

.services-wrapper .list-inline ul {
    width: 44%;
}

@media screen and (max-width: 767px) {
    .services-wrapper .list-inline ul {
        width: 100%;
    }
}

.services-wrapper .list-inline ul li {
    margin-bottom: 0.75rem;
    letter-spacing: 0;
    line-height: 1.625rem;
    font-weight: 500;
    margin-right: 2rem;
    font-family: 'Inter Bold';
}

@media screen and (max-width: 767px) {
    .services-wrapper .list-inline ul li {
        font-size: 1rem;
    }
}

/*-- ================================
      services-wrapper -End 
===================================== */