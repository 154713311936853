/*submenu section css start*/
.new-submneu .mega-menu-front .list-item {
	margin: 10px 0 20px 0;
	width: 19%;
	flex: 0;
	display: inline-block;
	padding: 0;
	max-width: 100%;
}

.new-submneu .mega-menu-front {
	display: inline-block;
}

.new-submneu .mega-menu-front button,
.new-submneu .mega-menu-front .view-all-link {
	display: block;
}

.new-submneu .mega-menu-front button span {
	display: block;
	max-width: 100%;
	text-align: center;
	padding: 0;
	font-size: 18px;
}

.new-submneu .mega-menu-front button img.img-hover,
.new-submneu .mega-menu-front .view-all-link img.img-hover {
	display: block;
	margin: 0 auto 10px auto;
}

/*submenu section css end*/
/*search box section css start*/
.search-box {
	background-color: rgba(235, 176, 36, 0.15);
}

.search-box .top-menu .locate-form-wrapper .locate-form .form-control:focus {
	outline: none;
	color: #888;
}

.search-box .top-menu .locate-form-wrapper .locate-form .col-form-label {
	padding: 0;
	color: #333333;
	font-weight: 600;
}

.search-box .top-menu .locate-form-wrapper .locate-form .form-control {
	margin: 0;
	max-width: 20rem;
	height: 2.2rem;
	border: 1px solid #8c8c8c;
	border-radius: 3px 0 0 3px;
	margin-left: 10px;
}

.search-box .top-menu .locate-form-wrapper .locate-form button {
	background: #b52126;
	border: none;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	width: 70px;
	height: 36px;
	border-radius: 0 3px 3px 0;
	margin: 0 0 0 -5px;
}

.search-box .top-menu .locate-form-wrapper .locate-form button:hover {
	background-color: #da291c;
}
/*search box section css end*/
.top-menu .locate-form-wrapper .locate-form .col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	letter-spacing: 0;
	text-align: right;
	color: #717171;
	font-size: 11px;
	line-height: 16px;
}

.top-menu .locate-form-wrapper .locate-form .form-control {
	background: #ffffff;
	border: 1px solid #797979;
	height: 2rem;
	max-width: 11.5rem;
	margin: 0 0 0 20px;
	border-radius: 5px 0 0 5px;
	color: #797979;
}

.top-menu .locate-form-wrapper .locate-form .locate-btn {
	padding: 5px 10px;
	border: none;
	font-family: 'Inter Regular';
	background: #000000;
	color: #fff;
	border-radius: 0 5px 5px 0;
	font-size: 15px;
	font-weight: bold;
}

.header-destkop .header-classic .navbar-nav .dropmenu {
	/* max-width: 950px; */
	max-width: 1400px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin: auto;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.header-destkop .header-classic .navbar-nav .dropdown-menu {
	background: rgba(255, 255, 255, 0.97);
	border-bottom: 5px solid #ebb024;
	border-right: none;
	border-radius: 0;
	margin: 0;
	top: 100%;
	padding-top: 0;
	border-top: none;
}

.header-destkop .header-classic .navbar-nav .menu-title h5 {
	color: #333333;
	font-family: 'Inter Medium';
	font-weight: 500;
	line-height: 1.35;
	letter-spacing: 0;
	width: 100%;
	display: block;
	margin-bottom: 10px;
	font-size: 16px;
}

.header-destkop .header-classic .navbar-nav .dropmenu.new-submenu ul.mega-menu-front div.dropmenu {
	border-bottom: 1px solid #797979;
	margin: 0 20px 20px 20px;
}


.header-destkop .sub-menu-right .inner-menu-row {
	background: #f2f2f2;
	border-top: 2px solid #b81f24;
	padding: 10px 20px 30px 20px;
}

/*submenu section css start*/
.header-destkop .new-submenu .mega-menu-front .list-item {
	margin: 10px 0 20px 0;
	width: 15%;
	flex: 0;
	display: inline-block;
	padding: 0;
	max-width: 100%;
}


.header-destkop .new-submenu .mega-menu-front {
	display: inline-block;
	text-align: center;
}

.header-destkop .new-submenu .mega-menu-front button,
.header-destkop .new-submenu.mega-menu-front .view-all-link {
	display: block;
}

.header-destkop .new-submenu .mega-menu-front button span {
	display: block;
	max-width: 100%;
	text-align: center;
	padding: 0;
	font-size: 18px;
}

.header-destkop .new-submenu .mega-menu-front button img.img-hover,
.header-destkop .new-submenu .mega-menu-front .view-all-link img.img-hover {
	display: block;
	margin: 0 auto 10px auto;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-left ul.inner-menu-list li {
	list-style: none;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-left ul.inner-menu-list li a {
	padding: 2px 5px;
	background: none;
	color: #333333;
	font-size: 16px;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-left ul.inner-menu-list li a:hover {
	background: #b81f24;
	color: #fff;
}

.header-destkop .sub-menu-right h5 {
	color: #333;
	font-size: 18px;
	padding: 0 0 5px 0;
	margin: 0 0 3px 0;
}

.header-destkop .sub-menu-left h5 {
	border-bottom: 1px solid #000;
	margin: 2px 5px;
	font-size: 18px;
	padding-bottom: 5px;
}

.header-destkop .sub-menu-left ul {
	margin-top: 10px;
	margin-left: 5px;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-left ul.inner-menu-list li a.dropdown-item:active {
	background: #b52126;
	color: #fff;
}

.header-destkop .sub-menu-right .inner-menu-row ul.inner-menu-list li.list-item button img {
	border: none;
	background: #fff;
	border-radius: 100%;
	padding: 10px;
	width: 60px;
	height: 60px;
}

.header-destkop .sub-menu-right .inner-menu-row ul.inner-menu-list li.list-item button {
	border: none;
	background: none;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-right {
	display: none;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-right.show {
	display: block;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col {
	padding-right: 1.25rem;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 23%;
	flex: 0 0 23% !important;
	max-width: 23% !important;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col:first-child {
	padding-right: 1.25rem;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 20%;
	flex: 0 0 10%;
	max-width: 10% !important;
	padding-top: 10px !important;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-left ul.inner-menu-list li.active a {
	background: #b52126;
	color: #fff;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-right .menu-col ul li.inner-list-item a {
	padding-right: 0;
	font-size: 14px;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-right .menu-col ul li.inner-list-item a strong {
	font-size: 16px;
	color: #333333;
}

.header-destkop .header-classic .main-nav a {
	border: 1px solid transparent;
	color: #333333;
	font-size: 15px;
}

.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-right .menu-col ul li.inner-list-item a.dropdown-item:focus,
.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-right .menu-col ul li.inner-list-item a.dropdown-item:hover,
.header-destkop .header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-right .menu-col ul li.inner-list-item a.dropdown-item:active {
	color: #333;
}

.header-mobile {
	display: none;
}

.header-classic.mobile-header {
	display: none;
}

.scheduler-modal .result-Stack-logo {
	margin-top: 0.6875rem;
}

/*submenu section css end*/
/*search box section css start*/
.search-box .top-menu .locate-form-wrapper .locate-form .col-form-label {
	padding: 0;
	color: #333333;
	font-weight: 500;
	font-size: 14px;
}

.search-box.submenu_open {
	z-index: 999;
}

.search-box {
	background-color: #fcf3de;
	z-index: 100;
	position: relative;
	-webkit-transition: all 0.5s ease;
	-moz-transition: position 10s;
	-ms-transition: position 10s;
	-o-transition: position 10s;
	transition: all 0.5s ease;
}

.header-mobile .request-mobile-sec ul.list-inline.fixed-header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
	background: #fff;
	animation: smoothScroll 0.5s forwards;
}

@keyframes smoothScroll {
	0% {
		transform: translateY(-40px);
	}

	100% {
		transform: translateY(0px);
	}
}

.search-box .top-menu .locate-form-wrapper .locate-form .form-control:focus {
	outline: none;
	color: #888;
}

.search-box .locate-form-wrapper .locate-form .col-form-label {
	padding: 0;
	color: #333333;
	font-weight: 600;
}

.search-box .locate-form-wrapper .locate-form .form-control {
	margin: 0;
	max-width: 20rem;
	height: 2.2rem;
	border: 1px solid #8c8c8c;
	border-radius: 3px 0 0 3px;
	margin-left: 10px;
}

.search-box .locate-form-wrapper .locate-form button {
	background: #b52126;
	border: none;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	width: 70px;
	height: 35px;
	border-radius: 0 3px 3px 0;
	margin: 0 0 0 0px;
}

.search-box form.locate-form.form-group {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0px;
	min-height: 4.125rem;
}

.search-box .locate-form-wrapper .locate-form button:hover {
	background-color: #da291c;
}

#sub-menu-right-services .menu-col {
	padding-top: 10px;
}

/*search box section css end*/

/*header section mobile css start*/
@media (min-width: 992px) {
	.header-destkop .header-classic .main-nav .navbar-nav .dropdown.active-sub-menu-open>.dropdown-menu {
		display: block;
		margin-top: 66px;
		z-index: 98;
		padding-top: 20px;
	}
}

@media (max-width: 1670px) {
	.header-mobile .nav-links .nav-section {
		width: 100%;
		margin: auto;
		background: #fff;
	}
}

@media (max-width: 991px) {
	.header-mobile .header-classic .navbar-nav {
		min-height: 200px;
	}

	.header-classic .nav-link:hover,
	.header-classic .nav-link:focus {
		text-decoration: underline;
		color: #B52126;
		outline: none;
	}

	.header-destkop {
		display: none;
	}

	.header-mobile {
		position: relative;
		display: block;
		z-index: 999;
	}

	.header-classic .main-nav .navbar-collapse {
		top: 0;
		padding-top: 0;
		background: none;
		position: fixed;
		overflow-y: scroll;
	}
	.offcanvas-active .header-mobile .header-classic .main-nav .navbar-collapse {
		top: 53px;
        background-color: #fff;
	}
	

	.mega-menu-front.secound-menu {
        padding-top: 5px;
        background: #fff;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
	}

	.header-mobile .search-box {
		z-index: 1;
	}
	body.offcanvas-active .header-mobile .search-box {
		position: fixed;
		width: 100%;
		top: 53px;
		z-index: 99999;
	}

	.header-mobile .search-box.open-search {
		display: none;
	}

	.request-mobile-sec.sticky-header ul li .call-icon {
		font-size: 0.8125rem;
	}

	.header-mobile .back-arrow-link::before {
		background: url(/us/en-us/mr-handyman/_assets/images/left-chevron-white.svg) top no-repeat;
	}


	ul.mega-menu-front.js-mega-menu-front.secound-menu li {
		display: inline-block;
		width: 27%;
		flex: 0 0 31%;
		border: sienna;
	}

	ul.mega-menu-front.js-mega-menu-front.secound-menu li.list-item button {
		display: block;
		text-align: center;
		border: none;
	}

	ul.mega-menu-front.js-mega-menu-front.secound-menu li.list-item button img {
		margin: auto;
		width: 50px;
	}

	ul.mega-menu-front.js-mega-menu-front.secound-menu li.list-item button:before {
		background: none;
	}

	.header-classic .navbar-nav h2.overview-link, .header-classic .navbar-nav span.overview-link,
	.header-classic .navbar-nav .overview-link {
		display: block;
		background: #000000;
		font-size: 0.875rem;
		color: #ebb024;
		letter-spacing: 1px;
		font-weight: 500;
		padding: 0.625rem 1rem !important;
		text-align: left;
		text-transform: capitalize;
		width: 100%;
		margin: 0;
		line-height: 1.72;
	}

	.header-classic .navbar-nav h2.overview-link, .header-classic .navbar-nav span.overview-link a {
		color: #ebb024 !important;
	}

	.header-mobile .mega-menu-front button img.img-hover {
		display: block;
	}


	.request-mobile-sec.sticky-header ul li .call-icon {
		font-size: 0.8125rem;
	}

	.search-form button {
		background: #b52126;
		border: none;
		color: #fff;
		text-transform: uppercase;
		font-weight: bold;
		width: 44px;
		height: 31px;
		border-radius: 3px;
		font-size: 12px;
		//margin: 0 5px 0 0 !important;
		min-height: 100%;
		text-align: center;
		display: block;
		line-height: normal;
        margin: 0 0 0 10px !important;
        padding-top: 0px;
	}

	.search-form input[type="search"] {
		max-width: 11.5rem !important;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 5px;
        padding-left: 5px;
        height: 1rem !important;
	}

	form.search-form.d-flex label {
		font-size: 0.69rem;
        padding-left: 5px;
	}

	form.search-form {
		padding: 5px 0;
		justify-content: center;
		background: #fcf3de;
		align-items: center;
	}

	.header-mobile .find-sec {
		margin: 0;
		background: #fcf3de;
		padding: 1rem;
		z-index: 999;
		position: absolute;
		bottom: 34px;
		width: 100%;
		display: none;
        min-height: 140px;
	}

	body.offcanvas-active .header-mobile .find-sec {
		display: block;
		position: relative;
		z-index: 1;
		bottom: 0;
	}


    // .header-mobile .find-sec form {
    //  display: flex;
    //  }

	.header-mobile .find-sec label {
		// margin: 0 0 10px 0;
        display: inline-block;
        font-size: 1rem;
        color: #B52126;
        letter-spacing: 0.96px;
        width: 100%;
        margin: 0px 0px 5px 0px;
        font-weight: 500;
        font-family: 'Inter Bold';
	}

	.find-sec input[type="text"] {
		margin: 0;
		height: 30px;
		font-size: 13px;
		border-radius: 5px;
		border-color: #797979;
		max-width: 11rem;
        display: inline-block;
	}

	.header-mobile .header-classic .arrow-right-link::before {
		right: 20px;
	}


	ul.dropdown-menu.megamenu {
		position: fixed;
		left: -100%;
	}

	ul.dropdown-menu.megamenu {
		left: 0;
		transition: all 0.5s;
	}

	.header-classic .navbar-nav .dropmenu .mega-menu-links .inner-menu-row {
		right: -100%;
		position: fixed;
		opacity: 0;
		visibility: hidden;
		transition: all 0.5s;
		top: 0px;

	}

	.header-classic .navbar-nav .dropmenu .mega-menu-links .current .inner-menu-row {
		opacity: 1;
		visibility: visible;
		transition: all 0.5s;
		right: 0;
		top: 0px;
		padding: 0 !important;
		position: absolute;
	}

	.header-classic .nav-link {
		padding: 0.525rem 0.625rem;
	}

	.header-classic.mobile-header .navbar-nav .col-section.active .dropdown-menu-inner {
		position: absolute;
		padding: 0 1rem;
	}

	.header-classic.mobile-header .navbar-nav .menu-col .col-section {
		margin: 0;
	}

	body .scheduler-modal .result-Stack-logo {
		width: 100%;
		display: block;
		min-height: 3.6875rem;
	}

	.scheduler-modal .neighborly-stamp-wrap {
		margin-top: 10px;
	}

	.header-classic.desktop-header {
		display: none;
	}

	.mobile-header .request-mobile-sec {
		padding: 0;
	}

	.header-classic.mobile-header {
		display: block !important;
		padding-bottom: 0;
		transition: all 0.5s ease-in-out;
	}

	.header-classic.mobile-header .navbar-collapse.show .dropdown .dropdown-menu {
        padding: 0 !important;
        background: none;
        overflow-y: auto !important;
        box-shadow: none;
        margin: 0;
        position: absolute;
        width: 100%;
        display: block;
        top: 0;
        height: 100%;

	}

	.offcanvas-active .mobile-header .header-left-sec {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.mobile-header .col-sec-left {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0 0 0.7rem 0;
		text-align: center;
	}

	.mobile-header .main-nav .mega-menu-front button.back-arrow-link,
	.header-classic.mobile-header .navbar-nav .menu-col .back-arrow-link {
		display: inline-block;
		width: 80px;
		background: #b52126;
		color: #fff;
		margin: 7px 0 7px 10px;
		border-radius: 5px;
		height: auto;
		line-height: 1 !important;
		padding: 8px 0 8px 10px !important;
		font-weight: 100 !important;
	}

	.request-mobile-sec.sticky-header .find-sec .primary-btn {
		min-width: 5rem;
		border-radius: 5px;
		min-height: 1.1rem;
		margin-left: 10px;
	}

	.request-mobile-sec.sticky-header ul li .call-icon {
		color: #fff;
		justify-content: start;
		font-family: 'Inter Medium';
		font-weight: normal;
		font-size: 1.1875rem;
		font-weight: 500;
		line-height: 1.5;
		min-width: 100%;
		min-height: 2.8125rem;
	}

	.request-mobile-sec.sticky-header ul li .call-icon img {
		width: 14px;
		height: 22px;
		margin-right: 5px;
		margin-top: -2px;
	}

	.request-mobile-sec.sticky-header ul li .call-icon {
		color: #fff;
		justify-content: start;
		font-family: 'Inter Medium';
		font-weight: normal;
		min-width: 8rem;
		min-height: 2.125rem;
		font-size: 0.8125rem;
		padding-left: 0.8125rem;
	}

	.request-mobile-sec.sticky-header ul li .call-icon img {
		width: 14px;
		height: 18px;
		margin-right: 5px;
		margin-top: -2px;
	}

	.header-classic.mobile-header .logo-sec .navbar-brand {
		margin: auto;
		padding: 0;
	}

	.header-classic.mobile-header .nav-container .row {
		margin: 0;
	}

	.mobile-header .request-mobile-sec ul.nav li {
		width: 100%;
        padding-top: 3px;
	}


	.mobile-header .mobile-logo-sec {
		padding-bottom: 0.875rem;
		align-items: end;
		border-bottom: 1px solid #e3e3e3;
		padding-right: 1.5rem;
		background: #fff;
		justify-content: end;
		-webkit-justify-content: flex-end;	
		align-items: flex-end;
		-webkit-align-items: flex-end;
	}

	.header-classic.mobile-header .main-nav {
		height: auto;
	}

	.sticky-header .nav-links .hamburger-menu {
		text-align: center;
		margin: auto;
		display: block;
		background: #b52126;
		width: 2.8rem;
		padding: 7px 10px;
		right: 0;
		top: 0;
		position: relative;
	}

	.header-classic.mobile-header .navbar-nav h2.overview-link, .header-classic .navbar-nav span.overview-link,
	.header-classic.mobile-header .navbar-nav .overview-link {
		padding: 0.625rem;
	}

	.sticky-header .nav-links .hamburger-menu span {
		background: #fff;
	}

	.request-mobile-sec.sticky-header>ul {
		align-items: center;
		padding: 0.58rem;
	}
	body.offcanvas-active .header-mobile .request-mobile-sec ul.list-inline {
		position: fixed;
		width: 100%;
		top: 0;
		background: #fff;
		
		animation: none;
	}

	.request-mobile-sec.sticky-header ul li span {
		font-size: 0.875rem;
	}

	.sticky-header .nav-links .hamburger-menu:hover {
		background: #da291c;
	}

	.request-mobile-sec.sticky-header .primary-btn {
		min-width: 100%;
		padding: 0 0.8125rem;
		min-height: 2.1925rem;
		font-size: 12.5px;
	}

	.sticky-header .nav-links .hamburger-menu.activate span:nth-child(2) {
		opacity: 0;
	}

	.sticky-header .nav-links .hamburger-menu.activate span:nth-child(1) {
		transform: rotate(45deg);
		position: relative;
		top: 6px;
	}

	.sticky-header .nav-links .hamburger-menu.activate span:nth-child(3) {
		transform: rotate(-45deg);
		top: -6px;
		position: relative;
	}

	.request-mobile-sec.sticky-header.sticky {
		position: fixed;
		width: 100%;
		z-index: 99;
		top: 0;
		padding-bottom: 0;
        -webkit-transition: 0.25s ease-in;
        transition: 0.25s ease-in;
	}

	.header-classic.mobile-header .navbar-nav .col-section ul,
	.header-classic.mobile-header .navbar-nav .menu-col .inner-menu-list {
		display: block;
		padding: 0px;
	}

	.header-classic.mobile-header .navbar-nav .menu-col .inner-menu-list {
		padding: 0;
	}

	.mobile-header .main-nav .mega-menu-front button {
		margin: 0;
	}

	.header-classic.mobile-header .navbar-collapse .mobile-search {
		margin-bottom: 10px;
	}

	.header-classic.mobile-header .navbar-nav .menu-col .back-arrow-link {
		padding: 0.625rem 0 0.625rem 0.325rem;
		line-height: 1.72;
	}

	.header-classic.mobile-header .logo-sec .navbar-brand img {
		max-width: 10rem;
	}

	body .scheduler-modal .result-Stack-logo img {

		width: 10.25rem;
	}

	body .scheduler-modal .modal-dialog .close {
		top: -1rem;
	}

	body .scheduler-modal .head-title {

		margin: 0.8125rem auto;
	}

	.mobile-header .logo-sec.hide {
		display: block;
	}

	body.offcanvas-active .mobile-header .logo-sec,
	.mobile-header .request-mobile-sec.sticky-header.sticky .logo-sec {
		display: none;
	}

	.header-classic.mobile-header .navbar-nav .dropdown-menu {
		position: absolute;
	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col {
		padding: 0;
        padding-top: 5px;
	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col .screen-three .sub-menu-right div.repair-menu.current {
		display: block;
	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col .screen-three .sub-menu-right div.repair-menu {
		display: none;
	}

	.mobile-logo-sec .close-btn {
		border: none;
		background-color: transparent;
		position: relative;
		z-index: 999;

	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col .row .col-lg-3 {
		width: 35%;
		background: #000;
		flex: 0 0 35%;
		max-width: 35%;
		height: 400px;
	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col .row {
		display: flex;
	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col .row .col-lg-9 {
		width: 65%;
		max-width: 65%;
		flex: 0 0 65%;
	}

	.screen-three .sub-menu-right ul li,
	.screen-three .sub-menu-right ul {
		display: block;
	}


	.screen-three .sub-menu-right ul li a {
		padding: 3px 0;
		border-bottom: 1px solid #ccc;
	}

	.screen-three .sub-menu-left ul li a {
		color: #ffff;
	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col .row .col-lg-3 .sub-menu-left ul.inner-menu-list-three li a {
		color: #fff;
		border-bottom: none;
		padding: 0 0 10px 0;
		font-weight: 500;
        white-space: break-spaces;
		line-height: 1.2;
		font-size: 14px;
	}

	.header-classic.mobile-header .request-mobile-sec ul.inner-menu-list-three {
		display: block;
	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col .row .col-lg-3 .sub-menu-left ul.inner-menu-list-three li {
		border-bottom: none;
	}

	.screen-three .sub-menu-left ul li a.dropdown-item:hover,
	.screen-three .sub-menu-left ul li a.dropdown-item:focus {
		background: none;
		outline: none;
	}

	.screen-three {
		margin: 20px 0px;
	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col .row .col-lg-3 .sub-menu-left {
		padding: 15px 0;
	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col .screen-three .sub-menu-right h5 {
		color: #b81f24;
		margin: 5px 0 5px 0;
		font-size: 18px;
	}

	.header-classic.mobile-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col .row .col-lg-3 .sub-menu-left ul.inner-menu-list-three li.active a {
		color: #ebb024;
        white-space: break-spaces;
	}
}

li.list-item-img img {
    border: none;
    background: #fff;
    border-radius: 100%;
    padding: 10px;
    width: 60px;
    height: 60px;
}
li.list-item-img button {
    border: none;
    background: none;
    margin: 0 -10px;
}
.header-classic .navbar-nav .menu-col .inner-menu-list .dropdown-item {
    padding-left: 0;
    padding-bottom: 0;
    font-size: 1rem;
    color: #0d0d0d;
    background-color: rgba(0,0,0,0);
    display: block;
    white-space: normal;
}

.header-destkop .sub-menu-right .inner-menu-row ul.inner-menu-list li.list-item button {
    @media screen and (min-width: 991px) {
    padding: 0 0px;
    margin: 0 -10px;
    }
}


//added list style none for custom UL
.custom-menu-list-item-inner {
    list-style-type:none;
}

// added the custom padding between menus 
.header-classic .navbar-nav .dropmenu .mega-menu-links .inner-menu-row {
    column-gap: inherit !important;
}
// adjusted the width of menu col
.header-classic.desktop-header .navbar-nav .dropmenu .mega-menu-links .inner-menu-row .menu-col:first-child {
    -ms-flex: 0 0 20%;
    flex: 0 0 10% !important;
    max-width: 10% !important;
}
// added a padding left for sub menu right 
.header-classic.desktop-header .navbar-nav .dropmenu .mega-menu-links .sub-menu-right.show {
    padding-left: 100px;
    width: auto;
}
.header-classic .navbar-nav .col-section .dropdown-item {
    font-family: "Inter Bold";
    font-size: 1.125rem;
    letter-spacing: 0;
    margin-bottom: 1.65rem;
}
.header-classic .main-nav .navbar-nav .dropdown:hover>.dropdown-menu{
    padding-left: 2rem;
    padding-right: 2rem;
}
/*mobile-header section css end*/