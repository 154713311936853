.combined-locations-count {
    height: 2rem;
    width: 2rem;
    background-color: #DA291C;
    border-radius: 50%;
    /*display: inline-block;*/
    outline: 0.5vw solid #DA291C;
    border: 0.2rem solid white;  
    border-style: solid;
    font-size: 0.8rem;
    justify-content: center;
    display: flex; /* or inline-flex */
    align-items: center; 
    color: white;
    margin: 0.5rem;
  }