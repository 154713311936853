/*-- ==========================================
       Review Section Start
============================================= --*/
.review-container {
    padding: 1.25rem 0 1.875rem;
  }
  
  .review-container:nth-child(even) {
    background-color: #f0f0f0;
  }
  
  .review-section .review-title {
    font-size: 1.5rem;
    letter-spacing: 0.47px;
    margin-bottom: 0.75rem;
  }
  
  .user-avatar {
    display: inline-block;
    vertical-align: top;
    padding-right: 1.25rem;
  }
  
  @media screen and (max-width: 992px) {
    .user-avatar {
      text-align: center;
      display: block;
      padding-right: 0;
    }
  }
  
  .user-review {
    max-width: 870px;
    display: inline-block;
  }
  
  @media screen and (max-width: 992px) {
    .user-review {
      max-width: 100%;
      display: block;
      text-align: center;
    }
  }
  
  .user-review .reviewer-info-wrap {
    padding-top: 1rem;
    padding-bottom: 0.85rem;
  }
  
  @media screen and (max-width: 992px) {
    .user-review .reviewer-info-wrap {
      text-align: center;
    }
  }
  
  .user-review .reviewer-info-wrap .star-rating {
    list-style-type: none;
    display: inline-block;
    margin-bottom: 0;
  }
  
  .user-review .reviewer-info-wrap .star-rating li {
    display: inline-block;
    vertical-align: text-bottom;
  }
  
  .user-review .reviewer-info-wrap strong {
    display: inline-block;
    color: #EE3831;
    margin-bottom: 0;
    margin-left: 0.3125rem;
  }
  
  @media screen and (max-width: 992px) {
    .user-review .reviewer-info-wrap strong {
      text-align: center;
      display: block;
      margin-left: 0;
    }
  }
  
  .user-review p {
    font-family: "Inter Medium";
    line-height: 1.625rem;
  }
  
  @media screen and (max-width: 767px) {
    .user-review p {
      text-align: center;
    }
  }
  
  .pagination-wrapper {
    padding-top: 3.4rem;
    padding-bottom: 3.75rem;
  }
  
  @media screen and (max-width: 767px) {
    .pagination-wrapper {
      padding-top: 1.4rem;
      padding-bottom: 1.75rem;
    }
  }
  
  .reviews-pagination-section {
    position: relative;
    padding-top: 0;
  }
  
  .reviews-pagination-section:before {
    content: '';
    display: block;
    height: 2px;
    width: 96%;
    margin: 0 auto;
    background-color: #ccc;
    max-width: 1200px;
  }
  
  @media screen and (max-width: 575px) {
    .reviews-pagination-section:before {
      width: 92%;
    }
  }
  
  .reviews-pagination-section .pagination {
    display: block;
    margin-bottom: 0;
  }
  
  @media screen and (max-width: 767px) {
    .reviews-pagination-section .pagination {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  
  .reviews-pagination-section .pagination li {
    display: inline-block;
    font-family: "Inter Bold";
  }
  
  @media screen and (max-width: 767px) {
    .reviews-pagination-section .pagination li:first-child {
      float: left;
    }
    .reviews-pagination-section .pagination li:last-child {
      float: right;
    }
  }
  
  .reviews-pagination-section .pagination li .pagination-item {
    position: relative;
    color: #EE3831;
    padding: 0 0.25rem;
  }
  
  .reviews-pagination-section .pagination li .pagination-item.disabled {
    color: #898989;
    pointer-events: none;
  }
  
  .reviews-pagination-section .pagination li .pagination-item.current {
    color: #4e4e4e;
  }
  
  .reviews-pagination-section .pagination li .pagination-item.current:hover {
    color: #4e4e4e;
  }
  
  .reviews-pagination-section .pagination li .pagination-item.last {
    padding-right: 1.2rem;
  }
  
  .reviews-pagination-section .pagination li .pagination-item.last::after {
    content: "";
    background-image: url(/us/en-us/mr-handyman/_assets/images/right-chevron-arrow.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 0.563rem;
    height: 0.938rem;
    position: absolute;
    -webkit-transform: translate(0, -65%) rotate(180deg);
            transform: translate(0, -65%) rotate(180deg);
    right: 0;
    top: 65%;
  }
  
  .reviews-pagination-section .pagination li .pagination-item.last:disabled::after, .reviews-pagination-section .pagination li .pagination-item.last.disabled::after {
    background-image: url(/us/en-us/mr-handyman/_assets/images/left-chevron-arrow.svg);
  }
  
  .reviews-pagination-section .pagination li .pagination-item.first {
    padding-left: 1.2rem;
  }
  
  .reviews-pagination-section .pagination li .pagination-item.first::before {
    background-image: url(/us/en-us/mr-handyman/_assets/images/right-chevron-arrow.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 0.563rem;
    height: 0.938rem;
    position: absolute;
    content: "";
    -webkit-transform: translate(0, -60%);
            transform: translate(0, -60%);
    top: 60%;
    left: 0;
  }
  
  .reviews-pagination-section .pagination li .pagination-item.first:disabled::before, .reviews-pagination-section .pagination li .pagination-item.first.disabled::before {
    background-image: url(/us/en-us/mr-handyman/_assets/images/left-chevron-arrow.svg);
  }
  
  .total-review {
    text-align: right;
  }
  
  .total-review p {
    margin-bottom: 0;
  }
  
  @media screen and (max-width: 767px) {
    .total-review p {
      text-align: center;
    }
  }
  
  .total-review p strong {
    color: #EE3831;
    padding-left: 0.3rem;
  }
  
  /*-- ==========================================
         Review Section End
  ============================================= --*/
  /*-- ==========================================
        Service Coupon Section Start
  ============================================= --*/
  .service-coupon-section {
    padding: 5rem 0;
  }
  
  @media screen and (max-width: 767px) {
    .service-coupon-section {
      padding: 2.813rem 0 1.8rem;
    }
  }
  
  .service-coupon-section .primary-title {
    line-height: 2.625rem;
    margin: 1.563rem 0 1.3rem;
  }
  
  @media screen and (max-width: 991px) {
    .service-coupon-section .primary-title {
      font-size: 1.75rem;
      line-height: 2.3rem;
    }
  }
  
  .service-coupon-section .expires-date {
    line-height: 1.313rem;
    margin-bottom: 2.125rem;
  }
  
  .service-coupon-section .additional-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .service-coupon-section .additional-info .logo-wrap {
    margin-right: 2.25rem;
  }
  
  @media screen and (max-width: 991px) {
    .service-coupon-section .additional-info .logo-wrap {
      margin-right: 1.3rem;
    }
  }
  
  .service-coupon-section .additional-info .logo-wrap img {
    max-width: 66px;
  }
  
  .service-coupon-section .additional-info p {
    max-width: 555px;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.375rem;
  }
  
  .service-coupon-section .content-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    text-align: center;
  }
  
  @media screen and (max-width: 767px) {
    .service-coupon-section .content-wrap {
      padding-top: 2.5rem;
    }
  }
  
  .service-coupon-section .content-wrap .img-fluid {
    margin: 0 auto;
  }
  
  @media screen and (max-width: 767px) {
    .service-coupon-section .content-wrap .img-fluid {
      margin-bottom: 1.875rem;
      max-width: 280px;
    }
  }
  
  .service-coupon-section .content-wrap .primary-btn {
    min-width: auto;
  }
  
  .service-coupon-section .btn-group {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    list-style-type: none;
  }
  
  @media screen and (max-width: 1199px) {
    .service-coupon-section .btn-group {
      display: block;
    }
  }
  
  .service-coupon-section .btn-group li {
    display: inline-block;
  }
  
  @media screen and (max-width: 1199px) {
    .service-coupon-section .btn-group li:first-child {
      display: block;
    }
  }
  
  .service-coupon-section .btn-group li:nth-child(2) {
    margin-right: 0.25rem;
  }
  
  .service-coupon-section .phone-number {
    margin-right: 0.7rem;
    display: inline-block;
    color: #000000;
  }
  
  @media screen and (max-width: 1199px) {
    .service-coupon-section .phone-number {
      margin-bottom: 1.3rem;
      margin-right: 0;
    }
  }
  
  .service-coupon-section .phone-number img {
    margin-right: 0.3rem;
    vertical-align: bottom;
  }
  
  .service-coupon-section .phone-number:hover, .service-coupon-section .phone-number:focus {
    color: #000000;
  }
  
  /*-- ==========================================
        Service Coupon Section End
  ============================================= --*/