.scheduler-modal {
    padding: 3rem 0;
}

@media only screen and (max-width: 1025px) {
    .scheduler-modal {
        padding-right: 0 !important;
    }
}

.scheduler-modal .modal-dialog {
    max-width: 1025px;
    margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
    .scheduler-modal .modal-dialog {
        max-width: 90%;
    }
}

@media only screen and (max-width: 900px) {
    .scheduler-modal .modal-dialog {
        max-width: 94%;
    }
}

.scheduler-modal .modal-dialog .modal-content {
    padding: 2rem 6rem;
}

@media only screen and (max-width: 1200px) {
    .scheduler-modal .modal-dialog .modal-content {
        padding: 2rem 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .modal-dialog .modal-content {
        padding: 2rem 0.5rem;
    }
}

.scheduler-modal .modal-dialog .modal-header {
    border-bottom: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 0;
    max-width: 650px;
    margin: 0 auto;
}

.scheduler-modal .modal-dialog .modal-header .sub-heading {
    margin: 1rem 0 1.3rem 0;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .modal-dialog .modal-header .sub-heading {
        font-size: 0.875rem;
    }
}

.scheduler-modal .modal-dialog .close {
    position: absolute;
    font-size: 2.8rem;
    line-height: .5;
    opacity: 1;
    width: 2.5rem;
    height: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #DA291C;
    border-radius: 50%;
    color: #DA291C;
    z-index: 9;
    font-weight: normal;
    padding: 0;
    top: 0;
    right: -3rem;
}

@media only screen and (max-width: 1200px) {
    .scheduler-modal .modal-dialog .close {
        right: 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .modal-dialog .close {
        width: 2rem;
        height: 2rem;
    }
}

.scheduler-modal .modal-dialog .close span {
    display: inline-block;
    font-size: 2.5rem;
}

@media only screen and (max-width: 991px) {
    .scheduler-modal .modal-dialog .close span {
        margin-left: 0;
        font-size: 2rem;
    }
}

.scheduler-modal .modal-dialog .close:hover,
.scheduler-modal .modal-dialog .close:focus {
    opacity: 1;
}

.scheduler-modal .btn-row {
    max-width: 615px;
    margin: 1.5rem auto;
}

.scheduler-modal .btn-row .scheduler-btn {
    min-width: 6.25rem;
    font-size: 0.8125rem;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .btn-row .scheduler-btn {
        min-width: 3rem;
    }
}

.scheduler-modal .btn-row .scheduler-btn.back {
    float: left;
}

.scheduler-modal .btn-row .scheduler-btn.back img {
    display: inline-block;
    position: relative;
    top: -1px;
    left: -4px;
}

.scheduler-modal .btn-row .scheduler-btn.next {
    float: right;
}

.scheduler-modal .btn-row .scheduler-btn.next img {
    display: inline-block;
    position: relative;
    top: -1px;
    left: 4px;
}

.scheduler-modal .call-details-text {
    text-align: center;
}

@media only screen and (max-width: 460px) {
    .scheduler-modal .call-details-text {
        padding: 0;
    }
}

.scheduler-modal .call-details-text .call-us-text {
    display: block;
    color: #666666;
}

.scheduler-modal .call-details-text a {
    display: inline-block;
    font-family: 'Inter Bold';
    font-weight: 500;
    white-space: nowrap;
}

.scheduler-modal .call-details-text a:hover,
.scheduler-modal .call-details-text a:focus {
    color: #DA291C;
    text-decoration: underline;
}

@media screen and (max-width: 767px) {
    .scheduler-modal .call-details-text a {
        font-size: 1rem;
    }
}

@media screen and (max-width: 360px) {
    .scheduler-modal .call-details-text a {
        font-size: 0.8125rem;
    }
}

.scheduler-modal .call-details-text img {
    width: 0.8125rem;
    margin-right: 0.3125rem;
    vertical-align: text-top;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .call-details-text img {
        width: 0.5625rem;
        height: auto;
        vertical-align: text-bottom;
    }
}

.scheduler-modal .neighborly-stamp-wrap {
    width: 100%;
    min-height: 4.6875rem;
    text-align: center;
}

.scheduler-modal .neighborly-stamp-wrap .neighborly-stamp {
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: inline-block;
}

.scheduler-modal .neighborly-stamp-wrap .neighborly-stamp img {
    width: 4.0625rem;
    height: auto;
}

.scheduler-modal .fieldset-title {
    margin-bottom: 0;
    font-size: 1.1875rem;
    line-height: 2.25rem;
    letter-spacing: 0;
    text-align: center;
    color: #333333;
}

.scheduler-modal .hero-title {
    letter-spacing: 0;
    line-height: 2.25rem;
}

.scheduler-modal .head-title {
    letter-spacing: 0;
    font-size: 2.625rem;
    line-height: 2.875rem;
    margin: 2.5rem 0;
    text-align: center;
    color: #000000;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .head-title {
        font-size: 2rem;
        line-height: 2.175rem;
        margin: 2.8125rem auto;
    }
}

@media only screen and (max-width: 360px) {
    .scheduler-modal .head-title {
        width: 100%;
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
}

.scheduler-modal .sub-heading {
    letter-spacing: 0;
    line-height: 2.25rem;
    margin-bottom: 0;
    text-align: center;
    color: #000000;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .sub-heading {
        line-height: 1.25rem;
        font-size: 0.875rem;
    }
}

.scheduler-modal .sub-heading a {
    text-decoration: underline;
}

.scheduler-modal .sub-heading a:hover,
.scheduler-modal .sub-heading a:focus {
    color: #DA291C;
}

.scheduler-modal .sub-heading strong {
    color: #000000;
}

.scheduler-modal .sub-title {
    margin: 1.25rem 0 0;
    display: inline-block;
    text-align: center;
}

.scheduler-modal form {
    text-align: left;
    margin: 1rem auto 1rem;
    max-width: 615px;
}

.scheduler-modal .header-brand-logo {
    max-width: 249px;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .header-brand-logo {
        max-width: 150px;
        height: auto;
    }
}

.scheduler-modal hr {
    border-top: 1px solid #979797;
    width: 80%;
    margin: 0 auto;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal hr {
        width: 90%;
    }
}

.scheduler-modal .required-field-text {
    display: block;
    margin-bottom: 1.5rem;
    color: #767676;
}

.scheduler-modal .form-control {
    border: 1px solid #979797;
    min-height: 3rem;
    font-size: 0.875rem;
    color: #333333;
    border-radius: 6px;
    font-family: 'Inter Regular';
}

.scheduler-modal .form-control::-webkit-input-placeholder {
    color: #333333;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Inter Medium';
}

.scheduler-modal .form-control:-ms-input-placeholder {
    color: #333333;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Inter Medium';
}

.scheduler-modal .form-control::-ms-input-placeholder {
    color: #333333;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Inter Medium';
}

.scheduler-modal .form-control::placeholder {
    color: #333333;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Inter Medium';
}

.scheduler-modal label {
    font-size: 0.875rem;
    color: #333333;
    letter-spacing: 0;
    font-weight: 400;
}

.scheduler-modal .search-services-form {
    max-width: 540px;
    margin: 2.5rem auto 2.3rem;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .search-services-form {
        max-width: 400px;
        margin: 1.5rem auto 2.3rem;
    }
}

.scheduler-modal .search-services-form .input-group {
    font-weight: 500;
    border-radius: 4px;
    margin-top: 0.625rem;
}

.scheduler-modal .search-services-form .input-group .form-control {
    min-height: 2.5rem;
}

.scheduler-modal .search-services-form label {
    font-size: 1rem;
    color: #0D0D0D;
}

.scheduler-modal .search-services-form .radio-group {
    text-align: center;
    margin-bottom: 1.5rem;
}

.scheduler-modal .search-services-form .radio-group p {
    margin-bottom: 0.5rem;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .search-services-form .radio-group .radio-group-wrap {
        max-width: 130px;
        margin: 0 auto;
    }
}

.scheduler-modal .search-services-form .search-input-wrap {
    position: relative;
}

.scheduler-modal .location-services {
    max-width: 625px;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .location-services {
        max-width: 340px;
        margin: 0 auto;
        border-bottom-left-radius: 0;
    }
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .location-services .primary-btn {
        width: 100%;
        margin-top: 1.5625rem;
        margin-bottom: 3.125rem;
    }
}

@media only screen and (max-width: 360px) {
    .scheduler-modal .location-services .primary-btn {
        min-width: auto;
    }
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .location-services label {
        font-size: 0.875rem;
    }

    .scheduler-modal .location-services .services-list-dd {
        position: initial;
    }
}

.scheduler-modal .location-services .location-search img {
    margin: 0 0.9375rem;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .location-services .location-search img {
        margin: 0 0.625rem 0 0;
    }
}

.scheduler-modal .location-services .location-search .services-list-dd li {
    padding: 0.7rem 1.5625rem 0 1rem;
}

.scheduler-modal .location-services .location-search .services-list-dd li:last-child {
    padding-bottom: 0.75rem;
}

.scheduler-modal .location-services .location-search .services-list-dd li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .location-services .search-input-wrap {
        margin-bottom: 1rem;
    }
}

.scheduler-modal .comments-input-text {
    min-height: 125px;
}

.scheduler-modal .center-align-input {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    margin-top: 0.75rem;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .center-align-input {
        margin-bottom: 1rem;
        height: auto;
    }
}

.scheduler-modal .radio-group {
    margin: 1rem 0;
}

.scheduler-modal .radio-group .form-check {
    display: inline-block;
}

.scheduler-modal .radio-group .form-check:first-child {
    padding-left: 0;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .radio-group .form-check {
        display: block;
        padding-left: 0;
        text-align: left;
    }
}

.scheduler-modal .radio-group .custom-radio {
    margin-left: 3rem;
}

.scheduler-modal .radio-group .custom-radio:first-child {
    margin-left: 0;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .radio-group .custom-radio {
        margin-left: 0;
    }
}

.scheduler-modal .contact-radio-group .form-check {
    display: block;
    padding-left: 0;
}

.scheduler-modal .contact-radio-group p {
    margin-bottom: 0.5rem;
    color: #333333;
}

.scheduler-modal .contact-radio-group .radio-txt,
.scheduler-modal .contact-radio-group .custom-radio input[type="radio"]:checked~.radio-txt {
    color: #333333;
}

.scheduler-modal .service-search-submit[type="submit"] {
    background: no-repeat center/2rem url(/us/en-us/mr-handyman/_assets/images/searchIcon-red.svg);
    padding: 0 1.8rem;
}

.scheduler-modal .search-input-group .form-control {
    font-size: 0.875rem;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .service-request-details {
        text-align: left;
    }
}

.scheduler-modal .service-request-details p {
    display: block;
    margin: 1rem 0;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .service-request-details p {
        font-size: 0.875rem;
    }
}

.scheduler-modal .service-request-details .radioTxt {
    font-size: 0.9375rem;
}

.scheduler-modal .service-request-details .cleaning-details {
    display: none;
}

.scheduler-modal .service-request-details .cleaning-details strong {
    margin: 0.5rem 0 1rem;
}

.scheduler-modal .service-request-details .frequency-form-check {
    max-width: 200px;
    margin: 0 auto;
    text-align: left;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .service-request-details .frequency-form-check {
        max-width: 100%;
    }
}

.scheduler-modal .service-request-details .frequency-form-check .form-check {
    display: block;
    padding-left: 0;
}

.scheduler-modal .services-links-cont {
    margin: 1.3rem 0 1rem;
}

.scheduler-modal .services-links-cont p {
    margin-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .services-links-cont p {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .services-link-list {
        margin-bottom: 0;
    }
}

.scheduler-modal .services-link-list li {
    margin-bottom: 1.35rem;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .services-link-list li {
        margin-bottom: 0.5rem;
    }
}

.scheduler-modal .services-link-list a {
    font-size: 0.875rem;
    color: #333333;
    letter-spacing: 0;
    line-height: 1.125rem;
    font-weight: 500;
    font-family: 'Inter Medium';
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .services-link-list a {
        line-height: 1.875rem;
    }
}

.scheduler-modal .content-wrap {
    text-align: left;
    margin: 1rem auto 3rem;
    color: #002554;
    max-width: 615px;
}

.scheduler-modal .content-wrap .list-heading {
    font-size: 1.125rem;
    letter-spacing: 0;
    color: #002f65;
    margin: 1.4rem 0 0.5rem 0;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .content-wrap .list-heading {
        font-size: 1rem;
    }
}

.scheduler-modal .content-wrap p,
.scheduler-modal .content-wrap h3,
.scheduler-modal .content-wrap h2,
.scheduler-modal .content-wrap strong {
    line-height: 1.5rem;
}

@media only screen and (max-width: 767px) {

    .scheduler-modal .content-wrap p,
    .scheduler-modal .content-wrap h3,
    .scheduler-modal .content-wrap h2,
    .scheduler-modal .content-wrap strong {
        line-height: 1.125rem;
    }
}

.scheduler-modal .content-wrap .estimation-text {
    margin: 0.2rem 0 1.5rem 0;
    display: inline-block;
    font-size: 2rem;
}

.scheduler-modal .content-wrap .content-text {
    margin-bottom: 0.4rem;
}

.scheduler-modal .content-wrap .list-disc {
    margin-bottom: 1rem;
}

.scheduler-modal .content-wrap .list-disc li {
    color: #002554;
    font-size: 0.875rem;
}

@media only screen and (max-width: 767px) {
    .scheduler-modal .content-wrap .list-disc li {
        font-size: 0.75rem;
        line-height: 1.125rem;
        margin-bottom: 0.375rem;
    }
}

.services-list-dd {
    display: none;
    position: absolute;
    color: #ffffff;
    width: 100%;
    background: #DA291C;
    border-bottom-left-radius: 8px;
    list-style: none;
    margin: 0;
    border-bottom-right-radius: 8px;
    padding: 0;
    z-index: 1;
    top: 2.5rem;
}

.services-list-dd li {
    padding: 0.5rem 1.5625rem 0 1rem;
    text-align: left;
}

.services-list-dd li a {
    color: #ffffff;
    font-size: 0.875rem;
}

@media only screen and (max-width: 767px) {
    .services-list-dd li a {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
}

.services-list-dd li a:hover,
.services-list-dd li a:focus,
.services-list-dd li a:active {
    font-weight: 500;
}

.services-list-dd li:last-child {
    padding-bottom: 1rem;
}

.close-location {
    margin: 3.75rem 0 5rem 0;
}

@media only screen and (max-width: 767px) {
    .close-location {
        margin: 0 0 4.0625rem 0;
    }
}

.close-location .primary-btn {
    min-width: 9.5rem;
}

@media only screen and (max-width: 400px) {
    .state select {
        padding: 0.3125rem;
        min-width: 5rem;
    }
}

.confirmation-page {
    margin-top: 4.0625rem;
    text-align: center;
    margin-bottom: 4.125rem;
}

.confirmation-page .confirmation-data {
    text-align: left;
    max-width: 755px;
    margin: 2rem auto;
}

@media screen and (max-width: 767px) {
    .confirmation-page .confirmation-data {
        text-align: center;
    }
}

.confirmation-page .confirmation-data .sub-title {
    margin: 1.5625rem 0 0;
    display: block;
    text-align: center;
    font-weight: 300;
}

.confirmation-page .confirmation-col {
    padding-left: 6.25rem;
    margin-top: 2rem;
    padding-right: 0;
}

@media screen and (max-width: 767px) {
    .confirmation-page .confirmation-col {
        padding-left: 0;
    }
}

.confirmation-page h2 {
    font-size: 2.8125rem;
    text-align: center;
    color: #333333;
}

@media screen and (max-width: 767px) {
    .confirmation-page h2 {
        font-size: 2rem;
        line-height: 1.2;
    }
}

.confirmation-page h3 {
    font-size: 1.625rem;
}

.confirmation-page dt {
    color: #666666;
}

@media screen and (max-width: 767px) {
    .confirmation-page dt {
        font-size: 1rem;
    }
}

.confirmation-page dd {
    margin-bottom: 1.2rem;
    color: #000000;
}

@media screen and (max-width: 767px) {
    .confirmation-page dd {
        font-size: 1rem;
    }
}

.scheduler-slide {
    z-index: 0;
    border: none;
    position: relative;
}

.scheduler-slide .fieldset {
    background: #ffffff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding: 0;
    text-align: center;
    max-width: 845px;
    margin: 0.375rem auto;
}

.scheduler-slide .search-input-group {
    border-radius: 6px;
}

.scheduler-slide .progressbar {
    margin: 0 auto;
    overflow: hidden;
    color: #D8D8D8;
    position: relative;
    max-width: 635px;
    top: -15px;
    z-index: 0;
}

@media screen and (max-width: 767px) {
    .scheduler-slide .progressbar {
        display: none;
    }
}

.scheduler-slide .progressbar span {
    display: block;
}

.scheduler-slide .progressbar li {
    list-style-type: none;
    width: calc(100%/5);
    float: left;
    position: relative;
    font-weight: 400;
    text-align: center;
    color: #000000;
    text-align: center;
}

.scheduler-slide .progressbar li::before {
    width: 0.875rem;
    height: 0.875rem;
    line-height: 2.8125rem;
    display: block;
    color: #ffffff;
    border: 1px solid #909090;
    border-radius: 50%;
    margin: 0 auto 0.625rem auto;
    content: '';
}

.scheduler-slide .progressbar li:nth-child(3)::before {
    background-color: transparent;
    border: none;
}

.scheduler-slide .progressbar li:last-child::before {
    margin: 0 0 0.625rem 3.25rem;
}

.scheduler-slide .progressbar li a {
    display: inline-block;
    text-align: center;
    font-size: 0.6875rem;
    line-height: 0.875rem;
    color: #767676;
    pointer-events: none;
}

.scheduler-slide .progressbar li a.active {
    color: #000000;
}

.scheduler-slide .progress {
    height: 1rem;
    border-radius: 12px;
    position: relative;
    //max-width: 515px;
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
    .scheduler-slide .progress {
        max-width: 100%;
    }
}

.scheduler-slide .progress-bar {
    background-color: #DA291C;
    position: relative;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    z-index: 1;
}

.scheduler-slide .progress-bar::after {
    position: absolute;
    content: '';
    width: 0.75rem;
    height: 0.75rem;
    background-color: #EFE818;
    right: 0.125rem;
    border-radius: 50%;
}

@media screen and (max-width: 767px) {
    .scheduler-slide .progress-bar {
        padding-left: 1rem;
    }
}

.calendar-wrapper {
    margin-top: 0.75rem;
}

.calendar-wrapper .calendar-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .calendar-wrapper .calendar-cont {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}

.calendar-wrapper .calendar-cont .arrow-btn {
    position: relative;
    background: transparent;
    border: none;
    font-size: 0.75rem;
    color: #818181;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 3.5rem;
}

.calendar-wrapper .calendar-cont .arrow-btn span {
    display: block;
    line-height: normal;
    padding-top: 0.3125rem;
}

@media only screen and (max-width: 500px) {
    .calendar-wrapper .calendar-cont .arrow-btn span {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .calendar-wrapper .calendar-cont .arrow-btn {
        margin-top: 1rem;
    }
}

@media only screen and (max-width: 500px) {
    .calendar-wrapper .calendar-cont .arrow-btn {
        margin-top: 1.3rem;
    }
}

.calendar-wrapper .calendar-cont .arrow-btn img {
    display: block;
}

@media only screen and (max-width: 500px) {
    .calendar-wrapper .calendar-cont .arrow-btn img {
        width: 1rem;
    }
}

@media only screen and (max-width: 767px) {
    .calendar-wrapper .calendar-cont .arrow-btn.prev-btn {
        margin-right: 1rem;
    }
}

@media only screen and (max-width: 500px) {
    .calendar-wrapper .calendar-cont .arrow-btn.prev-btn {
        margin-right: 0.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .calendar-wrapper .calendar-cont .arrow-btn.next-btn {
        margin-left: 1rem;
    }
}

@media only screen and (max-width: 500px) {
    .calendar-wrapper .calendar-cont .arrow-btn.next-btn {
        margin-left: 0.5rem;
    }
}

.calendar-wrapper .calendar-inner-wrap {
    margin: 0 auto;
    width: 100%;
}

.calendar-wrapper .date-details-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 645px;
    margin: 0 auto;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .calendar-wrapper .date-details-wrapper {
        margin-bottom: 2rem;
    }
}

.calendar-wrapper .date-details-wrapper .date-details {
    width: calc(100% / 5);
    margin-bottom: 1.1rem;
    line-height: 1.125rem;
}

@media only screen and (max-width: 767px) {
    .calendar-wrapper .date-details-wrapper .date-details {
        margin-bottom: 0;
        background-color: #D8D8D8;
        margin-left: 1px;
        padding: 0.5rem 0;
    }

    .calendar-wrapper .date-details-wrapper .date-details:first-child {
        margin-left: 0;
    }

    .calendar-wrapper .date-details-wrapper .date-details strong span {
        display: block;
    }

    .calendar-wrapper .date-details-wrapper .date-details strong,
    .calendar-wrapper .date-details-wrapper .date-details span {
        text-transform: uppercase;
        font-size: 0.75rem;
    }

    .calendar-wrapper .date-details-wrapper .date-details.active {
        background-color: #B00006;
        color: #ffffff;
    }
}

@media only screen and (max-width: 400px) {

    .calendar-wrapper .date-details-wrapper .date-details strong,
    .calendar-wrapper .date-details-wrapper .date-details span {
        font-size: 0.6875rem;
    }
}

@media only screen and (max-width: 935px) {
    .calendar-wrapper .date-details-wrapper {
        max-width: 550px;
    }
}

.calendar-wrapper .calendar-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 1.5rem;
    max-width: 645px;
    margin: 0 auto;
}

@media only screen and (max-width: 935px) {
    .calendar-wrapper .calendar-row {
        max-width: 550px;
    }
}

.calendar-wrapper .calendar-row .calendar-col {
    width: calc(100% / 5);
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    margin-left: 0;
    max-width: 120px;
}

@media only screen and (max-width: 767px) {
    .calendar-wrapper .calendar-row .calendar-col {
        display: block;
        max-width: 150px;
    }
}

.calendar-wrapper .calendar-row .calendar-col:not(:first-of-type) {
    margin-left: 0.625rem;
}

@media only screen and (max-width: 767px) {
    .calendar-wrapper .calendar-row .calendar-col:not(:first-of-type) {
        display: none;
    }
}

.calendar-wrapper .calendar-row .calendar-col .calendar-btn {
    margin-bottom: 0.625rem;
    width: 100%;
    min-height: 2.3125rem;
    border-radius: 5px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background-color: #d9291b;
    font-size: 0.75rem;
}

.calendar-wrapper .calendar-row .calendar-col .calendar-btn:disabled {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #797979;
    font-family: 'Inter Regular';
    font-weight: 300;
}

.calendar-wrapper .calendar-row .calendar-col .calendar-btn.active,
.calendar-wrapper .calendar-row .calendar-col .calendar-btn:focus {
    color: #ffffff;
    background-color: #333333;
    border: 1px solid #333333;
}

.calendar-wrapper .date-details {
    text-align: center;
    margin-bottom: 1.25rem;
    line-height: 1.125rem;
    color: #333333;
    background-color: transparent;
    border: none;
}

.calendar-wrapper .date-details p {
    margin-bottom: 0;
}

.calendar-wrapper .date-details strong {
    display: block;
}

.calendar-wrapper .date-details.selected-date {
    margin: 2rem 0 3rem 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .calendar-wrapper .date-details.selected-date {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.calendar-wrapper .date-details.selected-date p {
    margin-bottom: 0.5rem;
    color: #333333;
    padding-right: 0.625rem;
}

.calendar-wrapper .date-details.selected-date .selected-date-txt {
    color: #333333;
}

.calendar-wrapper .calendar-sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.stamp-popover-open .popover {
    background-color: #FFC733;
    max-width: 588px;
    width: 90%;
    padding: 1.5625rem 1.25rem;
    min-height: 220px;
    border-radius: 8px;
}

@media only screen and (max-width: 767px) {
    .stamp-popover-open .popover {
        width: 80%;
        padding: 0.625rem;
    }
}

.stamp-popover-open .popover-header {
    background-color: inherit;
    border-bottom: none;
    position: relative;
}

.stamp-popover-open .popover-header .popover-close-icon {
    text-decoration: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 0.3125rem;
    color: #002f65;
    cursor: pointer;
    font-family: 'Inter Bold';
    font-weight: 500;
    margin-right: 0;
}

.stamp-popover-open .popover-header .popover-close-icon::after {
    content: '\2716';
    font-size: 1.125rem;
}

.stamp-popover-open .popover-header h2 {
    font-size: 1.3125rem;
    color: #142651;
    letter-spacing: 0;
    line-height: 1.6875rem;
}

.stamp-popover-open .popover-header strong {
    font-size: 1.3125rem;
    color: #002f65;
    letter-spacing: 0;
    line-height: 1.6875rem;
    font-family: 'Inter Medium';
}

.stamp-popover-open .popover-header p {
    font-size: 1rem;
    color: #142651;
    letter-spacing: 0;
    line-height: 1.5rem;
}

.stamp-popover-open .popover-header a {
    color: #002f65;
    letter-spacing: 0;
    font-size: 1rem;
    text-decoration: none;
}

.stamp-popover-open .popover-body a {
    color: #142651;
}

.stamp-popover-open .bs-popover-auto[x-placement^=bottom] .arrow::after,
.stamp-popover-open .bs-popover-bottom .arrow::after {
    top: 1px;
    border-bottom-color: #FFC733;
}

.stamp-popover-open .bs-popover-auto[x-placement^=bottom] .arrow::before,
.stamp-popover-open .bs-popover-bottom .arrow::before {
    top: 0;
    border-bottom-color: #ffc733;
}

.stamp-popover-open .bs-popover-auto[x-placement^=bottom] .popover-header::before,
.stamp-popover-open .bs-popover-bottom .popover-header::before {
    border-bottom: 1px solid #FFC733;
}

.fade.in {
    opacity: 1;
}

.scheduler-modal .modal-header-one {
    display: none;
}

.scheduler-modal .modal-header-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.scheduler-modal .modal-header-two .result-Stack-logo img {
    width: 10.25rem;
    height: auto;
}

@media only screen and (max-width: 500px) {
    .scheduler-modal .modal-header-two .result-Stack-logo img {
        width: 7.5rem;
    }
}

@media only screen and (max-width: 360px) {
    .scheduler-modal .modal-header-two .result-Stack-logo img {
        width: 6.25rem;
    }
}

.scheduler-modal .banner-wizard .modal-header-one {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.scheduler-modal .banner-wizard .modal-header-two {
    display: none;
}

.scheduler-modal .banner-wizard .progressbar {
    display: none;
}

.scheduler-modal .banner-wizard .progress {
    display: none;
}

.global-scheduler .scheduler-slide ul {
    display: none;
}

.global-scheduler .modal-header-one {
    display: block;
}

@media screen and (max-width: 767px) {
    .attach-input {
        min-height: 2rem;
        line-height: 2.25rem;
    }
}

.attach-input input {
    padding-top: 4px;
    opacity: 0;
    width: 80%;
}

.service-head {
    margin-bottom: 3rem;
    font-family: 'Inter Medium';
}

@media screen and (max-width: 767px) {
    .service-head {
        margin-bottom: 2rem;
    }
}

.service-head span {
    color: #1E1E1E;
}

.services-link-content {
    max-width: 80%;
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
    .services-link-content {
        max-width: 100%;
    }
}

.services-link-content .sub-heading {
    font-size: 1.75rem;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    margin-bottom: 1.75rem;
    font-family: 'Inter Medium';
    color: #1E1E1E;
    line-height: normal;
}

@media screen and (max-width: 767px) {
    .services-link-content .sub-heading {
        font-size: 1.25rem;
    }
}

.change-zip:hover,.change-zip{color:#000000 !important;}


