//font weight
$regularFont: 400;
$boldFont: 500;
$bolderFont: 900;
//basic theme colors
$primary:#EE3831;
$secondary:#B00006;
//scheduler color
$primaryYellow:#FFC733;

//grey
$grey:#0D0D0D;
$darkGrey: #4e4e4e;
$lightGrey: #979797;
$primaryGrey:#666666;
$secondaryGrey:#cccccc;
$tertiaryGrey:#1D1D1D;
$lightGreyPrimary: #f0f0f0;
$lightGreySecondary: #F3F3F3;
$darkGreyPrimary: #323232;
$darkGreySecondary: #383838;
$darkestGrey: #333333;

//blue
$info:#497CC2;
$darkBlue: #142651;
$blue: #002f65;

//White and black
$white: #ffffff;
$black:#000000;

//red
$lightRed: #DA291C;












