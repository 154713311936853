@import "partials/variable";
@import "partials/header";
@import "partials/footer";
* {
    margin: 0;
    padding: 0;
    letter-spacing: 0;
}

html {
    font-size: 16px;
}

//Removed the opacity 0 from the body US 351807

body.wf-loading {
    opacity: 1;
}

section {
    border-bottom: 10px solid #e3e3e3;
}
/*-- ================================
        font-face Inter
===================================== */
@font-face {
    font-family: 'Inter Regular';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Inter Regular'), url('/us/en-us/brand/_assets/fonts/Inter-Regular.woff') format('woff');
  }
    
  @font-face {
    font-family: 'Inter Medium';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Inter Medium'), url('../fonts/Inter-Medium.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Inter Semi Bold';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Inter Semi Bold'), url('../fonts/Inter-SemiBold.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Inter Bold';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Inter Bold'), url('../fonts/Inter-Bold.woff') format('woff');
  }
  
  .medium-font {
    font-family: 'Inter Medium';
    font-weight: 500;
  }
  
  .bold-font {
    font-family: 'Inter Bold';
    font-weight: 500;
  }
  
  /*-- ================================
        font-face Inter End
===================================== */

  /*-- ================================
          Global Basic css Start
  ===================================== */
  body {
    font-family: 'Inter Regular','Arial', 'Helvetica', sans-serif;
    font-weight: 300;
    line-height: 1.5;
  }
  
  strong {
    font-family: 'Inter Bold';
    font-weight: 500;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Inter Bold';
    font-weight: 500;
    line-height: 1.35;
  }
  p, a, input, button{
    font-family: 'Inter Regular';
  }
a,
button,
.btn {
    &:focus {
        outline: 2px solid $darkBlue;
        outline-offset: 2px;
        box-shadow: none;
        border: 0.1px solid transparent;
    }
}

a {
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
img{
    max-width: 100%;
    height: auto;
}
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $primaryGrey; //#666666;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $primaryGrey; //#666666;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: $primaryGrey; //#666666;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: $primaryGrey; //#666666;
}

.form-control {
    border-radius: 0;
    font-family: 'Inter Regular';
}

textarea {
    resize: none;
}

.form-control:focus,
input[type="text"]:focus,
input[type="submit"]:focus {
    outline: 2px solid $darkBlue;
    outline-offset: 2px;
    box-shadow: none;
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    max-width: 1200px;
}


/*-- ================================
        Global Basic css End
===================================== */


/*-- ================================
        Classes defined Start
===================================== */

//white color & bg
.white-clr {
    color: $white;
}

.white-bg {
    background-color: $white; //#ffffff
}

//black color & bg
.black-clr {
    color: $black; //#000000
}

.black-bg {
    background-color: $black;
}

//grey color & bg
.grey-clr {
    color: $grey; //#0D0D0D
}

.primary-grey-clr {
    color: $primaryGrey; //#666666
}

.secondary-grey-clr {
    color: $tertiaryGrey; //#1D1D1D 
}

.light-grey-primary-bg {
    background-color: $lightGreyPrimary; //#f0f0f0
}

.light-grey-secondary-bg {
    background-color: $lightGreySecondary; //#F3F3F3
}

.dark-grey-primary-bg {
    background-color: $darkGreyPrimary; //#323232;
}

.dark-grey-primary-clr {
    color: $darkGrey;
}

.darkest-grey-clr {
    color: $darkestGrey;
}

//blue color & bg
.info-clr {
    color: $info; //#497CC2
}

.info-bg {
    background-color: $info; //#497CC2
}

// MRH: Brands :: color and BG color classes & some other comon Style
.primary-theme-bg {
    background-color: $lightRed;
}

.primary-theme-clr {
    color: $lightRed;
}

.primary-btn {
    background-color: $lightRed;
    color: $white;
    text-align: center;
    border-radius: 0.5rem;
    border: 0;
    font-size: 1.1875rem;
    line-height: 2.8125rem;
    min-width: 15rem;
    min-height: 2.8125rem;
    padding: 0 1.25rem;
    display: inline-block;
    font-family: 'Inter Medium';
    font-weight: $boldFont;
    @media screen and (max-width: 767px) {
        font-size: 0.875rem;
        padding: 0 0.5rem;
    }
    &:hover,
    &:focus,
    &:active {
        background-color: $secondary;
        color: $white;
        text-decoration: none;
    }
    &:focus {
        outline: 2px solid $darkBlue;
        box-shadow: none;
        outline-offset: 2px;
    }
}

.secondary-btn {
    background-color: $white;
    color: $lightRed;
    text-align: center;
    border-radius: 0.5rem;
    border: 0;
    font-size: 1.1875rem;
    line-height: 2.8125rem;
    min-width: 15rem;
    min-height: 2.8125rem;
    padding: 0 1.25rem;
    display: inline-block;
    font-family: 'Inter Medium';
    font-weight: $boldFont;
    @media screen and (max-width: 767px) {
        font-size: 0.875rem;
        padding: 0 1rem;
    }
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        background-color: #FFFDB7;
        color: $lightRed;
    }
    &:focus {
        outline: 2px solid $white;
        box-shadow: none;
        outline-offset: 2px;
    }
}

.call-icon {
    position: relative;
    &::before {
        width: 0.8125rem;
        height: 2rem;
        background: url(../images/phone.svg) no-repeat left top;
        content: '';
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        margin-right: 0.625rem;
    }
}


/*-- ================================
        Classes defined End
===================================== */


/*-- ================================
        font-size Start
===================================== */

.text-xlg {
    font-size: 2.25rem;
}

.text-xl {
    font-size: 1.875rem;
}

.text-lg {
    font-size: 1.3125rem;
}

.text-md {
    font-size: 1.25rem;
}

.text-sm {
    font-size: 1.125rem;
}

.body-text {
    font-size: 1rem;
}

.body-text-sm {
    font-size: 0.875rem;
}

.text-xs {
    font-size: 0.83rem;
}


/*-- ================================
        font-size End
===================================== */


/*-- ================================
        Titles Start
===================================== */

.primary-title {
    letter-spacing: 0;
    line-height: 3rem;
    font-weight: $boldFont;
}

.secondary-title {
    letter-spacing: 0;
    line-height: 1.625rem;
    font-weight: $boldFont;
}

.tertiary-title {
    letter-spacing: 0;
    line-height: 1.375rem;
    font-weight: $boldFont;
}


/*-- ================================
        Titles End
===================================== */

.align-center-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

//.custom-radio-box
.custom-radio-primary {
    text-transform: capitalize;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 1.625rem;
    cursor: pointer;
    .radio-circle {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid #b2b2b2;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        &::after {
            content: '';
            height: 0.5rem;
            width: 0.5rem;
            background: $primary;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            border-radius: 50%;
            transition: all 0.2s;
        }
    }
    input[type='radio'] {
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        cursor: pointer;
        &:checked~.radio-circle {
            border: 1px solid $primary;
            &::after {
                transform: translate(-50%, -50%) scale(1);
            }
        }
        &:focus {
            +.radio-circle {
                outline: 2px solid $darkBlue;
                outline-offset: 2px;
            }
        }
    }
}

//.custom-radio
.custom-radio-secondary {
    text-transform: capitalize;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 1rem;
    color: $tertiaryGrey;
    letter-spacing: 0;
    input[type="radio"] {
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        cursor: pointer;
        &:checked~.radio-circle {
            background: $white;
            border: 1px solid #B2B2B2;
            box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, 0.06);
        }
        &:checked~span.radio-circle:after {
            transform: translate(-50%, -50%) scale(1);
        }
        &:checked~.radio-txt {
            color: $darkestGrey;
        }
        &:focus {
            +.radio-circle {
                outline: 2px solid $darkBlue;
                outline-offset: 2px;
            }
        }
    }
    .radio-txt {
        font-size: 0.875rem;
        color: $tertiaryGrey;
        letter-spacing: 0;
        line-height: 2.25rem;
    }
    .radio-circle {
        height: 1.375rem;
        width: 1.375rem;
        border-radius: 50%;
        background: $white;
        border: 1px solid #b2b2b2;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
        display: block;
        position: absolute;
        left: 0;
        top: 7px;
        &::after {
            content: "";
            height: 0.9375rem;
            width: 0.9375rem;
            background: #A3A3A3;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            border-radius: 50%;
            transition: all 0.2s;
        }
    }
}

.custom-checkbox {
    position: relative;
    display: flex;
    input {
        padding: 0;
        margin-bottom: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        cursor: pointer;
    }
    label {
        position: relative;
        cursor: pointer;
        clear: both;
        line-height: normal;
        height: auto;
        display: flex;
        align-items: center;
        &::before {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 1px solid $darkGrey;
            padding: 0.75rem;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 0.75rem;
            border-radius: 4px;
            width: 22px;
            height: 22px;
            float: left;
        }
    }
    input:checked+label::after {
        content: '';
        display: block;
        position: absolute;
        left: 0.5625rem;
        width: 0.5rem;
        height: 1rem;
        border: solid $black;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        margin-top: -3px;
    }
    input {
        &:focus {
            +label::before {
                outline: 2px solid $darkBlue;
                outline-offset: 2px;
            }
        }
    }
}

.list-disc {
    margin-left: 1rem;
    li {
        font-size: 1rem;
        letter-spacing: 0;
        line-height: 1.625rem;
    }
}

.search-input-group {
    border: 0.0625rem solid #979797;
    margin-bottom: 0;
    height: 100%;
    input[type="search"] {
        border: none;
        padding: 0 0 0 0.75rem;
        height: 1.8125rem;
        font-size: 0.6875rem;
        color: $primaryGrey;
        letter-spacing: 0;
        line-height: 1.5rem;
        border: 2px solid transparent;
        &::placeholder {
            font-size: 0.875rem;
            color: $primaryGrey;
            letter-spacing: 0;
            line-height: 1.5rem;
            font-family: 'Inter Regular';
        }
        &:focus {
            box-shadow: none;
            border: 2px solid $darkBlue;
            outline: none;
            outline-offset: 0;
        }
    }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }
    input[type="submit"] {
        height: auto;
        border: none;
        border-left: 0;
        background: no-repeat center/0.75rem url(../images/searchIcon.svg);
        background-color: $white;
        padding: 0 0.625rem 0 1.5625rem;
        &:focus {
            box-shadow: none;
            border: 1px solid $darkBlue;
            outline: none;
            border-radius: 0;
            outline-offset: 0;
        }
    }
}

.link-text {
    letter-spacing: 0;
    font-weight: $boldFont;
    transition: all 0.2s;
    position: relative;
    display: inline-block;
    margin-top: 1rem;
    font-family: 'Inter Bold';
    border: 1px solid transparent;
    &:hover,
    &:focus {
        color: $secondary;
    }
    @media only screen and (max-width: 767px) {
        // margin-bottom: 1.5rem;
        font-size: 1rem;
        line-height: 2.25rem;
        margin-top: 0;
    }
    &::before {
        content: "";
        width: 21px;
        height: 21px;
        background: url(../images/red-arrow.svg) no-repeat left top;
        background-size: 21px;
        vertical-align: text-top;
        display: inline-block;
        margin-right: 0.625rem;
    }
    &:hover,
    &:focus {
        color: $secondary;
        &::before {
            background: url(../images/red-hover.svg) no-repeat left top;
            background-size: 21px;
        }
    }
    &.blue-arrow {
        &::before {
            background: url(../images/blue-arrow.svg) no-repeat left top;
        }
    }
    &.white-arrow {
        &::before {
            background: url(../images/white-arrow.svg) no-repeat left top;
        }
    }
}

// Additional CSS for MAC Screen
.mac .scheduler-modal .close span {
    height: 27px;
}

.search-services-form .search-input-group input[type="search"]::placeholder {
    line-height: 2.25rem;
}

.btn-row {
    margin-top: 3.75rem;
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
}

/*-- ===================================
      CSS for common components start
======================================= --*/

.left-text-wrap {
    .col-row {
        flex-direction: row;
    }
    &.mob-reverse-col {
        .col-row {
            @media screen and (max-width: 767px) {
                flex-direction: column-reverse;
            }
        }
    }
    .text-container {
        padding-right: 3.125rem;
        @media screen and (max-width: 991px) {
            padding-right: 2rem;
        }
        @media screen and (max-width: 767px) {
            padding-right: 1rem;
        }
    }
}

.right-text-wrap {
    .col-row {
        flex-direction: row-reverse;
    }
    &.mob-reverse-col {
        .col-row {
            @media screen and (max-width: 767px) {
                flex-direction: column-reverse;
            }
        }
    }
    .text-container {
        padding-left: 3.125rem;
        @media screen and (max-width: 991px) {
            padding-left: 2rem;
        }
        @media screen and (max-width: 767px) {
            padding-left: 0;
            padding-right: 1rem;
        }
    }
}

//fifty fifty section
.fifty-fifty-section {	
    padding:5rem 0;	
    @media screen and (max-width: 767px) {	
        padding:2.80rem 0;	
    }
    &.left-text-wrap .col-row {
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }
    .right-wrap {
        @media screen and (max-width: 767px) {
            margin-top: 2.5rem;
        }
    }
    .video-container {
        padding: 0;
        padding-right: 1.85rem;
        @media screen and (max-width: 767px) {
            padding-right: 0;
        }
        img {
            width: 100%;
        }
    }
    .primary-title {	
        margin-bottom: 2rem;	
        margin-left: 0;	
        @media screen and (max-width: 767px) {	
            margin-left: 0;	
            font-size: 1.5rem;	
            margin-bottom: 1.5rem;	
            line-height: 1.75rem;	
        }	
    }
    .link-text {
        margin-left: 0;
    }
    ul {
        list-style-type: none;
        li {
            margin-bottom: 1rem;
            .inner-desc {
                margin-left: 1rem;
            }
            h3 {
                letter-spacing: 0;
                line-height: 1.375rem;
                font-weight: $boldFont;
            }
            p {
                letter-spacing: 0;
                line-height: 1.625rem;
                font-weight: $regularFont;
                margin-bottom: 0;
            }
        }
    }
    .media-left {
        margin-right: 1.25rem;
        min-width: 3.125rem;
        text-align: center;
        margin-left: 0;
    }
    .media {
        align-items: center;
        .withborder-img img {
            border-radius: 50%;
            border: 1px $secondary solid;
            padding: 13%;
            width: 3.125rem;
            height: 3.125rem;
        }
        .withoutborder-img img {
            width: 1.875rem;
        }
    }
}

//hero banner section
.hero-title {
    font-size: 3rem;
    @media screen and (max-width: 767px) {
        font-size: 2rem;
    }
}

.primary-hero-banner {
    background-color: #574847;
    .banner-row {
        position: relative;
        .hero-main-img {
            @media screen and (max-width: 767px) {
                height: 550px;
                overflow: hidden;
            }
        }
        .banner-img-container {
            height: 100%;
        }
        img {
            max-width: 100%;
            width: 100%;
            @media screen and (max-width: 767px) {
                height: 100%;
            }
        }
    }
    .banner-sec {
        .banner-text-sec {
            position: absolute;
            width: 100%;
            background: rgba(255, 255, 255, 0.85);
            padding: 1.575rem 3.55rem 3.125rem 0;
            max-width: 61.4%;
            flex-direction: column;
            top: 50%;
            left: 0;
            -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
            @media screen and (max-width: 1200px) {
                font-size: 2.25rem;
                letter-spacing: 0;
                line-height: 2.875rem;
                padding: 1.2rem 1.5rem 2.2rem;
                max-width: 90%;
            }
            @media screen and (max-width: 767px) {
                padding: 1.2rem .5rem 1.6rem;
            }
        }
        p {
            line-height: 1.625rem;
            font-weight: $boldFont;
            margin-bottom: 1.55rem;
            margin-left: 0.9375rem;
            letter-spacing: -0.19px;
            max-width: 90%;
            font-family: 'Inter Medium';
            @media screen and (max-width: 767px) {
                margin-bottom: 1rem;
                line-height: 1.5rem;
                font-size: 1.125rem;
            }
        }
    }
    .banner-text {
        font-weight: $boldFont;
        margin-bottom: 0.85rem;
        margin-left: 0.75rem;
        letter-spacing: -0.44px;
        line-height: 3.75rem;
        color: $black;
        text-transform: capitalize;
        @media screen and (max-width: 767px) {
            font-size: 2.25rem;
            letter-spacing: 0;
            line-height: normal;
            margin-bottom: 0.8rem;
        }
    }
    .navbar-form {
        display: inline-flex;
        width: 100%;
        //height: 2.8125rem;
        vertical-align: top;
        margin-left: 0.9375rem;
        @media screen and (max-width: 991px) {
            max-width: 95%;
        }
        @media screen and (max-width: 767px) {
            flex-direction: column;
            max-width: 90%;
        }
        .form-control {
            display: inline-block;
            width: 100%;
            height: 2.8125rem;
            padding: 0.9375rem;
            line-height: 1.125rem;
            max-width: 240px;
            border: 1px solid $blue;
            margin-right: 1.5rem;
            @media screen and (max-width: 767px) {
                max-width: 100%;
            }
        }
        input[type="submit"] {
            // margin-left: 1.5rem;
            min-width: 16.3125rem;
            font-size: 1.125rem;
            @media only screen and (max-width: 767px) {
                width: 100%;
                margin: 0.9375rem 0 0.3125rem 0;
                font-size: 0.875rem;
                max-width: 100%;
            }
            @media only screen and (max-width:480px) {
                min-width: 100%;
            }
        }
    }
}

//error msg
.error {
    label {
        color: #DE0000 !important;
    }
    .form-control {
        margin-right: 0.625rem;
        border-width: 2px !important;
        border-color: #DE0000 !important;
        background: #FFF1F1 url(../images/error-icon.png) no-repeat 96%;
    }
    .error-msg {
        &.show {
            color: #DE0000;
            display: inline-block;
            font-size: 0.875rem;
        }
    }
}
.error-msg {display: none;}

/*-- ===================================
      CSS for common components start
======================================= --*/

/*-- =================================================
        Common style for services-list-section Start
====================================================== -- */
.services-list-section {	
    padding: 4.25rem 0 3.85rem;	
    @media screen and (max-width: 767px) {	
        padding:2.5rem 0 1.25rem 0;	
    }
    .primary-title {
        margin-bottom: 2rem;
        @media screen and (max-width: 767px) {
            font-size: 1.5rem;
            max-width: 80%;
            margin: 0 auto 1.375rem;
            line-height: 1.75rem;
        }
    }
    .services-list {
        list-style: none;
        margin-left: 2rem;
        @media screen and (max-width: 767px) {
            margin-left: 2.8rem;
        }
        a {
            line-height: 1.375rem;
            margin-bottom: 0.7rem;
            display: inline-block;
            font-weight: $boldFont;
            &:hover,
            &:focus {
                color: $secondary;
                text-decoration: underline;
            }
        }
    }
}
/*-- =================================================
        Common style for services-list-section End
====================================================== -- */


/*-- =================================================
        Common style for services-wrapper Start
====================================================== -- */
.services-wrapper {	
    padding: 4.25rem 0 4.75rem;	
    @media screen and (max-width: 767px) {	
        padding:2.8rem 0 2.3rem;	
    }
    &.left-text-wrap .col-row {
        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }
    &.right-text-wrap {
        .col-row {
            @media screen and (max-width: 767px) {
                flex-direction: column;
            }
        }
        .left-wrap {
            padding-right: 0.9375rem;
            padding-left: 3.5rem;
        }
        .text-container {
            padding-left: 0;
        }
    }
    .left-wrap {
        padding-right: 3.5rem;
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 2.1875rem;
            padding: 0;
        }
        .img-section {
            position: relative;
            @media screen and (max-width: 767px) {
                max-width: 10.6875rem;
                margin: 0 auto;
            }
            .top-img {
                position: absolute;
                top: 0;
                right: 0;
                width: 3.625rem;
                height: 3.625rem;
                @media screen and (max-width: 991px) {
                    width: 2.8125rem;
                    height: 2.8125rem;
                }
                @media screen and (max-width:767px) {
                    width: 3.625rem;
                    height: 3.625rem;
                }
                &.border-img {
                    border-radius: 50%;
                    border: 2px solid $primary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 3.75rem;
                    height: 3.75rem;
                    box-sizing: border-box;
                    padding: 0.75rem;
                    background: $white;
                    top: 0;
                    right: 0;
                    @media screen and (max-width: 991px) {
                        width: 2.75rem;
                        height: 2.75rem;
                        padding: 0.5rem;
                    }
                    @media screen and (max-width: 767px) {
                        width: 3.5rem;
                        height: 3.5rem;
                        padding: 0.5rem;
                    }
                }
            }
        }
    }
    .right-wrap {
        .text-container {
            max-width: 87%;
            @media screen and (max-width: 767px) {
                max-width: 100%;
            }
        }
        .primary-title {
            margin-bottom: 1.25rem;
            @media screen and (max-width: 767px) {
                font-size: 1.5rem;
                line-height: 1.75rem;	
            }
        }
        .secondary-title {
            margin-bottom: 0.5rem;
            @media screen and (max-width: 767px) {
                font-size: 0.875rem;
                line-height: 1.5rem;
            }
        }
        strong {
            margin-right: 0.3125rem;
        }
        .link-text {
            margin-top: 0.625rem;
            @media screen and (max-width: 767px) {
                font-size: 1.125rem;
                line-height: 2.25rem;
            }
        }
    }
    .list-inline {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-left: 1.2rem;
        margin-top: 1.5rem;
        ul {
            width: 44%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            li {
                margin-bottom: 0.75rem;
                letter-spacing: 0;
                line-height: 1.625rem;
                font-weight: $boldFont;
                margin-right: 2rem;
                //color: $secondary;
                font-family: 'Inter Bold';
                @media screen and (max-width: 767px) {
                    font-size: 1rem;
                }
            }
        }
    }
}

/*-- =================================================
        Common style for services-wrapper End
====================================================== -- */

/*-- =================================================
        Common style for Pagination Section Start
====================================================== -- */
.pagination-section{
    position: relative;
    padding-top: 0;
    &:before{
        content: '';
        display: block;
        height: 2px;
        width: 96%;
        margin: 0 auto;
        background-color: #ccc;
        max-width: 1200px;
  }
}
/*-- =================================================
        Common style for Pagination Section End
====================================================== -- */

/*-- ===================================
      CSS for only IE specific start
======================================= --*/

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    body {
        overflow-x: hidden
    }
    .custom-checkbox input:checked+label::after {
        top: 7px;
    }
    .calendar-wrapper .calendar-row .calendar-col {
        max-width: 100% !important;
    }
    .scheduler-slide .progress-bar::after {
        top: 2px;
    }
}


/*-- ===================================
      CSS for only IE specific End 
======================================== --*/

/*-- ===================================
      CSS for call back component start here
======================================== --*/



.call-details.contact-info.calendar-cont
{
	margin: auto; 
	background: #f4f4f4;
	max-width: 620px; 
	border-radius: 10px; 
	padding: 20px 0;
}

.call-details.contact-info.calendar-cont p
{
	margin: 0;  
	padding: 0;
	font-size: 14px;
}

.call-details.contact-info.calendar-cont span.call-txt a
{
	font-size: 15px;
	font-weight: bold;
	text-decoration: underline;
	color: #333333;
}

.call-details.contact-info.calendar-cont a.call-txt b
{
	font-weight: bold;
	color: #b8252a;
	font-size: 14px;
	text-decoration: underline;
}

.contact-right
{
	border-left: 2px solid #b8252a !important;
}
.call-details.contact-info.calendar-cont .col-md-3, .call-details.contact-info.calendar-cont .col-md-9
{
	padding: 0;
}

.calendar-wrapper .calendar-cont
{
	margin-bottom: 30px !important;
}

h3.text-md.contact-info.text-center.mb-1
{
    color:  #333333!important;
}

.contact-info span.call-txt a.text-sm
{
	color: #333333 !important;
}

@media(max-width:767px){

    .scheduler-modal .contact-form{padding: 20px 30px;}
    .contact-right
	{
		border-left: none;
		padding-top: 10px;
	}
    .call-details.contact-info.calendar-cont .w-100
	{ 
		width: auto !important;
	}
    .call-details.contact-info.calendar-cont .col-md-3, 
    .call-details.contact-info.calendar-cont .col-md-9 
	{    
		padding: 0 15px;
	}
    .call-details.contact-info.calendar-cont 
	{
		max-width: 100%; 
		margin: 0 15px;
		display: block;
	}
    .call-details.contact-info.calendar-cont p
	{
		padding: 0 20px;
	}
    .call-details.contact-info.calendar-cont b
	{
		display: block; 
		margin-top: 5px;
	}
    .calendar-wrapper .contact-info
	{
		font-size: 1.1rem;
	}
}


  .call-details.contact-info.calendar-cont .text-md.contact-info.text-center{
     color: #333333 !important;;
  }
  .call-details.contact-info.calendar-cont .call-txt > .phnNumber{
	font-weight: bold;
    color: #b8252a;
    font-size: 14px;
    text-decoration: underline;
  }
  .call-details.contact-info.calendar-cont span.call-txt > .text-sm.contact-info.text-center{
	color: #333333;
  }
  .contact-right {
    border-left: 2px solid #b8252a !important;
}

.thankyou-sec h2.pink-text{color: #B84C00;}
.thankyou-sec h3.media-heading{color: #383838;}
.thankyou-sec p a{color: #212529;}