/*search box section css start*/
.search-box .top-menu .locate-form-wrapper .locate-form .col-form-label {
	padding: 0;
	color: #333333;
	font-weight: 500;
	font-size: 14px;
}

.search-box.submenu_open {
	z-index: 999;
}

.search-box {
	background-color: #fcf3de;
	z-index: 999;
	position: relative;
	-webkit-transition: all 0.5s ease;
	-moz-transition: position 10s;
	-ms-transition: position 10s;
	-o-transition: position 10s;
	transition: all 0.5s ease;
}

.header-mobile .request-mobile-sec ul.list-inline.fixed-header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
	background: #fff;
	animation: smoothScroll 0.5s forwards;
}

@keyframes smoothScroll {
	0% {
		transform: translateY(-40px);
	}

	100% {
		transform: translateY(0px);
	}
}

.search-box .top-menu .locate-form-wrapper .locate-form .form-control:focus {
	outline: none;
	color: #888;
}

.search-box .locate-form-wrapper .locate-form .col-form-label {
	padding: 0;
	color: #333333;
	font-weight: 600;
}

.search-box .locate-form-wrapper .locate-form .form-control {
	margin: 0;
	max-width: 20rem;
	height: 2.2rem;
	border: 1px solid #8c8c8c;
	border-radius: 3px 0 0 3px;
	margin-left: 10px;
}

.search-box .locate-form-wrapper .locate-form button {
	background: #b52126;
	border: none;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	width: 70px;
	height: 35px;
	border-radius: 0 3px 3px 0;
	margin: 0 0 0 -5px;
}

.search-box form.locate-form.form-group {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0px;
	min-height: 4.125rem;
}

.search-box .locate-form-wrapper .locate-form button:hover {
	background-color: #da291c;
}

#sub-menu-right-services .menu-col {
	padding-top: 10px;
}

@media(max-width: 991px){
.search-box .locate-form-wrapper .locate-form .form-control{
    max-width: 11.5rem;
    font-size: 12px;
    border-radius: 4px;
    margin-left: 5px;
    padding: 0px 10px;
}
.search-box .locate-form-wrapper .locate-form .col-form-label {
    font-size: 0.69rem;
}
.request-mobile-sec.sticky-header .primary-btn {
    min-width: 100%;
    padding: 0 0.8125rem;
    min-height: 2.1925rem;
    font-size: 12.5px;
}
.request-mobile-sec.sticky-header>ul {
    align-items: center;
    padding: 0.58rem;
}
}
/*search box section css ends here*/

/* fix for the bug start from here padding issue on next and top button on blog page 171662 */
a#prevButton.link-text {
    padding-left: 2rem !important
}
a#back-to-top-article.link-text {
    padding-left: 2rem !important
}
/* fix for the bug start from here padding issue on next and top button on blog page 171662*/

.service-coupon-alternate-bgcolor:nth-of-type(odd) {
    background: #e0e0e0 !important
 }
 .menu-mob-clr {
    color: #b81f24;
 }
 
 /*optimize flow css changes start from here*/
 .form-progressbar h5 {
    padding: 0 0 15px 0;
    color: #B52126;
}
.scheduler-modal h5 {
    font-size: 17px;
}
.scheduler-modal .fl {
    color: #B52126;
    font-family: 'Inter Bold';
    font-size: 16px;
}
.menu-mob-clr {
    color: #b81f24;
 }
.scheduler-modal .custom-radio-secondary .radio-circle::after {background: #B52126 !important;width: 12px;height: 12px;}
.scheduler-modal .custom-radio-secondary .radio-circle {border-color: #979797 !important;}
.scheduler-modal input, .scheduler-modal select {outline: none !important;border-width: 1px !important; height: 44px !important;appearance: none;-moz-appearance: none;-webkit-appearance: none;} 
.scheduler-modal label.custom-radio-secondary {padding-right: 20px;}
.scheduler-modal .custom-radio-secondary input[type="radio"]:focus + .radio-circle {outline: none;} 
.form-check-wrap label.custom-radio-secondary {display: block;margin: 0 0 -4px 0;}
.scheduler-modal select {background: url('/us/en-us/mr-handyman/_assets/images/down-arrow.png') no-repeat center right 10px / 10px;}
.scheduler-modal input[type="file"]{position: relative;}
.scheduler-modal input[type="file"]:before {
    content: "Upload Image";
    background-color: rgb(246 224 170);
    position: absolute;
    z-index: 1;
    padding: 5px 9px;
    border-radius: 3px;
    border: 1px solid #B52126;
    font-size: 12px;
    font-weight: 500;
    margin-left: 1px;
    margin-top: -1px;
}
.scheduler-modal .sub-heading {
    letter-spacing: 0;
    text-align: center;
    color: #b52126;
    font-family: "Inter Bold";
    font-weight: 500;
    line-height: 1.35;
}
.scheduler-slide .fieldset {
    margin: -1.825rem auto !important;
}
 //progress bar mrh css start from here
// .scheduler-slide .progress {
//     max-width: 318px !important;
// }
// .scheduler-slide .progressbar {
//     max-width: 460px !important;
// }
.scheduler-slide .progressbar li {
    width: 33.3333333333%;
}
.scheduler-slide .progressbar li:nth-child(4)::before {
    padding-right: 0px;
    border: 1px solid #909090 !important;
    margin: 0 0 0.625rem 6.1765rem !important;
}
@media screen and (max-width: 767px){
    .scheduler-slide .progressbar li:nth-child(4)::before {
        margin: 0 0 0.625rem 3rem !important;
    }
}
//calender screen css
.calendar-wrapper .date-details span {
    color: #b81f24 !important;
    font-weight: 700 !important;
    font-size: 80%;
}
.calendar-wrapper .calendar-row .calendar-col .calendar-btn {
    background: #b81f24;
    color: #fff;
    font-size: 13px;
    font-family: 'Inter Regular';
    font-weight: 400;
}
.calendar-wrapper .calendar-row .calendar-col .calendar-btn.active, .calendar-wrapper .calendar-row .calendar-col .calendar-btn:focus {
    background: #b81f24;
    color: #fff;
    outline: 2px solid #b81f24 !important;
    outline-offset: 1px;
}
.calendar-wrapper .calendar-row .calendar-col .calendar-btn:disabled {
    outline: none !important;
}
.calendar-wrapper .date-details.selected-date .selected-date-txt {
    color: #B52126;
    font-family: 'Inter Bold';
    font-weight: 500;
    line-height: 1.35;
    font-size: 1.5rem;
}
.calendar-wrapper .date-details.selected-date {
    margin: 2rem 0 3rem 0;
    display: block !important;
    justify-content: center;
}
.scheduler-modal .head-title {
    letter-spacing: 0;
    font-size: 2.125rem;
    text-align: center;
    color: #000;
    line-height: 1.1;
    margin: 2rem 0 0.5rem;
}
.scheduler-modal .mrh-calendar-screen small {
    font-size: 14px;
    font-weight: 100 !important;
    font-family: 'Inter Regular';
    display: block;
    line-height: 1.4;
    padding: 5px 0 0;
}
.confirmation-page h2 {
    font-size: 2rem;
    text-align: center;
    line-height: 1.1;
    color: #b52126;
}

.primary-btn:disabled, .primary-btn.disabled {
    pointer-events: none;
    background-color: #ccc;
    color: #a0a0a0;
}
.scheduler-modal .modal-dialog .modal-content {
    padding-bottom: 5rem !important;
}
.scheduler-slide .progressbar li::before {
    border: 1px solid #909090 !important;
   
}

element.style {
    width: 5.2%;
}
.scheduler-slide .progress-bar {
    height: 16px !important;
}

.calendar-wrapper .date-details strong {
    display: block;
    color: #b81f24;
    font-size: 16px;
    font-family: "Inter Regular";
    font-weight: 600;
}
//callback css start from here
.call-details.contact-info.calendar-cont .col-md-2 {
    padding-right: 0px;
    position: relative;
    left: 10px;
}
.calendar-cont .contact-left img {
    width: 50px;
}
.call-details.contact-info.calendar-cont .col-md-10 {
    padding-left: 0px;
}

.call-details.contact-info.calendar-cont .col-md-10 .contact-right {
    font-weight: bold;
    border: 0px !important;
}
.contact-right {
    padding-left: 10px;
    color: #B52126;
}
.call-details.contact-info.calendar-cont p {
    text-align: left;
    color: #343a40 !important
}
.contact-right .body-text-sm span:first-child {
    font-size: 18px;
    font-family: "Inter Bold";
}
.call-details.contact-info.calendar-cont a.call-txt b {
    font-weight: bold;
    font-size: 14px;
    text-decoration: underline;
    color: #343a40;
}
.call-details.contact-info.calendar-cont .call-txt>.phnNumber {
    font-weight: 700;
    color: #B52126;
    font-size: 14px;
    text-decoration: underline;
}
//@prasanth chnages are start here
.scheduler-modal .head-title {
    margin: 4rem 0 0.5rem!important;
}
.calendar-wrapper .date-details-wrapper .date-details.active, .calendar-wrapper .date-details.active strong, .calendar-wrapper .date-details-wrapper .date-details.active span{
    background-color: #b00006;
    color: #fff!important;
}
.attach-input{
    border: 1px solid #979797;
    min-height: 3rem;
    font-size: .875rem;
    color: #333;
    border-radius: 6px;
    font-family: "Inter Regular";
    max-width: 100% !important;
    margin-right: 0px !important;
    padding: 0.375rem 0.75rem;
}
.scheduler-modal input[type=file] {
    padding-top: 4px;
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%!important;
}
//@prasanth chnages are ends here

//calender screen mobile and mobile call back css
@media only screen and (max-width: 767px){
    .calendar-wrapper .date-details-wrapper .date-details.active span  {
        color: #fff !important;
    }
    .calendar-wrapper .date-details-wrapper .date-details.active strong  {
        color: #fff !important;
    }
    .calendar-wrapper .date-details-wrapper .date-details span  {
        color: #525252 !important;
    }
    .calendar-wrapper .date-details-wrapper .date-details strong  {
        color: #525252 !important;
    }
    .call-details.contact-info.calendar-cont {
        padding: 0px 15px;
    }
    .call-details.contact-info.calendar-cont {
        max-width: 100%;
        margin: 0 15px;
        display: block;
    }
    .call-details.contact-info.calendar-cont .w-100 {
        padding: 10px;
    }
    .call-details.contact-info.calendar-cont .w-100 {
        width: auto !important;
    }
    .call-details.contact-info.calendar-cont .col-md-2 {
        display: inline-block;
        width: 12%;
        padding: 0px;
        position: relative;
        left: 5px;
        top: -18px;
    }
    .call-details.contact-info.calendar-cont .col-md-10 {
        display: inline-block;
        width: 85%;
        padding: 0px;
    }
    .contact-right {
    padding-top: 10px;
    }
    .call-details.contact-info.calendar-cont .col-md-10 .contact-right .body-text-sm {
        display: inline-block;
        font-size: 11.5px;
        font-weight: 700;
        line-height: 1.5;
    }
    .call-details.contact-info.calendar-cont .col-md-10 .contact-right p span {
        font-size: 14px;
        display: inline-block;
        padding-right: 5px;
    }
    .call-details.contact-info.calendar-cont .col-md-10 .contact-right .body-text-sm a.call-txt {
        display: inline-block;
    }
    .call-details.contact-info.calendar-cont .col-md-10 .contact-right p span {
        font-size: 14px;
        display: inline-block;
        padding-right: 5px;
    }
    .call-details.contact-info.calendar-cont .col-md-10 .contact-right .body-text-sm a.call-txt {
        display: inline-block;
    }
    .call-details.contact-info.calendar-cont a.call-txt b {
        font-size: 12px;
    }
    .call-details.contact-info.calendar-cont b {
        display: block;
        margin-top: 5px;
    }
    .call-details.contact-info.calendar-cont .text-md.contact-info.text-center {
        padding-right: 42px;
    }
    .text-md {
    font-size: 1rem;
    }
}


/* Request form start */
.request-form .scheduler-modal {
    background: #f3f3f3;
    border: 1px solid #dfdfdf;
    padding: 40px;
    border-radius: 6px;
    max-width: 725px;
    margin: 0 auto;
}
.request-form .scheduler-modal .asv-primary-red {
    color: #b84c00 !important;
}
.asv-primary-btn:hover, .asv-primary-btn:focus, .asv-primary-btn:active {
    background-color: #243d94;
}

.request-form .asv-primary-btn {
    background-color: #b84c00;
    color: #ffffff;
}
.request-form .brand-btn {
    text-align: center;
    border-radius: 0.5rem;
    border: 0;
    font-size: 1.1875rem;
    line-height: 2.8125rem;
    min-height: 2.8125rem;
    padding: 0 20px;
    display: inline-block;
    font-family: 'Inter Medium';
    font-weight: 500;
}

.request-form .asv-primary-btn:hover, .request-form .asv-primary-btn:focus, .request-form .asv-primary-btn:active {
  background-color: #1E437B;
  color: #ffffff;
  text-decoration: none;
}
.request-form .scheduler-modal .form-control{background-color:#fff;}
.request-form .scheduler-modal label {
    font-weight: 600;
}

.request-form .scheduler-modal span.error-msg, .scheduler-modal span.info-msg { /* display: block; */font-size: 11px;color: #b34734;font-weight: 600;position: relative;white-space: nowrap;padding: 5px 5px 5px 20px;font-family: 'Inter Regular';font-weight: 400;}
.request-form .form-group.msg-show > label {color: #9B1C1C;}
.request-form-thankyou .info-list {
    list-style: none;
    max-width: 320px;
    margin: 0 auto;
    text-align: left;
    display: block;
}
.request-form-thankyou .info-list li {
    padding: 5px 0 10px;
}
.request-form-thankyou .info-list li span b {
    font-weight: 600;
}
.request-form-thankyou .info-list li span {
    display: block;
}
.asv-primary-red {
    color: #b84c00;
}
.request-form .msg-show .form-control {
    border-color: #9B1C1C;
    background-color: #fbf4f4 !important;
}
.request-form .scheduler-modal span.error-msg::after {content: "";height: 13px;width: 12px;position: absolute;display: block;background: url(../images/error-icon.svg) no-repeat left top;background-size: 12px;left: 3px;top: 4px;transform: rotate(180deg);}
.request-form .asv-primary-btn img {
    top: 0px;
    position: relative;
    left: 10px;
    width: 14px;
}
@media (max-width: 1024px) { 
 
	.request-form .scheduler-modal {padding: 30px 20px !important;} 
	.request-form h3 {font-size: 1.50rem;}
	.request-form h2.fieldset-title {font-size: 1.35rem;}

} 
/* Requet form end */
.service-video-section iframe {border: 5px solid #da291c; width:100%; height:100%}

.header-classic .navbar {
    background-color: #fff;
}

.header-classic.desktop-header .sticky {
    top: 0; /* Make the header stick to the top */
    position: fixed;
    width: 100%;
    z-index: 100;
    padding-bottom: 0;
}



// lead flow zip code modal css start

.locaion-code input#locationSearch,.locaion-code #locationSearch_change_loc {
    color: #6B7280;
}

.locaion-code input#locationSearch::-webkit-search-cancel-button,.locaion-code input#locationSearch_change_loc::-webkit-search-cancel-button {
    width: 18px;
    height: 18px;
    background-image: url(../images/location-close-icon.svg);
    background-repeat: no-repeat;
    background-position: 94% center;
    background-size: 15px;
    cursor: pointer;
    opacity: 1;
    -webkit-appearance: none;
    transition: 0.2s;
}

.locaion-code span.location-icon {
    opacity: 0;
    visibility: hidden;
}

.locaion-code span.location-icon.open {
    opacity: 1;
    visibility: visible;
    transition: 0.2s;
}

.locaion-code .search-input-wrap {
    position: relative;
}

.locaion-code .go-bttn {
    font-size: 16px;
    outline: none;
    border: 0;
}

#click-location-gps,#clickabletext_change_loc,#hero-location-gps {
    width: 40px;
    height: 45px;
    position: absolute;
    cursor: pointer;
}

.locaion-code .search-input-wrap input#locationSearch,#locationSearch_change_loc{
    border-radius: 4px;
    padding: 10px 10px 10px 45px;
    height: auto;
    font-size: 14px;
    width: 220px;
    font-family: 'Inter Regular';
    height: 45px;
    min-height: auto;
}

.locaion-code .go-bttn {
    border-radius: 6px;
    font-size: 13px !important;
    padding: 0 15px;
    margin-left: 10px !important;
}
.locaion-code .go-bttn:hover {background-color: #da291c;}
.locationerror {
    border-color: #B52126 !important;
}

input#locationSearch.locationerror,#locationSearch_change_loc.locationerror {
    color: #B52126 !important;
}

input#locationSearch.locationerror::-webkit-search-cancel-button,input#locationSearch_change_loc.locationerror::-webkit-search-cancel-button  {
    background-image: url(../images/location-close-icon-red.svg);
}

.call-icon::before {
    display: none;
}

.locaion-code .search-input-wrap i.field-close-icon {
    width: 33px;
    height: 35px;
    margin: 5px 0 0 -33px;
    cursor: pointer;
}
.scheduler-modal .modal-dialog .modal-header{
    border-bottom: none;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    padding: 10px 0 10px 0;
    margin: 0 auto;
    max-width: 550px;
    flex-direction: inherit;
}
.scheduler-modal .header-brand-logo {
    max-width: 180px;
}

.scheduler-modal img.nbly-done-right-promise{ max-width: 50px;}
.scheduler-modal .head-title {font-size: 2rem;}
.scheduler-modal .head-title {margin-bottom:0px}
.mm_modal-call .call-icon.primary-theme-clr {
    font-weight: 400;
    font-family: 'Inter Bold';
    white-space: nowrap;
    font-size: 21px;
    text-decoration: none;
    letter-spacing: 1px;
    color: #CE360B;
}
.mm_modal-call .call-icon.primary-theme-clr:hover {
    color: #0056b3;
}
.mm_modal-call a.call-icon img {vertical-align: sub;margin: 0 0 0;}
.body-text{font-size: 1.16rem;}
.scheduler-modal .modal-dialog .close{border:0px;}
.scheduler-modal .banner-wizard .modal-header-two {display: flex !important;margin-top: 20px;}
.zip-entry-screen .cust-opus-h2-cls{color: #212529;}
.scheduler-modal .modal-dialog .close:focus{outline: auto;}
.scheduler-modal .modal-dialog .close{top: 0px;right: 0px;}
.scheduler-modal .modal-dialog  .sub-heading {
    text-align: center;
    font-size: 17px;
    margin: 5px 0 15px 0;
    font-size: 17px;
    font-family: "Inter Bold";
    font-weight: 600;
}
.location-search.mr-2 {
    margin-right: 0px;
}
#click-location-gps, #clickabletext_change_loc, #hero-location-gps {
    background: #f9fafb url(/us/en-us/mr-handyman/_assets/images/locator-icon.svg) no-repeat center left -1px/contain;
    background-color: #cbc9c9;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.scheduler-modal .modal-dialog .modal-content {
    padding: 1rem 0.5rem;
}
@media screen and (max-width: 991px){
body .scheduler-modal .modal-dialog .close {
    top: 0px;
    right: 0px;
}
}
@media(max-width:767px) {
    .locaion-bttn a img {
        filter: brightness(0) invert(1);
    }
    .locaion-code {
        max-width: 280px;
        margin: auto;
    }
    .modal-header-one.promise-logo img {
        max-width: 55px;
    }
    
    .scheduler-modal .header-brand-logo {
        max-width: 125px;
    }
    
    .mm_modal-call span,.mm_modal-call a {font-size: 14px !important;letter-spacing: 0;}
    .locaion-code .search-input-wrap input#locationSearch,.locaion-code #locationSearch_change_loc{width:100%;display:block}
    .locaion-code .search-input-wrap input#locationSearch,.locaion-code #locationSearch_change_loc{width:180px;}
    .scheduler-modal .locaion-code .primary-btn{width: auto;min-width: auto;}
    .locaion-code .go-bttn{padding: 0px;}
    #locationform_change_loc button#main_btn_zipcode_change_loc {
        padding: 10px;
    }

}
// lead flow zip code modal css end
// 320920 change location from home page in mobile view page is breaking css start
@media screen and (max-width: 991px) {
    .request-mobile-sec.sticky-header ul li .call-icon {
        line-height: 2.5 !important;
    }
    .header-classic.mobile-header .navbar-collapse.show{
        background-color: #fff;
    }
    .mobile-header .mobile-logo-sec{
        justify-content: space-between !important;
        align-items: center !important;
        -webkit-align-items: center !important;
    }
}
// 320920 change location from home page in mobile view page is breaking css end
