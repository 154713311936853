
.neighborly-sec ul .sign-in::before{
    background: url(/us/en-us/mr-handyman/_assets/images/sprites/mrh-sprite.png) no-repeat top left;
    background-position: -11px -10px;
    background-size: auto;
}
.banner-text-sec .navbar-form .invalid-feedback::after{
    background: url(/us/en-us/mr-handyman/_assets/images/sprites/mrh-sprite.png) no-repeat top left !important;
    background-size: auto;
    width: auto;
    background-position: -12px -190px !important;
}

.link-text.blue-arrow::before{
    background: url(/us/en-us/mr-handyman/_assets/images/sprites/mrh-sprite.png) no-repeat top left;
    background-size: auto;
    width: 30px;
    background-position: -5px -122px;
    height: 29px;
}
.collapsable-list h3 button.collapsed::before{
    background: url(/us/en-us/mr-handyman/_assets/images/sprites/mrh-sprite.png) no-repeat top left;
    background-position: -15px -91px;
    background-size: auto;
}
.link-text::before{
    background: url(/us/en-us/mr-handyman/_assets/images/sprites/mrh-sprite.png) no-repeat top left;
    background-position: -10px -50px;
    background-size: auto;
}

.link-text:hover::before, .link-text:focus::before {
    background-size: 21px;
    background-size: auto;
    background: url(/us/en-us/mr-handyman/_assets/images/sprites/mrh-sprite.png) no-repeat top left;
    background-position: -10px -50px;
    background-size: auto;
}
.link-text.blue-arrow::before {
    background: url(/us/en-us/grounds-guys/_assets/images/sprites/NEI-icon-sprite-1.png) no-repeat top left;
    background-position: -9px -201px;
    background-size: auto;
}
.align-items-center .header-left-sec .col-sec-middle .locate-form-wrapper .text-danger::after{
    background: url(/us/en-us/mr-handyman/_assets/images/sprites/mrh-sprite.png) no-repeat top left !important;
    background-size: auto;
    width: auto;
    background-position: -12px -190px !important;
}
.link-text:hover::before, .link-text:focus::before {
    background: url(/us/en-us/mr-rooter/_assets/images/sprites/mrr-sprite.png) no-repeat left top;
    background-size: 21px;
    background-position: -10px -50px;
    background-size: auto;
}
.scheduler-modal .service-search-submit[type=submit]{
    background: url(/us/en-us/five-star-painting/_assets/images/sprites/fsp-sprite.png) no-repeat top left;
    background-position: 5px -113px;
    background-size: auto;
}