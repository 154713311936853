@import "partials/variable";

/*-- ==========================================
       Results Gallery Section Start
============================================= --*/

.result-gallery {	
    padding: 4.25rem 0 5rem;	
    text-align: center;	
    @media screen and (max-width: 992px) {	
        padding: 2.5rem 0 2.8rem;	
    }	
    .primary-title{	
        @media screen and (max-width:767px) {	
            font-size: 1.5rem;	
        }	
    }
    .slider {
        padding: 2rem 0 0.5rem;
        display: flex;
        align-items: center;
        @media screen and (max-width: 992px) {
            padding: 0.50rem 0 0;
        }
        .slick-list{
            margin: 0 -12px;
            @media screen and (max-width: 1300px) {
                margin: 0 auto;
                width: 93%;
            }
            @media screen and (max-width: 767px) {
                width: 90%;
            }
        }
        .slick-slide{
            margin: 4px 12px;
            img{
                width: 100%;
            }
            .photo-title {
                margin-top: 0.89rem;
                margin-bottom: 0;
                height: 50px;
                overflow: hidden;
            }
            &:focus {
                outline: 2px solid $darkBlue;
                outline-offset: 2px;
            }
        }
        .slider-prev, .slider-next {
            display: block;
            background: transparent;
            border: 0;
            position: absolute;
            top: 36%;
            padding: 35px 0 0;
            &::before,
            &::after {
                border-right: 4px solid $primary;
                content: " ";
                display: block;
                height: 22px;
                margin-top: -17px;
                position: absolute;
                transform: rotate(135deg);
                top: 16px;
                width: 1px;
                left: 50%;
                @media screen and (max-width: 992px) {
                    height: 18px;
                    margin-top: -14px;
                }
            }
            @media screen and (max-width: 992px) {
                padding: 35px 12px 0;
            }
            &:after {
                margin-top: -3px;
                transform: rotate(45deg);
            }
            span{
                display: block;
                font-size: 0.938rem;
                @media screen and (max-width: 992px) {
                    display: none;
                }
            }
        }
        .slider-prev{
            left: -50px;
            @media screen and (max-width: 1300px) {
                left: 0;
            }
            &::before,
            &::after {
                left: auto;
                right: 50%;
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(135deg);
            }
       }
        .slider-next{
            right: -50px;
            @media screen and (max-width: 1300px) {
                right: 0;
            }
       }
    }
}
.slick-dots{visibility: hidden; height: 0;}


/*-- ==========================================
       Results Gallery Section Start
============================================= --*/


/*-- ==========================================
       Photo gallery section start
============================================= --*/
.tabbing-panel-section {
    padding-top: 4.5rem;
    text-align: center;
  }
  
  @media screen and (max-width: 767px) {
    .tabbing-panel-section {
      padding-top: 2.35rem;
    }
  }
  
   
  .tabbing-panel-section .tab-head-text strong {
    margin-right: 0.5rem;
    color: #d9272e;
  }
  
   
  .tabbing-panel-section .tabs {
    padding-top: 0.938rem;
  }
  
  .tabbing-panel-section .tabs button[role="tab"] {
    margin: 0 0.8rem 1.875rem;
    min-width: 150px;
  }
  
  @media screen and (max-width: 480px) {
    .tabbing-panel-section .tabs button[role="tab"] {
      min-width: 110px;
    }
  }
  
  .tabbing-panel-section [role="tablist"] {
    padding-bottom: 3.15rem;
  }
  
  @media screen and (max-width: 992px) {
    .tabbing-panel-section [role="tablist"] {
      padding-bottom: 1rem;
    }
  }
  
  @media screen and (max-width: 767px) {
    .tabbing-panel-section [role="tablist"] {
      padding-bottom: 0;
    }
  }
  
  .tabbing-panel-section .primary-title {
    margin-bottom: 1.813rem;
  }
  
  @media screen and (max-width: 767px) {
    .tabbing-panel-section .primary-title {
      font-size: 1.5rem;
    }
  }
  
  .tabbing-panel-section button[role="tab"][aria-selected="true"] {
    background-color: #0033a0;
    position: relative;
  }
  
  .tabbing-panel-section button[role="tab"][aria-selected="true"]::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -12px;
    width: 100%;
    height: 2px;
    background-color: #333333;
  }
  
  .tabbing-panel-section .tab-content {
    background-color: #f0f0f0;
    padding: 4.25rem 0 0.1rem;
    text-align: left;
  }
  
  @media screen and (max-width: 767px) {
    .tabbing-panel-section .tab-content {
      padding: 2.5rem 0 0.1rem;
    }
  }
  
  .tabbing-panel-section .tab-content .image-gallery-row {
    list-style-type: none;
    margin-bottom: 0;
  }
  
  .tabbing-panel-section .tab-content .image-gallery-row .image-wrap {
    margin-bottom: 1.688rem;
    padding: 0 0.75rem;
  }
  
  .tabbing-panel-section .tab-content .image-gallery-row .image-wrap a {
    display: inline-block;
    text-decoration: none;
  }
  
  .tabbing-panel-section .tab-content .image-gallery-row .image-wrap a img {
    width: 100%;
  }
  
  .tabbing-panel-section .tab-content .image-gallery-row .image-wrap a .img-caption {
    padding-top: 0.625rem;
    margin: 0;
    color: #000; 
  }
  
  .tabbing-panel-section .modal {
    background: rgba(13, 13, 13, 0.9);
    padding: 0;
  }
  
  @media screen and (min-width: 580px) and (max-width: 1200px) {
    .tabbing-panel-section .modal-dialog {
      max-width: 90%;
      margin: 1rem auto;
    }
  }
  
  .tabbing-panel-section .modal-content {
    max-width: 70%;
    margin: 0 auto;
    position: static;
    background: none;
    border: none;
  }
  
  @media screen and (max-width: 580px) {
    .tabbing-panel-section .modal-content {
      max-width: 100%;
    }
  }
  
  .tabbing-panel-section .modal-content a:focus,
  .tabbing-panel-section .modal-content button:focus,
  .tabbing-panel-section .modal-content .btn:focus {
    outline-color: #ffffff;
  }
  
  .tabbing-panel-section .modal-content .modal-header {
    border: 0;
    padding: 0;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  
  .tabbing-panel-section .modal-content .modal-header .close {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 1;
    font-size: 3rem;
    padding: 0.5rem;
    color: #ffffff;
    background: none;
    border: none;
    opacity: 1;
  }
  
  @media screen and (max-width: 580px) {
    .tabbing-panel-section .modal-content .modal-header .close {
      position: static;
      padding: 0;
      margin: 0.5rem 0.5rem 0 0;
    }
  }
  
  .tabbing-panel-section .modal-content .modal-body {
    text-align: left;
  }
  
  @media screen and (max-width: 580px) {
    .tabbing-panel-section .modal-content .modal-body {
      padding: 2rem 0;
      text-align: center;
    }
  }
  
  .tabbing-panel-section .modal-content .modal-body .image-gallery-slideshow img {
    width: 100%;
  }
  
  .tabbing-panel-section .modal-content .modal-body .image-gallery-slideshow .modal-title {
    color: #ffffff;
    margin: 1.25rem 0 1.4rem;
  }
  
  @media screen and (max-width: 580px) {
    .tabbing-panel-section .modal-content .modal-body .image-gallery-slideshow .modal-title {
      max-width: 90%;
      margin: 1.25rem auto 1.4rem;
      font-size: 1rem;
    }
  }
  
  .tabbing-panel-section .modal-content .modal-body:focus {
    outline: none;
  }
  
  .tabbing-panel-section .modal-content .modal-footer {
    border: 0;
    padding: 0;
  }
  
  @media screen and (max-width: 580px) {
    .tabbing-panel-section .modal-content .modal-footer {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      max-width: 90%;
      margin: 0 auto;
      width: 100%;
    }
  }
  
  .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn,
  .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn {
    position: absolute;
    top: 40%;
    color: #ffffff;
    z-index: 1;
    padding: 0;
    background: none;
    border: none;
  }
  
  @media screen and (max-width: 991px) {
    .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn,
    .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn {
      top: 35%;
    }
  }
  
  @media screen and (max-width: 580px) {
    .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn,
    .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn {
      position: static;
    }
  }
  
  .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn::before, .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn::after,
  .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn::before,
  .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn::after {
    background-size: cover;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    display: inline-block;
  }
  
  @media screen and (max-width: 991px) {
    .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn::before, .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn::after,
    .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn::before,
    .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn::after {
      width: 40px;
      height: 40px;
    }
  }
  
  @media screen and (max-width: 580px) {
    .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn::before, .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn::after,
    .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn::before,
    .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn::after {
      width: 10px;
      height: 10px;
    }
  }
  
  .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn.disabled,
  .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  
  .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn.disabled:focus,
  .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn.disabled:focus {
    outline: none;
  }
  
  .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn span,
  .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn span {
    display: none;
  }
  
  @media screen and (max-width: 580px) {
    .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn span,
    .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn span {
      display: inline-block;
    }
  }
  
  .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn {
    left: 0;
  }
  
  .tabbing-panel-section .modal-content .modal-footer .carousel-prev-btn::before {
    content: '';
    background-image: url(../images/left-arrow-white.svg);
  }
  
  .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn {
    right: 0;
  }
  
  .tabbing-panel-section .modal-content .modal-footer .carousel-next-btn::after {
    content: '';
    background-image: url(../images/left-arrow-white.svg);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  
  @media screen and (max-width: 580px) { 
    .tabbing-panel-section .social-links {
      max-width: 90%;
      margin: 0 auto;
    }
  }
  
  .tabbing-panel-section .social-links span {
      display: inline-block;
      vertical-align: middle;
      color: #fff;
  }
  
  @media screen and (max-width: 580px) {
    .tabbing-panel-section .social-links span {
      font-size: 1.125rem;
      padding-bottom: 0.5rem;
    }
  }
  
  .tabbing-panel-section .social-icons-list {
    padding-left: 0.75rem;
    display: inline-block;
    margin-bottom: 0;
  }
  
  @media screen and (max-width: 580px) {
    .tabbing-panel-section .social-icons-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding-left: 0;
    }
  }
  
  .tabbing-panel-section .social-icons-list a {
    display: inline-block;
  }
  
  .tabbing-panel-section .pagination-section {
    padding-bottom: 4.6rem;
  }
  
  @media screen and (max-width: 767px) {
    .tabbing-panel-section .pagination-section {
      padding-bottom: 2.55rem;
    }
  }
  
  .mobile-filter {
    display: none;
  }
  
  @media screen and (max-width: 767px) { 
    .mobile-filter {
      display: block;
      margin-bottom: 2.8rem;
    } 
    .category-tab {
      display: none;
    }
  }
  
  #jsCategorySelect {
    display: inline-block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    vertical-align: middle;
    background: #d9272e url(../images/select-down-arrow.png) no-repeat center right 10px / 11px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .pagination-section:before {
    content: '';
    display: block;
    height: 2px;
    width: 96%;
    margin: 0 auto;
    background-color: #ccc;
    max-width: 1200px;
}
.pagination-section:before {
    margin-bottom: 3.1875rem;
}
.tabbing-panel-section select {    font-family: 'Inter Regular'; background: #d9272e url(../images/select-down-arrow.png) no-repeat center right 10px / 11px;color: #fff;width: 320px;float: right;margin-top: -80px;border: 0;box-shadow: none !important;outline: none !important;cursor: pointer; font-size: 15px; appearance: none !important;}
.tabbing-panel-section select option {background: #fff;color: #000;     font-family: 'Inter Regular';} 
.light-grey-primary-bg {background-color: #f0f0f0;}


  /*-- ==========================================
         Photo gallery section end
  ============================================= --*/

  .pagination-section:before {
    content: '';
    display: block;
    height: 2px;
    width: 96%;
    margin: 0 auto;
    background-color: #ccc;
    max-width: 1200px;
}
.pagination-section:before {
    margin-bottom: 3.1875rem;
}


@media screen and (min-width: 767px) {	

	.tabbing-panel-section p.tab-head-text {text-align: left;padding-right: 350px;}
	
}

@media screen and (max-width: 767px) {
	
	.tabbing-panel-section select { width: 100%;float: none;margin: 0 0 40px 0;}
	
}