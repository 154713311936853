.footer-btn-yellow {
    color: $darkBlue;
    background: $primaryYellow;
    border-color: transparent;
    font-size: 1rem;
    text-align: center;
    vertical-align: top;
    font-family: 'Inter Regular';
    font-weight: 700;
    min-width: 8.125rem;
    min-height: 2.6875rem;
    line-height: 2.6875rem;
    display: inline-block;
    border: 0;
    cursor: pointer;
    padding: 0.125rem 1.375rem 0 1.375rem;
    transition: all 0.2s;
    border-radius: 0.5rem;
}

footer {
    .footer {
        a {
            &:focus {
                outline-color: $white;
            }
        }
        .footer-top-bg {
            background: url(../images/footer_mark_icon_bg.svg) no-repeat bottom right;
            @media screen and (max-width: 767px) {
                background-size: 20%;
            }
        }
        padding: 3.75rem 0 0 0;
        .links-section {
            margin-bottom: 3.125rem;
            @media screen and (max-width: 767px) {
                margin-bottom: 2rem;
            }
        }
        .footer-logo-wrap {
            text-align: center;
            max-width: 12.9375rem;
            .address {
                letter-spacing: 0;
                text-align: center;
                font-weight: 500;
                line-height: 1.5rem;
                span {
                    display: block;
                }
                @media screen and (max-width: 767px) {
                    line-height: 1.1rem;
                }
            }
        }
        .footer-brand-logo {
            display: inline-block;
            // min-height: 53px;
            min-height: 3.3125rem;
            @media screen and (max-width: 767px) {
                // min-height: 35px;
                min-height: 2.1875rem;
            }
            img {
                // width: 251px;
                height: auto;
                max-width: 100%;
                width: 9.6875rem;
                @media screen and (max-width: 767px) {
                    width: 8.75rem;
                }
            }
        }
        .location-list-details {
            margin-bottom: 1.25rem;
            margin-top: 1.375rem;
            text-align: center;
            max-width: 12.9375rem;
            @media screen and (max-width: 767px) {
                margin-top: 1.25rem;
            }
            strong {
                letter-spacing: 0;
                font-weight: 500;
                font-size: 1.75rem;
                &:hover {
                    text-decoration: none;
                }
                @media screen and (max-width: 991px) {
                    font-size: 1rem;
                }
                @media screen and (max-width: 362px) {
                    font-size: 0.8125rem;
                }
            }
            .call-us-text {
                letter-spacing: 0;
                font-weight: 500;
                margin-top: 0.3125rem;
                display: block;
                margin-bottom: 0;
            }
        }
        .list-unstyled li {
            margin-bottom: 0px;
            a {
                letter-spacing: 0;
                line-height: 2rem;
                transition: all 0.2s;
                font-weight: 500;
                &:hover,
                &:focus {
                    text-decoration: underline;
                    color: $white;
                }
            }
        }
        .footer-search {
            @media screen and (min-width: 767px) {
                padding-left: 1.875rem;
            }
        }
        .footer-search-box {
            min-height: 3.75rem;
            position: relative;
            @media screen and (max-width: 767px) {
                margin-top: 1.875rem;
                min-height: 4.0625rem;
            }
            .input-group {
                border: 1px solid #979797;
                margin-bottom: 0;
                height: 2.8125rem;
                display: flex;
                flex-wrap: wrap;
                border-radius: 5px;
                max-width: 31.25rem;
                @media screen and (max-width: 767px) {
                    max-width: 100%;
                }
                .form-control {
                    border: none;
                    padding: 0 0 0 0.75rem;
                    height: 2.8125rem;
                    letter-spacing: 0;
                    line-height: 2.8125rem;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    font-size: 0.875rem;
                    color: $black;
                    &:focus {
                        outline-offset: 2px;
                        outline-color: $white;
                    }
                }
                .input-yext {
                    height: 2.8125rem;
                    border: none;
                    border-left: 0;
                    background: no-repeat center/2rem url(../images/yext_icon.png);
                    background-color: $white;
                    padding: 0 0.625rem 0 1.5625rem;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    &:focus {
                        outline-offset: 2px;
                        outline-color: $white;
                    }
                }
            }
        }
        .list-inline-item {
            margin-bottom: 0.9375rem;
            margin-right: 1.5625rem;
            @media screen and (max-width: 767px) {
                margin-right: 0.625rem;
            }
            img {
                width: 3.75rem;
                height: auto;
            }
        }
        .footer-social-icons {
            display: flex;
            justify-content: flex-end;
            .social-icons-list {
                position: relative;
                display: inline-block;
                &::before {
                    position: absolute;
                    content: '';
                    background: #fff;
                    height: 1px;
                    width: 100%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    bottom: -0.625rem;
                }
                li {
                    float: left;
                    &:nth-child(3n+1) {
                        content: '';
                        display: table;
                        clear: both;
                    }
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                    img {
                        color: $secondaryGrey;
                        transition: all 0.2s ease-in;
                        filter: brightness(0) invert(1);
                        -ms-filter: brightness(0) invert(1);
                        -webkit-filter: brightness(0) invert(1);
                        width: 1.688rem;
                        height: auto;
                        @media screen and (max-width: 767px) {
                            width: 1.375rem;
                            height: auto;
                        }
                    }
                }
            }
        }
        .copyright-foot-note {
            width: 70%;
            padding-bottom: 1.5625rem;
            letter-spacing: 0;
            line-height: 1rem;
            font-family: 'Inter Regular';
            margin-top: 1.875rem;
            font-size: 0.6875rem;
            @media screen and (max-width: 767px) {
                margin-top: 1rem;
            }
            p {
                color: $secondaryGrey;
                font-size: 0.6875rem;
            }
            a {
                text-decoration: underline;
                color: $secondaryGrey;
                font-size: 0.6875rem;
            }
        }
    }
    .footer-secondary {
        padding: 5rem 0 3.125rem 0;
        font-family: 'Inter Regular';
        @media screen and (max-width: 767px) {
            padding: 3.125rem 0;
        }
        .footer-neighbourly-logo img {
            width: 15.6875rem;
            height: auto;
            @media screen and (max-width: 767px) {
                width: 9.375rem;
            }
        }
        .logo-heading {
            display: inline-block;
            padding-left: 3.4375rem;
            letter-spacing: 0;
            line-height: 1.125rem;
            font-family: 'Inter Regular';
            font-weight: 700;
            @media screen and (max-width: 767px) {
                padding-left: 0;
                line-height: normal;
            }
        }
        .footer-info-heading {
            letter-spacing: 0;
            line-height: 1.125rem;
            font-family: 'Inter Regular';
            font-weight: 400;
            display: block;
            strong {
                font-weight: 700;
            }
            @media screen and (max-width: 767px) {
                font-size: 1rem;
                line-height: 1.5625rem;
            }
        }
        .secondary-link-cont {
            margin-top: 2.8125rem;
            @media screen and (max-width: 767px) {
                margin: 1.5625rem 0;
                .col-md-3 {
                    padding-left: 0;
                }
            }
            ul {
                @media screen and (max-width: 767px) {
                    margin-bottom: 0;
                }
            }
            li {
                margin-bottom: 2.1875rem;
                min-height: 2.25rem;
                @media screen and (max-width: 767px) {
                    margin-bottom: .2rem;
                    min-height: 3rem;
                    display: flex;
                    align-items: center;
                }
                @media screen and (max-width: 450px) {
                    min-height: 4rem;
                }
                a {
                    display: inline-flex;
                    span {
                        width: 2.8125rem;
                        height: auto;
                        margin-right: 1.5625rem;
                        @media screen and (max-width: 767px) {
                            margin-right: 0.3125rem;
                            min-width: 2.8125rem;
                        }
                        @media screen and (max-width: 450px) {
                            margin-right: 0;
                        }
                    }
                    img {
                        @media screen and (max-width: 767px) {
                            width: 1.75rem;
                            height: auto;
                        }
                    }
                    display: flex;
                    align-items: center;
                    font-family: 'Inter Regular';
                    letter-spacing: 0;
                    font-weight: 700;
                    transition: all 0.2s;
                    &:hover,
                    &:focus {
                        text-decoration: underline;
                        color: $white;
                    }
                    @media screen and (max-width: 767px) {
                        padding-left: 0.3125rem;
                        font-size: 0.875rem;
                    }
                }
            }
        }
        .footer-info-text {
            .footer-btn-yellow {
                margin: 1.5625rem 0 2.8125rem 0;
                &:focus,
                &:hover {
                    background-color: $white;
                    text-decoration: none;
                }
                &:focus {
                    outline-offset: 0.25rem;
                }
            }
            p {
                font-family: 'Inter Regular';
                font-weight: 400;
                letter-spacing: 0;
                line-height: 1.5rem;
                max-width: 53.125rem;
                width: 100%;
            }
            .copy-text {
                margin: 2.3125rem 0 0 0;
            }
        }
        .app-store-Icons a {
            display: inline-block;
        }
        .app-store-Icons .iosAppStoreBtn img {
            width: 8.6875rem;
            height: auto;
            @media screen and (max-width: 365px) {
                width: 7rem;
            }
        }
        .app-store-Icons .googlePlayBtn {
            margin-left: 1.5625rem;
            img {
                width: 10.1875rem;
                height: auto;
                @media screen and (max-width: 355px) {
                    width: 8.4375rem;
                }
            }
            @media screen and (max-width: 767px) {
                margin-left: 0.9375rem;
            }
        }
    }
    .footer-bottom-cont {
        padding: 3.75rem 0;
        text-align: center;
        .footer-bottom-links {
            letter-spacing: 0;
            text-align: center;
            margin-bottom: 1.25rem;
            li {
                position: relative;
                margin-left: 0.9375rem;
                margin-right: 0.9375rem;
                font-size: 1rem;
                @media screen and (max-width: 767px) {
                    font-size: 0.875rem;
                }
                &:last-child {
                    margin-right: 0;
                }
                &:first-child::after {
                    height: 0;
                }
                &::after {
                    position: absolute;
                    content: '';
                    top: 5px;
                    left: -0.9375rem;
                    width: 1px;
                    height: 0.9375rem;
                    background: #ccc;
                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }
                a {
                    letter-spacing: 0;
                    text-align: center;
                    font-family: 'Inter Regular';
                    font-weight: 700;
                    transition: all 0.2s;
                    &:hover,
                    &:focus {
                        text-decoration: underline;
                        color: $info;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: 0.875rem;
                    }
                }
            }
        }
        .footer-bottom-note {
            max-width: 53.125rem;
            width: 100%;
            margin: 0 auto;
            p {
                font-family: 'Inter Regular';
                letter-spacing: 0;
                text-align: center;
                line-height: 0.875rem;
                margin-bottom: 1.25rem;
                font-size: 0.6875rem;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .order-col-4 {
        order: 4;
    }
    .order-col-3 {
        order: 3;
    }
    .order-col-2 {
        order: 2;
    }
    .order-col-1 {
        order: 1;
    }
}