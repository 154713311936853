.box-tiles .asv-primary-btn {
    background-color: #da291c;
    color: #ffffff;
    display: inline-block;
    font-family: 'Inter Medium';
    font-weight: 500;
}
.box-container {padding-bottom: 40px;}
.box-container h2 {font-size: 26px;margin-bottom: 40px;}
.main-tiles-bg > section {margin: 20px 0; background:#f0f0f0;}
.box-tiles-col img {max-width: 65px;}
.box-tiles-col {background: #fff; /*color: #003DA6;*/ border-radius: 10px;padding: 35px 25px 30px; border: 2px solid #fff; cursor: pointer; height: 100%;  display: flex !important; flex-direction: column; align-items: center; justify-content: space-between;}
.box-tiles-col h3 {font-size: 22px;margin: 20px 0 9px;} 
.box-tiles-col p {font-size: 15px;line-height: 1.3;margin: 0;min-height: 160px;} 
.box-tiles-col .brand-btn {min-width: 100%;font-size: 14px;border: 1px solid #da291c;border-radius: 4px;min-height: auto;height: auto;line-height: 1;padding: 13px 35px; color: #fff; position: relative; background: #da291c;}
.box-tiles-col .brand-btn:hover, .box-style3 .box-tiles-col.hover .brand-btn, .box-style4 .box-tiles-col:hover .brand-btn {color: #da291c;background: #fff; }
.box-tiles h2 a, .box-tiles a {text-decoration: none;color: inherit;}
.box-style1 .box-tiles-col {background: #da291c;color: #fff; cursor: pointer;margin-bottom: 15px;border: 0; }
.box-style1 .box-tiles-col:hover {background: #000;} 
.box-style2 .box-tiles-col:hover, .box-style4 .box-tiles-col:hover, .box-style6 .box-tiles-col:hover {border-color: #DA291C;}    
.box-style3 .box-tiles-col, .box-style5 .box-tiles-col {background: #da291c;color: #fff;cursor: pointer; margin-bottom: 15px;border: 0;position: relative;display: block;}
.box-style3 .box-tiles-col:hover, .box-style5 .box-tiles-col:hover {background: #000;} 
.brand-btn.brand-btn3:hover { border-color: #da291c  !important;} 
.box-style5 a.brand-btn {z-index:12;} 
.box-link h2 a:after {content: "";height: 100%;width: 100%;display: block;position: absolute;top: 0;left: 0;} 
.box-container h2 i {color: #d9272e;} 
.box-style5 .box-tiles-col.hover {background: #da291c;} 
.box-style6 .box-tiles-col.hover, .box-style5 .brand-btn {border-color: #fff;}   
.box-style3 .brand-btn, .box-style5 .brand-btn { background: #fff;color: #000; border: 0;}  
.box-style3 .box-tiles-col.hover .brand-btn, .box-style3 .box-tiles-col .brand-btn:hover, .box-style5 .box-tiles-col.hover .brand-btn, .box-style5 .box-tiles-col .brand-btn:hover { color: #000 !important;border-color:#fff;}
.box-tiles img[src="/us/en-us/myhandyman/_assets/images/heart-icon-2.svg"] {background: #DA291C ;border-radius: 100px;}
.box-style2 .box-tiles-col, .box-style4 .box-tiles-col {color: #000;}
.box-style5 .box-tiles-col .brand-btn{border:1px solid #fff !important;}
.box-style5 .box-tiles-col .brand-btn:hover {background-color: #000 !important;color: #fff !important;border-color: #000;}
@media(min-width:1200px){
	.box-tiles .row > div {padding: 0 10px;}
} 
@media(max-width:1200px){
	.box-tiles .row > div {padding: 0 3px;}
	.box-tiles .row {padding: 5px;}
} 
@media(max-width:1200px) and (min-width:767px) {
	.box-tiles-col p {min-height: auto !important;} 
}
@media(max-width:991px){ 
	.box-tiles-col {padding: 20px 5px !important;margin-bottom: 7px !important;     height: calc(100% - 5px);}
	.box-tiles-col img {max-width: 50px;}	
	.box-container h2 {font-size: 20px;}
	.box-tiles-col h3 {font-size: 15px; margin: 10px 0 5px;}
	.box-tiles-col p {font-size: 13px; min-height: 190px;} 
	.box-tiles-col .brand-btn {margin: 0;font-size: 12px;padding: 10px;min-width: 85%;} 
}
#box-tile-header{
    font-size: 2rem;
}